import h from '../../lib/react-hyperscript';
import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

const FetchBackdrop = ({
  state,
}: {
  state: {
    error?: Error;
    loading: boolean;
  };
}) => {
  const [dismissedError, setDismissedError] = React.useState(
    undefined as undefined | Error,
  );

  return h('div', [
    h(Backdrop, { sx: { color: '#fff', zIndex: 10000 }, open: state.loading }, [
      h(CircularProgress, { color: 'inherit' }),
    ]),
    h(
      Backdrop,
      {
        sx: { color: '#fff', zIndex: 100 },
        open: !!state.error && dismissedError !== state.error,
        onClick: () => setDismissedError(state.error),
      },
      [
        h('div', [
          h('h1', 'Something went wrong...'),
          h('p', state.error?.name),
          h('p', state.error?.message),
          h('p', state.error?.stack),
        ]),
      ],
    ),
  ]);
};

export default FetchBackdrop;
