import MarkdownEditor from '../../../common/components/MarkdownEditor';
import * as api from '../../../common/api';

export default function Reports() {
  return MarkdownEditor({
    title: 'Report',
    getDataFn: api.getReportsPage,
    saveDataFn: api.saveReportsPage,
  });
}
