import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import {
  PageDataGrid,
  QuickFilterSpec,
  format,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import { fmt } from '../../../common/helper';

import DataGridEntryLink from '../../../common/components/DataGridEntryLink';
import { LeagueId } from '../../../common/types';
import { daysBetween } from '../../../common/util';

import * as agg from './aggregatePricingColumns';

const column_specs: GridColDef[] = [
  {
    field: 'skybox_event_id',
    headerName: 'skybox_event_id',
    width: 100,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.id },
        `/pricing/inventory?filter.01.skybox_event_id=equals%2C${params.row.id}&v=2`,
      ),
  },
  {
    field: 'event',
    headerName: 'event',
    minWidth: 270,
    valueGetter: ({ row }) =>
      `'${row.season - 2000} ${row.league} ${row.team_abbrev} • ${fmt.dateSort(
        row.event_date_local,
      )} • ${fmt.timeSort(row.event_date_local)} • ${row.opponent_abbrev}`,
    renderCell: (params) => params.value,
  },
  {
    field: 'days_to',
    valueGetter: ({ row }) =>
      row.event_date
        ? Math.round(daysBetween(new Date(), new Date(row.event_date)))
        : null,
    type: 'number',
    width: 80,
  },
  {
    field: 'avg_erp',
    headerName: 'erp',
    width: 80,
    type: 'number',
    valueFormatter: format.dollars,
  },
  {
    field: 'is_amp',
    headerName: 'amp',
    width: 80,
    type: 'boolean',
  },

  ...agg.column_specs,
  {
    field: 'max_tm_event_id',
    headerName: 'tm_event_id',
    width: 100,
  },
  { field: 'season', width: 75 },
  { field: 'league', width: 75 },
  {
    field: 'team',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.team },
        `/pricing/packages?filter.01.team=equals%2C${params.row.team}&filter.02.season=equals%2C${params.row.season}&filterOp=and&v=2`,
      ),
  },
  {
    field: 'event_date_local',
    headerName: 'date',
    width: 160,
    valueFormatter: format.datetime,
  },
  {
    field: 'event_dow',
    headerName: 'day',
    valueGetter: ({ row }) => row.event_date_local,
    valueFormatter: format.dayOfWeek,
    sortComparator: (a, b) => {
      return new Date(a).getDay() - new Date(b).getDay();
    },
  },
  { field: 'opponent', width: 80 },
];

const column_groups = [
  {
    groupId: 'Event',
    children: [
      { field: 'skybox_event_id' },
      { field: 'event' },
      { field: 'days_to' },
      { field: 'avg_erp' },
      { field: 'is_amp' },
    ],
  },
  ...agg.column_groups,
  {
    groupId: 'Event Details',
    children: [
      { field: 'max_tm_event_id' },
      { field: 'season' },
      { field: 'league' },
      { field: 'event_date_local' },
      { field: 'event_dow' },
      { field: 'team' },
      { field: 'opponent' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | LeagueId>[] = [
    ...agg.buildFilterSpecs(baseData),

    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
  ];
  return filter_specs;
};

export default function PackagePricingDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = baseData.hydrateTeams(rows);
  return PageDataGrid({
    id: 'event_pricing',
    getRowId: (row) => row.id + '-' + row.is_amp,
    rows: rows,
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
  });
}
