import h from '../../../../lib/react-hyperscript';
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import FetchBackdrop from '../../../../common/components/FetchBackdrop';
import PlayoffPackagesDataGrid from './PlayoffPackagesDataGrid';
import { FetchPageHeader } from '../../../../common/components/FetchPageHeader';
import { useCachedData } from '../../../../common/cached_data';

export default function PlayoffPackagesListPanel() {
  const { loadData, error, loading, value, is_expired, invalidateData } =
    useCachedData('playoff_ticket_packages');
  useEffect(() => {
    if (is_expired) loadData();
  }, []);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchPageHeader, {
      header: 'Playoff Ticket Packages',
      state: { error, loading },
      doFetch: loadData,
    }),

    h(FetchBackdrop, { state: { error, loading } }),

    h(PlayoffPackagesDataGrid, {
      rows: value ?? [],
      onDataUpdated: invalidateData,
    }),
  ]);
}
