import h from '../../../lib/react-hyperscript';
import CachedDataPanel from '../../../common/components/CachedDataPanel';
import EventPricingDataGrid from './EventPricingDataGrid';

export default function EventPricingPanel() {
  return h(CachedDataPanel, {
    header: 'Event Pricing',
    cache_key: 'event_pricing',
    componentFn: ({ rows }) =>
      h(EventPricingDataGrid, {
        rows,
      }),
  });
}
