export const innerHeight = (el: HTMLElement) => {
  const style = window.getComputedStyle(el);
  const width =
    el.clientWidth -
    parseFloat(style.paddingLeft) -
    parseFloat(style.paddingRight);
  const height =
    el.clientHeight -
    parseFloat(style.paddingTop) -
    parseFloat(style.paddingBottom);
  return { width, height };
};
