import { create as d3Create, scaleLinear as d3ScaleLinear } from 'd3';

type SparklineData = {
  min: number;
  max: number;
  points: {
    value: number;
    opacity: number;
  }[];
};

const sparklineSvg =
  ({
    width,
    height,
    points_shown,
    show_end_dot,
    show_value_on_hover,
    two_colors,
  }: {
    width: number;
    height: number;
    points_shown: number;
    show_end_dot: boolean;
    show_value_on_hover: boolean;
    two_colors: boolean;
  }) =>
  (data: SparklineData) => {
    const sold_dot_radius = 3;
    const stroke_thickness = 2;
    const points = data.points.slice(-points_shown);
    const start_point = points[0];

    const x = d3ScaleLinear()
      .domain([0, points_shown - 1])
      .range([0 + sold_dot_radius, width - sold_dot_radius]);
    const y = (val: number) =>
      d3ScaleLinear()
        .domain([data.min, data.max])
        .range([height - sold_dot_radius, 0 + sold_dot_radius])(
        // Infinity is drown as a single pixel at the top of the plot area
        val === Infinity ? 1000000 : val > data.max ? Infinity : val,
      );

    const d3_el = d3Create('svg');
    const svg = d3_el
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .classed('sparkline-graph', true);

    const line_segments: {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      opacity: number;
    }[] = [];

    for (let i = 0; i < points.length - 1; i++) {
      const start = points[i];
      const end = points[i + 1];
      line_segments.push({
        x1: i,
        y1: start.value,
        x2: i + 1,
        y2: end.value,
        opacity: end.opacity,
      });
    }

    const print_value = (val: number) => {
      // Make it easier to read the number when the sprkline is behind it
      // by creating a drop shadow
      const fs = 14;
      const fw = 'bold';
      const y = height / 2 + 5;

      svg
        .append('text')
        .text(val === Infinity ? '∞' : val)
        .attr('font-size', fs)
        .attr('font-weight', fw)
        .attr('fill', 'white')
        .attr('x', -1)
        .attr('y', y - 1);

      svg
        .append('text')
        .text(val === Infinity ? '∞' : val)
        .attr('font-size', fs)
        .attr('font-weight', fw)
        .attr('fill', 'white')
        .attr('x', 1)
        .attr('y', y - 1);

      svg
        .append('text')
        .text(val === Infinity ? '∞' : val)
        .attr('font-size', fs)
        .attr('font-weight', fw)
        .attr('fill', 'white')
        .attr('x', -1)
        .attr('y', y + 1);

      svg
        .append('text')
        .text(val === Infinity ? '∞' : val)
        .attr('font-size', fs)
        .attr('font-weight', fw)
        .attr('fill', 'white')
        .attr('x', 1)
        .attr('y', y + 1);

      svg
        .append('text')
        .text(val === Infinity ? '∞' : val)
        .attr('font-size', fs)
        .attr('font-weight', fw)
        .attr('fill', 'purple')
        .attr('x', 0)
        .attr('y', y);
    };

    svg
      .selectAll('line')
      .data(line_segments)
      .join('line')
      .attr('x1', (d) => x(d.x1))
      .attr('y1', (d) => y(d.y1))
      .attr('x2', (d) => x(d.x2))
      .attr('y2', (d) => y(d.y2))
      .attr('stroke', (d) => {
        return two_colors
          ? d.y2 < start_point.value
            ? 'steelblue'
            : 'tomato'
          : 'steelblue';
      })
      .attr('stroke-width', stroke_thickness)
      .attr('opacity', (d) =>
        d.y2 === Infinity || d.y1 === Infinity ? 0 : d.opacity,
      )
      .on('mouseover', function (_e, d) {
        if (!show_value_on_hover) return;

        svg.select('.dot').remove();
        svg.selectAll('text').remove();

        if (d.y2 === Infinity) return;

        svg
          .append('circle')
          .classed('dot', true)
          .attr('r', sold_dot_radius)
          .attr('cx', x(d.x2))
          .attr('cy', y(d.y2))
          .attr('fill', 'purple');

        print_value(d.y2);
      });

    d3_el.on('mouseleave', function (d) {
      svg.select('.dot').remove();
      svg.selectAll('text').remove();
      add_end_dot_if();
    });

    const add_end_dot_if = () => {
      if (!show_end_dot || points.length < 1) return;

      const val = points[points.length - 1].value;
      svg
        .append('circle')
        .classed('dot', true)
        .attr('r', sold_dot_radius)
        .attr('cx', x(points.length - 1))
        .attr('cy', y(val))
        .attr(
          'fill',
          two_colors
            ? points[points.length - 1].value < start_point.value
              ? 'steelblue'
              : 'tomato'
            : 'purple',
        );
      if (show_value_on_hover) print_value(val);
    };

    add_end_dot_if();

    return d3_el.node() as SVGSVGElement;
  };

export { type SparklineData, sparklineSvg };
