import PackagesPanel from './components/PackagesPanel';
import PackagePanel from './components/PackagePanel';
import PackagesListPanel from './components/PackagesListPanel';
import AddPackagePanel from './components/AddPackagePanel';
import PlayoffPackagesListPanel from './components/playoffs/PlayoffPackagesListPanel';
import h from '../../lib/react-hyperscript';

const routing = {
  element: h(PackagesPanel),
  children: [
    {
      path: '',
      element: h(PackagesListPanel),
    },
    {
      path: 'add',
      element: h(AddPackagePanel),
    },
    {
      path: ':package_id',
      element: h(PackagePanel),
    },
    {
      path: 'playoffs',
      element: h(PlayoffPackagesListPanel),
    },
  ],
};

export { routing };
