import { GridColDef } from '@mui/x-data-grid-pro';
import EventStatusColumn from './EventStatusColumn';
import { PageDataGrid, QuickFilterSpec } from '../../../common/components';
import { LeagueId } from '../../../common/types';
import { BaseDataProvider, useBaseData } from '../../../common/base_data';
import * as _ from 'ramda';

const column_groups = [
  {
    groupId: 'Playoff Round Details',
    children: [
      { field: 'id' },
      { field: 'league' },
      { field: 'season' },
      { field: 'playoff_round' },
      { field: 'playoff_round_id' },
      { field: 'home_team' },
      { field: 'home_team_id' },
      { field: 'window_start' },
      { field: 'window_end' },
      { field: 'status' },
      { field: 'status_is_loading' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Actions',
    children: [{ field: 'DELETE' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    minWidth: 80,
  },
  { field: 'league', minWidth: 100 },
  { field: 'season', minWidth: 120 },
  { field: 'home_team', minWidth: 150 },
  { field: 'home_team_id', minWidth: 120 },
  { field: 'playoff_round', minWidth: 150 },
  { field: 'playoff_round_id', minWidth: 120 },
  {
    field: 'window_start',
    minWidth: 175,
    valueFormatter: (params) =>
      params.value ? `${params.value} 00:00 am` : '',
  },
  {
    field: 'window_end',
    minWidth: 175,
    valueFormatter: (params) =>
      params.value ? `${params.value} 00:00 am` : '',
  },
];
const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<
    number | LeagueId | 'pending' | 'listable' | 'not_happening' | 'completed'
  >[] = [
    {
      id: 'season',
      label: 'Season',
      optionValues: _.map(
        (start_year: number) => ({
          value: start_year,
          label: start_year.toString(),
        }),
        baseData.getSeasonStartYears(),
      ),
    },
    {
      id: 'league',
      label: 'League',
      optionValues: _.map(
        (league_id: LeagueId) => ({ label: league_id, value: league_id }),
        baseData.getLeagueIds(),
      ),
    },
    {
      id: 'status',
      label: 'Status',
      optionValues: [
        { label: 'Pending', value: 'pending' },
        { label: 'Listable', value: 'listable' },
        { label: 'Not happening', value: 'not_happening' },
        { label: 'Completed', value: 'completed' },
      ],
    },
  ];
  return filter_specs;
};

const PlayoffGameDataGrid = ({
  rows,
  actionColumns,
}: {
  rows: any[];
  actionColumns: GridColDef[];
}) => {
  const { baseData } = useBaseData();
  return PageDataGrid({
    id: 'playoff-rounds',
    rows,
    column_specs: [...column_specs, ...actionColumns, EventStatusColumn()],
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
    initialState: { pinnedColumns: { right: ['DELETE'] } },
    hide_client_filter: true,
  });
};

export default PlayoffGameDataGrid;
