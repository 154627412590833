import h from '../../lib/react-hyperscript';
import {
  Drawer as MuiDrawer,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';

import { useBaseData } from '../../common/base_data';

import {
  ListAlt as ListAltIcon,
  EventSeat as EventSeatIcon,
  AttachMoney as AttachMoneyIcon,
  Event as EventIcon,
  CalendarMonth as CalendarMonthIcon,
  EmojiEvents as EmojiEventsIcon,
  ReportProblem as ReportProblemIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
  AccountTree as AccountTreeIcon,
  QueryStats as QueryStatsIcon,
  Campaign as CampaignIcon,
  Payments as PaymentsIcon,
} from '@mui/icons-material';
import { ReactElement } from 'react';

const NavItem = ({
  icon,
  label,
  id,
  onClick,
}: {
  icon: ReactElement;
  label: string;
  id: string;
  onClick: (id: string) => void;
}) =>
  h(
    ListItem,
    { key: label, disablePadding: true, onClick: () => onClick(id) },
    [
      h(ListItemButton, [
        h(ListItemIcon, [icon]),
        h(ListItemText, { primary: label }),
      ]),
    ],
  );

export default function Drawer({
  open,
  onMenuClicked,
  onClose,
}: {
  open: boolean;
  onMenuClicked: (id: string) => void;
  onClose: () => void;
}) {
  const { baseData, fetchBaseData } = useBaseData();

  return h(MuiDrawer, { anchor: 'left', open, onClose }, [
    h(
      Stack,
      {
        justifyContent: 'space-between',
        sx: { width: 250, height: '100%', paddingTop: '50px' },
        role: 'presentation',
      },
      [
        h(List, [
          h(NavItem, {
            icon: h(DescriptionIcon),
            id: 'documentation',
            label: 'Documentation',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(ListAltIcon),
            id: 'reports',
            label: 'Reports',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(CampaignIcon),
            id: 'awaiting-broadcast-tickets',
            label: 'Request to List',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(PersonIcon),
            id: 'clients',
            label: 'Clients',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(EventSeatIcon),
            id: 'ticket-packages',
            label: 'Ticket Packages',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(EmojiEventsIcon),
            id: 'ticket-packages/playoffs',
            label: 'Playoff Tickets',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(EventIcon),
            id: 'single-event',
            label: 'Single Event',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(QueryStatsIcon),
            id: 'pricing/teams',
            label: 'Pricing',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(AttachMoneyIcon),
            id: 'sales',
            label: 'Sales',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(PaymentsIcon),
            id: 'payments',
            label: 'Payments',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(AccountTreeIcon),
            id: 'playoff-rounds',
            label: 'Playoff Rounds',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(CalendarMonthIcon),
            id: 'season-calendars',
            label: 'Season Calendars',
            onClick: onMenuClicked,
          }),
          h(NavItem, {
            icon: h(ReportProblemIcon),
            id: 'problematic-listing-report',
            label: 'Problematic Listings Report',
            onClick: onMenuClicked,
          }),
        ]),
        h(Stack, { sx: { pb: 2, pl: 2, pt: 2, backgroundColor: '#eee' } }, [
          h(Typography, { variant: 'overline' }, 'Data Options'),
          h(FormControlLabel, {
            control: h(Switch, {
              checked: baseData.include_archived,
              onChange: (e) => {
                fetchBaseData({ include_archived: e.target.checked });
              },
            }),
            label: 'Show archived',
          }),
          h(FormControlLabel, {
            control: h(Switch, {
              checked: baseData.include_test_data,
              onChange: (e) =>
                fetchBaseData({ include_test_data: e.target.checked }),
            }),
            label: 'Show test data',
          }),
        ]),
      ],
    ),
  ]);
}
