import h from '../../../lib/react-hyperscript';
import * as api from '../../../common/api';
import { useContext, useEffect, useState } from 'react';
import { AlertColor, Button, Stack, Typography, useTheme } from '@mui/material';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import ProblematicListingDataGrid from './ProblematicListingDataGrid';
import { convertMillisecondsToTime } from '../../../common/helper';
import { AppContext } from '../../../common/store/context';
import { format } from '../../../common/components';

export default function ProblematicListingListPanel() {
  const [state, setState] = useState<{
    loading: boolean;
    value?: any;
    error?: Error | undefined;
  }>({ loading: false, value: null });
  const theme = useTheme();
  const { setNotification } = useContext(AppContext);
  const getDuration = () => {
    if (!state.value?.request_time || !state.value?.response_time) return;
    const diff = Math.abs(
      new Date(state.value.request_time).getTime() -
        new Date(state.value.response_time).getTime(),
    );

    const { hours, minutes, seconds } = convertMillisecondsToTime(diff);
    return (
      (hours ? `${hours}h ` : '') +
      (minutes ? `${minutes}m ` : '') +
      (seconds ? `${seconds}s` : '')
    );
  };

  const loadDataByLongPolling = () => {
    clearTimeout();
    api
      .getNoBarcodeReport(true)
      .then((resp) => {
        setState({ loading: resp.is_loading, value: resp });
        if (!!resp.is_loading || resp.status === 'PROCESSING')
          setTimeout(() => {
            loadDataByLongPolling();
          }, 10 * 1000);
      })
      .catch((err) => {
        setState({ loading: false, error: err });
      });
  };

  const updateBarcodeReport = () => {
    api
      .updateNoBarcodeReport()
      .then(() => {
        setNotification({
          open: true,
          severity: 'success' as AlertColor,
          msg: `Server start to reload barcode report from TicketMaster.`,
        });
        loadDataByLongPolling();
      })
      .catch(() => {
        setNotification({
          open: true,
          severity: 'error' as AlertColor,
          msg: `Failed to request updates, please try again later.`,
        });
      });
  };

  useEffect(() => {
    loadDataByLongPolling();
  }, []);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(
      Typography,
      {
        variant: 'h5',
        fontWeight: 'bold',
        mb: 4,
        onClick: () => {
          api
            .getNoBarcodeReportAccessToken()
            .then((resp) => {
              console.log({
                status: state.value.status,
                api_duration: getDuration(),
                ...resp,
              });
            })
            .catch((err) => {
              console.error(err);
            });
        },
      },
      'Problematic Listings Report',
    ),

    h(FetchBackdrop, { state }),

    !!state.value?.is_loading
      ? h(
          Typography,
          {
            variant: 'h6',
            textAlign: 'start',
            color: theme.palette.warning.main,
            fontWeight: 'bold',
          },
          `Server is loading data now, the current data below may out of date.`,
        )
      : '',
    h(
      Stack,
      {
        direction: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
      },
      [
        h(Stack, { gap: 4, direction: 'row', alignItems: 'center' }, [
          h(
            Typography,
            { variant: 'body1', textAlign: 'end' },
            `Status: ${state.value?.status ?? ''}`,
          ),
          h(
            Typography,
            { variant: 'body1', textAlign: 'end' },
            `Updated at: ${
              state.value?.response_time
                ? `${format.datetime({ value: state.value?.response_time })}`
                : ''
            }`,
          ),
        ]),
        h(
          Button,
          {
            variant: 'contained',
            sx: {},
            fullWidth: false,
            onClick: () => updateBarcodeReport(),
          },
          'Update report',
        ),
      ],
    ),

    h(ProblematicListingDataGrid, {
      rows: state.value?.data ?? [],
      is_loading:
        state.value?.status === 'LOADING' ||
        state.value?.status === 'PROCESSING',
    }),
  ]);
}
