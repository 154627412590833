import h from '../../../lib/react-hyperscript';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useState } from 'react';

const AttributesTextRow = ({
  content,
  type,
  id,
}: {
  id: number;
  content: string;
  type: 'pros' | 'cons';
}) => {
  return h(
    Grid,
    {
      key: id,
      container: true,
      alignItems: 'center',
      width: '100%',
    },
    [
      h(
        Grid,
        {
          xs: 1,
          sx: { display: 'flex', justifyContent: 'center' },
        },
        [
          h(
            type === 'pros'
              ? SentimentVerySatisfiedIcon
              : SentimentVeryDissatisfiedIcon,
            { color: type === 'pros' ? 'success' : 'error' },
          ),
        ],
      ),
      h(
        Grid,
        {
          xs: 11,
          sx: { display: 'flex', justifyContent: 'start' },
        },
        [
          h(
            Typography,
            {
              variant: 'body1',
              gutterBottom: true,
              sx: {
                mb: 0,
                textAlign: 'start',
              },
            },
            content,
          ),
        ],
      ),
    ],
  );
};

export function SalesAttributesOverview({
  pros,
  cons,
  size,
  getAttributeText,
}: {
  pros: { id: number; value?: string }[];
  cons: { id: number; value?: string }[];
  getAttributeText: (id: number, input?: string) => string;
  size?: string;
}) {
  const theme = useTheme();
  return h(
    Stack,
    {
      direction: 'column',
      alignItems: 'start',
      spacing: 2,
      width: '100%',
      height: size === 'small' ? 'inherit' : '245px',
      overflow: 'scroll',
    },
    [
      h(Stack, { spacing: 0.8, width: '100%' }, [
        h(
          Typography,
          {
            variant: 'body1',
            gutterBottom: true,
            sx: {
              fontWeight: 'bold',
              mb: 0,
              textAlign: 'start',
              color: theme.palette.success.dark,
            },
          },
          'Pros',
        ),

        ...pros.map((item) =>
          h(AttributesTextRow, {
            content: getAttributeText(item.id, item.value),
            type: 'pros',
            id: item.id,
          }),
        ),

        pros.length === 0 ? 'n/a' : '',
      ]),

      h(Stack, { spacing: 0.8, width: '100%' }, [
        h(
          Typography,
          {
            variant: 'body1',
            gutterBottom: true,
            sx: {
              fontWeight: 'bold',
              mb: 0,
              color: theme.palette.error.dark,
              textAlign: 'start',
            },
          },
          'Cons',
        ),

        ...cons.map((item) =>
          h(AttributesTextRow, {
            content: getAttributeText(item.id, item.value),
            type: 'cons',
            id: item.id,
          }),
        ),

        cons.length === 0 ? 'n/a' : '',
      ]),
    ],
  );
}

export function SalesAttributesHistory({
  selected_pricing_factors,
  getAttributeText,
  isAttributePositive,
}: {
  selected_pricing_factors: { id: number; value?: string }[];
  getAttributeText: (id: number, input?: string) => string;
  isAttributePositive: (id: number) => boolean;
}) {
  const [is_expanded, setExpanded] = useState(true);

  return h(Accordion, {
    expanded: is_expanded,
    children: [
      h(AccordionSummary, { sx: { cursor: 'default!important' } }, [
        h(
          Grid,
          {
            container: true,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          [
            h(Stack, { direction: 'row', alignItems: 'center' }, [
              h(
                IconButton,
                { size: 'small', onClick: () => setExpanded(!is_expanded) },
                [h(is_expanded ? ExpandLessIcon : ExpandMoreIcon)],
              ),
              h(
                Typography,
                {
                  variant: 'h6',
                  gutterBottom: true,
                  sx: { fontWeight: 'bold', mb: 0 },
                },
                'Quality attributes',
              ),
            ]),
          ],
        ),
      ]),

      h(AccordionDetails, { sx: { paddingX: 3 } }, [
        h(SalesAttributesOverview, {
          pros: selected_pricing_factors.filter((item) =>
            isAttributePositive(item.id),
          ),
          cons: selected_pricing_factors.filter(
            (item) => !isAttributePositive(item.id),
          ),
          getAttributeText,
        }),
      ]),
    ],
  });
}
