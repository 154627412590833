import h from '../../../lib/react-hyperscript';
import CachedDataPanel from '../../../common/components/CachedDataPanel';
import PackagePricingDataGrid from './PackagePricingDataGrid';

export default function PackagePricingPanel() {
  return h(CachedDataPanel, {
    header: 'Package Pricing',
    cache_key: 'ticket_package_pricing',
    componentFn: ({ rows }) =>
      h(PackagePricingDataGrid, {
        rows,
      }),
  });
}
