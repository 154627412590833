import h from '../../../lib/react-hyperscript';
import {
  Stack,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Chip,
  Alert,
} from '@mui/material';
import { useState, useEffect } from 'react';

import { useAsyncFn } from 'react-use';
import React from 'react';

const ArchiveDialog = ({
  open,
  onConfirm,
  onCancel = () => {},
}: {
  open: boolean;
  onConfirm: (archive_reason?: string) => void;
  onCancel: () => void;
}) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const archive_reason = (data.get('archive_reason') as string).trim();
    onConfirm(archive_reason ?? undefined);
  };

  return h(
    Dialog,
    {
      open,
      fullWidth: true,
      maxWidth: 'sm',
      sx: { alignItems: 'center' },
    },
    [
      h(DialogTitle, { align: 'center' }, 'Archive Package'),
      h(DialogContent, { sx: { alignItems: 'center' } }, [
        h(
          Stack,
          {
            component: 'form',
            noValidate: true,
            onSubmit: handleSubmit,
            alignItems: 'center',
          } as any, // How do you type a Box element as a form?
          [
            h(TextField, {
              autoFocus: true,
              margin: 'normal',
              fullWidth: true,
              id: 'archive_reason',
              label: 'Reason for archiving',
              placeholder: 'optional',
              name: 'archive_reason',
            }),
            h(Stack, { direction: 'row', width: '100%', sx: { mt: 4 } }, [
              h(
                Button,
                {
                  variant: 'outlined',
                  sx: { width: '100%' },
                  onClick: () => onCancel(),
                },
                'Cancel',
              ),
              h(
                Button,
                {
                  type: 'submit',
                  variant: 'contained',
                  sx: { width: '100%', ml: 2 },
                },
                'Confirm',
              ),
            ]),
          ],
        ),
      ]),
    ],
  );
};

export default function DangerZone({
  is_archived,
  archive_reason,
  onArchive,
  onUnarchive,
}: {
  is_archived: boolean;
  archive_reason?: string;
  onArchive: (archive_reason?: string) => void;
  onUnarchive: () => void;
}) {
  const [is_archive_open, setIsArchiveOpen] = useState(false);

  return h(Stack, [
    h(ArchiveDialog, {
      open: is_archive_open,
      onConfirm: (archive_reason) => {
        setIsArchiveOpen(false);
        onArchive(archive_reason);
      },
      onCancel: () => {
        setIsArchiveOpen(false);
      },
    }),

    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        sx: { width: '100%' },
      },
      [
        h(
          Stack,
          {
            direction: 'column',
            justifyContent: 'start',
            textAlign: 'left',
            flexGrow: 1,
          },

          is_archived
            ? [
                h(
                  Typography,
                  { variant: 'body1', sx: { fontWeight: 'bold' } },
                  'Unarchive this package',
                ),
                h(
                  Typography,
                  { variant: 'body2' },
                  'This package will again be visible to the user.',
                ),
                ...(archive_reason
                  ? [
                      h(
                        Typography,
                        { variant: 'body1', mt: 1 },
                        'Archive reason:',
                      ),
                      h(Typography, { variant: 'body2' }, archive_reason),
                    ]
                  : []),
              ]
            : [
                h(
                  Typography,
                  { variant: 'body1', sx: { fontWeight: 'bold' } },
                  'Archive this package',
                ),
                h(
                  Typography,
                  { variant: 'body2' },
                  'This package will be removed from the user and only visible within the OCC.',
                ),
              ],
        ),
        is_archived
          ? h(
              Button,
              {
                variant: 'outlined',
                sx: { ml: 2 },
                onClick: () => onUnarchive(),
              },
              'Unarchive',
            )
          : h(
              Button,
              {
                variant: 'outlined',
                color: 'error',
                sx: { ml: 2 },
                onClick: () => setIsArchiveOpen(true),
              },
              'Archive',
            ),
      ],
    ),
  ]);
}
