export const prettyUsNumber = (val: string) => {
  //remove e164 country code
  if (val.startsWith('+1')) val = val.slice(2);

  const digits = val.replace(/\D/g, '').substring(0, 10);

  const areaCode = digits.substring(0, 3);
  const middle = digits.substring(3, 6);
  const last = digits.substring(6, 10);

  return digits.length > 6
    ? `(${areaCode}) ${middle}-${last}`
    : digits.length > 3
    ? `(${areaCode}) ${middle}`
    : digits.length > 0
    ? `(${areaCode}`
    : '';
};
