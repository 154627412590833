import h from '../../../lib/react-hyperscript';
import * as _ from 'ramda';
import { Stack, Breadcrumbs, Link } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

export default function TicketPackagesPanel({
  selectedPackage,
}: {
  selectedPackage?: string | number;
}) {
  const location = useLocation();
  const path = location.pathname.split('/').filter((p) => p !== '');

  return h(Stack, { flexGrow: 1, direction: 'column' }, [
    h(
      Breadcrumbs,
      { 'aria-label': 'breadcrumb' },
      path.map((p, index) => {
        const href = '/sales?last=true' + path.slice(1, index + 1).join('/');
        return h(
          Link,
          {
            underline: 'hover',
            color: 'inherit',
            href: href,
            key: href,
          },
          p,
        );
      }),
    ),
    h(Outlet),
  ]);
}
