import h from '../../../lib/react-hyperscript';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { Stack, Typography } from '@mui/material';
import { useAsyncFn } from 'react-use';
import * as api from '../../../common/api';
import { useEffect } from 'react';
import OverduePaymentsDataGrid from './OverduePaymentsDataGrid';

const fetchFunc = async (opts: { updateFn?: () => Promise<void> }) => {
  if (opts.updateFn) {
    await opts.updateFn();
  }
  const data = await api.getOverduePayments();
  return data;
};

export default function OverduePaymentsPanel() {
  const [data, fetchData] = useAsyncFn(fetchFunc);

  useEffect(() => {
    fetchData({});
  }, [fetchData]);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(
      Typography,
      { variant: 'h5', fontWeight: 'bold' },
      'Overdue Approved Dwolla Payments',
    ),

    h(FetchBackdrop, { state: { error: data.error, loading: data.loading } }),

    h(OverduePaymentsDataGrid, {
      rows: data.value ?? [],
      reloadData: fetchData,
    }),
  ]);
}
