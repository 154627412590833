import h from '../../../lib/react-hyperscript';
import PagePanel from '../../../common/components/PagePanel';

export default function PaymentsPanel() {
  return h(PagePanel, {
    url: '/payments',
    levels: [
      { label: 'All', href: '/payments' },
      { label: 'Overdue Approved Dwolla', href: '/payments/overdue' },
      { label: 'Batches', href: '/payments/batches' },
    ],
  });
}
