import h from '../../../lib/react-hyperscript';
import { SaleConfirmationEmail } from '../../../common/types';
import { Chip, Divider, Stack, TextField, Typography } from '@mui/material';
import { convertObjectToString } from '../../../common/helper';

const SalesEmailHistory = ({
  sale_email,
}: {
  sale_email: SaleConfirmationEmail;
}) => {
  const getEmailStatusComponent = () => {
    switch (sale_email.status) {
      case 'COMPLETED':
        return h(Chip, { label: 'Email sent', color: 'success' });
      case 'IN_PROGRESS':
        return h(Chip, { label: 'Email is sending', color: 'primary' });
      case 'ERROR':
        return h(Chip, { label: 'Error', color: 'error' });
      case 'NOT_SENT':
        return h(Chip, { label: 'Sale confirmed', color: 'secondary' });
      default:
        return '';
    }
  };

  return h(
    Stack,
    {
      paddingX: '20px',
      gap: 1,
    },
    [
      h(
        Typography,
        {
          variant: 'h6',
          fontWeight: 'bold',
          mb: 2,
          sx: { display: 'flex' },
        },
        'Sales confirmation',
      ),

      h('div', {}, [
        h(Divider, { sx: { marginY: 3 } }, [getEmailStatusComponent()]),
      ]),

      sale_email.is_sale_guarantee && sale_email.status === 'NOT_SENT'
        ? h(
            Typography,
            {
              variant: 'body2',
              fontWeight: 'bold',
              mb: 2,
              sx: { display: 'flex' },
            },
            'No email is actually sent because of sale guarantees',
          )
        : '',

      h(TextField, {
        label: 'Subject',
        variant: 'standard',
        size: 'small',
        defaultValue: sale_email.email_vars.subject,
        disabled: true,
      }),

      h(TextField, {
        label: 'Firstname',
        variant: 'standard',
        size: 'small',
        defaultValue: sale_email.email_vars.first_name,
        disabled: true,
      }),

      h(TextField, {
        label: 'Order number(External ID)',
        variant: 'standard',
        size: 'small',
        defaultValue: sale_email.email_vars.order_number,
        disabled: true,
      }),

      h(TextField, {
        label: 'TiqAssist price(client facing sale price)',
        variant: 'standard',
        size: 'small',
        InputProps: {
          startAdornment: '$',
        },
        defaultValue: sale_email.email_vars.ta_price,
        disabled: true,
      }),

      !sale_email.email_vars.is_fee_split && sale_email.email_vars.feesxticket
        ? h(TextField, {
            label: 'Fees',
            variant: 'standard',
            size: 'small',
            InputProps: {
              startAdornment: '$',
            },
            defaultValue: sale_email.email_vars.feesxticket,
            disabled: true,
          })
        : null,

      sale_email.email_vars.is_fee_split &&
      sale_email.email_vars.marketplace_fee
        ? h(TextField, {
            label: '3rd Party Fees',
            variant: 'standard',
            size: 'small',
            InputProps: {
              startAdornment: '$',
            },
            defaultValue: sale_email.email_vars.marketplace_fee,
            disabled: true,
          })
        : null,

      sale_email.email_vars.is_fee_split &&
      sale_email.email_vars.commissionxticket
        ? h(TextField, {
            label: 'TiqAssist Commission',
            variant: 'standard',
            size: 'small',
            InputProps: {
              startAdornment: '$',
            },
            defaultValue: sale_email.email_vars.commissionxticket,
            disabled: true,
          })
        : null,

      h(TextField, {
        label: 'Payout',
        variant: 'standard',
        size: 'small',
        InputProps: {
          startAdornment: '$',
        },
        defaultValue: sale_email.email_vars.payout,
        disabled: true,
      }),

      h(TextField, {
        label: 'Quantity',
        variant: 'standard',
        size: 'small',
        defaultValue: sale_email.email_vars.quantity,
        disabled: true,
      }),

      h(TextField, {
        label: 'Total payout',
        variant: 'standard',
        size: 'small',
        InputProps: {
          startAdornment: '$',
        },
        defaultValue: sale_email.email_vars.total_payout,
        disabled: true,
      }),

      h(TextField, {
        label: 'Analysis',
        multiline: true,
        minRows: 4,
        sx: { marginY: 1 },
        defaultValue: sale_email.email_vars.analysis,
        disabled: true,
      }),

      h(TextField, {
        label: 'Payment date',
        variant: 'standard',
        size: 'small',
        defaultValue: sale_email.email_vars.processing_date,
        disabled: true,
      }),

      h(TextField, {
        label: 'BCC',
        variant: 'standard',
        size: 'small',
        defaultValue: sale_email.bcc_email?.split(',').join('\n'),
        disabled: true,
        multiline: true,
      }),

      h('div', {}, [
        h(Divider, { sx: { marginY: 3 } }, [
          h(Chip, { label: 'Other references' }),
        ]),
      ]),

      h(TextField, {
        label: 'Compared sale information',
        variant: 'standard',
        size: 'small',
        value: sale_email.comp_sale
          ? convertObjectToString(sale_email.comp_sale)
          : 'None',
        multiline: true,
        disabled: true,
      }),
    ],
  );
};

export default SalesEmailHistory;
