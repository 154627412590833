import h from '../../../lib/react-hyperscript';
import { Box, Stack, Typography, Link, Tooltip } from '@mui/material';
import { getPackageEvents } from '../../../common/api';
import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import EventsTable from './EventsTable';
import InventoryControls from './InventoryControls';
import * as _ from 'ramda';
import {
  ClientEvent,
  ClientTicketPackageEx,
  InventoryItem,
} from '../../../common/types';

export type TicketPackageEventsViewProps = {
  ticket_package: ClientTicketPackageEx;
  onCreateInventory: (
    event_ids: number[],
    selected_events: ClientEvent[],
  ) => void;
  onDeleteInventory: (
    inv_ids: number[],
    selected_events: ClientEvent[],
  ) => void;
  has_unsaved_changes: boolean;
  onShowToggle: (value: boolean) => void;
  onShowBanner: (value: boolean) => void;
  onAmpPricingToggled: () => Promise<void>;
};

const HeaderLink = ({
  name,
  link,
  disabled,
  disabled_msg,
}: {
  name: string;
  link: string;
  disabled: boolean;
  disabled_msg: string;
}) => {
  return !disabled
    ? h(
        Link,
        {
          target: '_blank',
          rel: 'noopener',
          underline: 'hover',
          href: link,
          sx: { mb: '4px' },
        },
        [h(Typography, { variant: 'overline', fontSize: 'small' }, name)],
      )
    : h(Tooltip, {
        title: disabled_msg,
        children: h(
          Typography,
          {
            color: 'gray',
            variant: 'overline',
            fontSize: 'small',
            sx: {
              cursor: 'not-allowed',
            },
          },
          name,
        ),
      });
};

export default function TicketPackageEventsView({
  ticket_package,
  onCreateInventory,
  onDeleteInventory,
  has_unsaved_changes,
  onShowToggle,
  onShowBanner,
  onAmpPricingToggled,
}: TicketPackageEventsViewProps) {
  const [state, doFetch] = useAsyncFn(getPackageEvents);
  const [selected_events, setSelectedEvents] = useState(
    {} as Record<number, boolean>,
  );
  const [selected_inventory, setSelectedInventory] = useState(
    {} as Record<number, boolean>,
  );
  const [is_delete_toggled, onDeleteToggle] = useState(false);
  const getInventoryInfo = (id: number) => {
    const event_inv = state.value?.find((event) => event.inv?.id === id);
    if (!event_inv) return {};
    return {
      id: event_inv.inv?.id,
      event_date: event_inv.date,
      event_name: event_inv.name,
    };
  };

  useEffect(() => {
    doFetch(ticket_package.id);
  }, [ticket_package, doFetch]);

  useEffect(() => {
    if (state.value) {
      const defaultSelected = _.reduce(
        (result, event) => {
          if (!event.inv) {
            result[event.id] = Boolean(event.is_usual_venue);
          }
          return result;
        },
        {} as Record<number, boolean>,
        state.value,
      );
      setSelectedEvents(defaultSelected);

      const defaultSelectedInv = _.reduce(
        (result, event) => {
          if (
            event.inv &&
            !event.inv.is_sold &&
            !event.inv.is_broadcasted &&
            !event.inv.is_awaiting_broadcast &&
            !isSplitInventory(event.inv) &&
            event.inv.tps_key === ticket_package.tps_key
          ) {
            result[event.inv.id] = Boolean(false);
          }
          return result;
        },
        {} as Record<number, boolean>,
        state.value,
      );
      setSelectedInventory(defaultSelectedInv);
    }
  }, [state]);

  const isSplitInventory = (inv: InventoryItem) => {
    const first_seat: number = parseInt(ticket_package.first_seat, 10);
    const last_seat: number = parseInt(ticket_package.last_seat, 10);
    for (let seat = first_seat; seat <= last_seat; ++seat) {
      if (!inv.seats.find((s) => parseInt(s, 10) === seat)) return true;
    }
    return false;
  };

  const isSectionMismatched = (inv?: InventoryItem) =>
    !!inv && inv.section.toUpperCase() !== ticket_package.section.toUpperCase();
  const isRowMismatched = (inv?: InventoryItem) =>
    !!inv && inv.row.toUpperCase() !== ticket_package.row.toUpperCase();

  return h(
    Box,
    {
      sx: { width: '100%' },
    },
    [
      h(Box, { sx: { mb: 4 } }, [
        h(InventoryControls, {
          ticket_package,
          selected_events,
          selected_inventory,
          has_unsaved_changes,
          onCreateInventory: (event_ids: number[]) => {
            const selected_events =
              state.value?.filter((eve) => event_ids.includes(eve.id)) ?? [];
            onCreateInventory(event_ids, selected_events);
          },
          onDeleteInventory: (inv_ids: number[]) => {
            const selected_events =
              state.value?.filter(
                (eve) => eve.inv && inv_ids.includes(eve.inv.id),
              ) ?? [];
            onDeleteInventory(inv_ids, selected_events);
          },
          onShowToggle,
          onShowBanner,
          onAmpPricingToggled,
          has_unpriced_inventory: state.value
            ? state.value.some((item) => item.inv && !item.inv.is_priced)
            : false,
          is_delete_toggled,
          onDeleteToggle,
          getInventoryInfo,
          is_amp_available: state.value
            ? state.value.some((item) => item.inv && item.inv.tm_event_id)
            : false,
        }),
      ]),
      state.loading
        ? h(Box, 'Loading...')
        : state.error
        ? h(Box, [
            h(
              Typography,
              { fontSize: '1.2rem', color: 'red', mb: 2 },
              'Something went wrong...',
            ),
            h('p', state.error?.name),
            h('p', state.error?.message),
            h('p', state.error?.stack),
          ])
        : state.value
        ? h(Stack, { spacing: 3 }, [
            h(
              Stack,
              {
                sx: { backgroundColor: '#eee', pl: 2 },
                direction: 'row',
                alignItems: 'center',
                spacing: 4,
              },
              [
                h(
                  Typography,
                  { variant: 'overline', fontSize: 'small' },
                  `Total Events: ${state.value.length}`,
                ),
                h(
                  Typography,
                  { variant: 'overline', fontSize: 'small' },
                  `Recommended: ${_.count(
                    (e) => Boolean(e.is_usual_venue),
                    state.value,
                  )}`,
                ),
                h(
                  Typography,
                  { variant: 'overline', fontSize: 'small' },
                  `Created Inventory: ${ticket_package.inventory_count}`,
                ),
                HeaderLink({
                  name: 'Skybox',
                  link: ticket_package.skybox_link,
                  disabled: ticket_package.inventory_count < 0,
                  disabled_msg: 'No inventory has been created in Skybox',
                }),
                HeaderLink({
                  name: 'Active Campaign',
                  link: ticket_package.ac_link,
                  disabled: !ticket_package.ac_deal_id,
                  disabled_msg:
                    'This package does not have Active Campaign Deal ID',
                }),
              ],
            ),
            h(EventsTable, {
              events: state.value,
              selected: is_delete_toggled
                ? selected_inventory
                : selected_events,
              onChangeSelected: is_delete_toggled
                ? setSelectedInventory
                : setSelectedEvents,
              isSplitInventory,
              isSectionMismatched,
              isRowMismatched,
              is_delete_toggled,
              cur_tps_key: ticket_package.tps_key,
              is_tp_shown: ticket_package.is_shown,
              is_playoff: ticket_package.is_playoff,
            }),
          ])
        : null,
    ],
  );
}
