import { useAsyncFn } from 'react-use';
import * as api from '../../common/api';

const fetchCommissionFunc = async () => {
  const commissions = await api.getCommissions();
  return commissions;
};

export const useCommissions = () => {
  const [commissions, doFetchCommissions] = useAsyncFn(fetchCommissionFunc);

  return { commissions: commissions.value || [], doFetchCommissions };
};
