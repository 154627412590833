import h from '../../../lib/react-hyperscript';
import {
  Stack,
  Button,
  TextField,
  Typography,
  Divider,
  Chip,
  Dialog,
  Box,
  Checkbox,
  ButtonGroup,
  AlertColor,
  InputAdornment,
} from '@mui/material';
import {
  Send as SendIcon,
  CalculateOutlined as CalculateOutlinedIcon,
  PriceChange as PriceChangeIcon,
  Sync as SyncIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from '@mui/icons-material';
import { InventoryItem, SalesReports } from '../../../common/types';
import { useBaseData } from '../../../common/base_data';
import * as api from '../../../common/api';
import { useContext, useEffect, useState } from 'react';
import { utcToPstDateString } from '../../../common/helper';
import { useParams } from 'react-router-dom';
import LoadingMask from '../../../common/components/LoadingMask';
import { SalesAttributesOverview } from './SalesAttributesHistory';
import { AppContext } from '../../../common/store/context';
import { useCachedData } from '../../../common/cached_data';

const EmailField = ({
  label,
  value,
  startAdornment,
  endAdornment,
  multiline,
  error,
  helperText,
}: {
  label: string;
  value: any;
  startAdornment?: any;
  endAdornment?: any;
  multiline?: boolean;
  error?: boolean;
  helperText?: string;
}) =>
  h(TextField, {
    label,
    variant: 'standard',
    size: 'small',
    InputProps: {
      startAdornment,
      endAdornment,
      inputProps: {
        style: { paddingBottom: 0 },
      },
    },
    value,
    disabled: true,
    multiline,
    error,
    helperText,
    sx: {
      marginBottom: '8px',
    },
  });

const SalesEmailForm = ({
  inv_item,
  inv_price_detail,
  doFetch,
  comp_price,
  setCompPrice,
  updateSelectionModel,
  getCurrentCompSale,
  getAttributes,
  getAttributeText,
  getInvalidAttributeSelections,
}: {
  inv_item: InventoryItem & { subsidy: number };
  inv_price_detail: { [key: string]: string | number | undefined };
  doFetch: (opts: {
    id: number;
    refresh?: boolean;
    updateFn?: () => Promise<void>;
  }) => Promise<SalesReports>;
  comp_price: string;
  setCompPrice: (value: string) => void;
  updateSelectionModel: (value: string) => void;
  getCurrentCompSale: () => { [key: string]: unknown };
  getAttributes: () => {
    pros: { id: number; value: string }[];
    cons: { id: number; value: string }[];
  };
  getAttributeText: (id: number, input?: string) => string;
  getInvalidAttributeSelections: () => string[];
}) => {
  const { invalidateData } = useCachedData('sales');
  const { inventory_id } = useParams<{ inventory_id: string }>();
  const id = parseInt(inventory_id as string, 10);
  const {
    baseData: { getClient, getSalesBackFillDate },
  } = useBaseData();
  const { setNotification } = useContext(AppContext);
  const [inv_item_ref, setInvItemRef] = useState<
    InventoryItem & { subsidy: number }
  >(inv_item);
  const is_back_filled = () => {
    if (!inv_item.invoice_date) return false;
    return new Date(inv_item.invoice_date) < getSalesBackFillDate();
  };
  const is_sale_guarantee = () => {
    // @ts-ignore
    return !!inv_item.skybox?.tags?.includes('no_email');
  };
  const [is_email_required, setEmailRequired] = useState(!is_sale_guarantee());

  // @ts-ignore
  const skybox_invoice_id = inv_item.skybox.invoiceId ?? '';

  const dollarAdornment = h(
    InputAdornment,
    {
      position: 'start',
      sx: { color: 'rgba(0,0,0,0.3)', '& p': { color: 'inherit' } },
    },
    '$',
  );

  /* Email variable calculations */
  const [email_vars, setEmailVars] = useState<{
    [key: string]: string | number | boolean | undefined;
  }>({});
  const initEmailVars = (inv: InventoryItem) => {
    // @ts-ignore
    const quantity = parseInt(inv.skybox.quantity ?? '0');
    const eventDateOnly = () => {
      const [year, month, day] = inv.event_date_local.split('T')[0].split('-');
      return `${month}/${day}/${year}`;
    };
    const [_, month, day, year] = utcToPstDateString(
      inv.target_payment_date ?? '',
    ).split(' ');
    setEmailVars({
      is_fee_split: inv_price_detail.is_fee_split,
      quantity,
      ta_price: inv_price_detail.ta_price,
      payout: inv_price_detail.payout,
      feesxticket: inv_price_detail.feesxticket,
      commissionxticket: inv_price_detail.commissionxticket,
      marketplace_fee: inv_price_detail.marketplace_fee,
      total_payout: inv_price_detail.total_payout,
      order_number: inv.invoice_external_ref,
      subject: `${quantity} Tickets Sold @ $${
        inv_price_detail.ta_price
      } each! ${
        // @ts-ignore
        inv.skybox.eventDayOfWeek ?? ''
      } ${eventDateOnly()} ${inv.event_name} Section ${inv.section} Row ${
        inv.row
      } Seats ${inv.seats}`,
      first_name: getClient(inv.client_id)?.first_name,
      processing_date: `${month} ${day}, ${year}`,
      is_playoff: !!inv.playoff_round_id,
    });
  };
  useEffect(() => {
    setInvItemRef(inv_item);
  }, [inv_item]);
  useEffect(() => {
    if (inv_item_ref) initEmailVars(inv_item_ref);
    else setEmailVars({});
  }, [inv_item_ref]);

  /* Sales analysis */
  const [sale_analysis, setSaleAnalysis] = useState('');
  const [loading_analysis, setLoadingAnalysis] = useState(false);

  const getSaleAnalysis = async () => {
    if (!comp_price) return;
    setLoadingAnalysis(true);
    const { text, inventory_ref } = await api.getSaleAnalysis(
      id,
      parseFloat(comp_price),
    );

    setInvItemRef(inventory_ref);
    setSaleAnalysis(text);
    setLoadingAnalysis(false);
  };

  /* Email bcc */
  const [is_trustpilot, setTrustpilot] = useState(false);

  /* Send Sale email */
  const [sending_email, setSendingEmail] = useState(false);
  const sendSaleEmail = async () => {
    if (!inv_item_ref) return;
    const { pros, cons } = getAttributes();

    const payload = {
      client_email: getClient(inv_item_ref.client_id)?.email,
      email_vars: {
        ...email_vars,
        analysis: sale_analysis,
      },
      comp_sale: getCurrentCompSale(),
      bcc: is_trustpilot
        ? 'sales@tiqassist.com,tiqassist.com+fcbec4b42f@invite.trustpilot.com'
        : 'sales@tiqassist.com',
      pricing_factors: [...pros, ...cons],
      is_sale_guarantee: is_sale_guarantee(),
      is_email_required,
    };
    await api.sendSaleEmail(inv_item_ref.id, payload);
  };
  const submitEmailForm = () => {
    setSendingEmail(false);
    doFetch({ id: id, updateFn: sendSaleEmail }).then(() => {
      invalidateData();
    });
  };

  const isImplausibleSalePrice = () => {
    const client_facing_price = email_vars.ta_price as string;
    const actual_sale_price = inv_item_ref.list_price as string;
    return (
      parseInt(client_facing_price) / parseInt(actual_sale_price) >= 1.25 ||
      parseInt(client_facing_price) / parseInt(actual_sale_price) < 1
    );
  };
  const getSalePriceHelperText = () => {
    // if (isDeviatedSalePrice())
    //   return 'Sale price deviates from last list price';
    if (isImplausibleSalePrice())
      return `Implausible sale price (list price: $${inv_item_ref.list_price})`;
    return `(list price: $${inv_item_ref.list_price})`;
  };

  return h(
    Stack,
    {
      paddingX: '20px',
      gap: 1,
    },
    [
      h(
        Typography,
        {
          variant: 'h6',
          fontWeight: 'bold',
          mb: 2,
          sx: { display: 'flex' },
        },
        'Sales confirmation',
      ),

      is_back_filled()
        ? h('div', {}, [
            h(Divider, { sx: { marginY: 1 } }, [
              h(Chip, {
                label: 'Sales confirmation has been backfilled',
                color: 'success',
              }),
            ]),
          ])
        : '',

      h('div', {}, [
        h(Divider, { sx: { marginY: 1 } }, [
          h(Chip, { label: 'Sale price adjustment' }),
        ]),
      ]),

      h(EmailField, {
        label: 'Adjusted sale price',
        value: inv_item_ref.adjusted_sale_price ?? '',
        startAdornment: dollarAdornment,
      }),

      h(EmailField, {
        label: 'Subsidy',
        value: inv_item_ref.subsidy ?? '',
        startAdornment: dollarAdornment,
      }),

      h(EmailField, {
        label: 'Tags',
        // @ts-ignore
        value: inv_item_ref.skybox.tags.replaceAll(' ', '\n') ?? '',
        multiline: true,
      }),

      !is_back_filled()
        ? h(ButtonGroup, { variant: 'outlined' }, [
            h(
              Button,
              {
                sx: { width: '100%' },
                onClick: () =>
                  window.open(
                    `https://skybox.vividseats.com/invoices/${skybox_invoice_id}`,
                  ),
              },
              [
                'Change sale price',
                h(PriceChangeIcon, { sx: { paddingX: 1 } }),
              ],
            ),
            h(
              Button,
              {
                sx: { width: '40px' },
                onClick: () => doFetch({ id, refresh: true }),
              },
              [h(SyncIcon, { sx: { paddingX: 1 } })],
            ),
          ])
        : '',

      !is_back_filled()
        ? h('div', {}, [
            h(Divider, { sx: { marginY: 1.5 } }, [
              h(Chip, { label: 'Analysis' }),
            ]),
          ])
        : '',

      !is_back_filled()
        ? h(TextField, {
            label: 'Comparable price',
            size: 'small',
            sx: { marginTop: 3 },
            InputProps: {
              startAdornment: '$',
            },
            type: 'number',
            value: comp_price,
            onChange: (e) => {
              setCompPrice(e.target.value);
              updateSelectionModel('');
            },
            onBlur: () => updateSelectionModel(comp_price),
          })
        : '',

      !is_back_filled()
        ? h(
            Button,
            {
              variant: 'contained',
              onClick: () => getSaleAnalysis(),
            },
            ['Analysis', h(CalculateOutlinedIcon, { sx: { paddingX: 1 } })],
          )
        : '',

      h('div', {}, [
        h(Divider, { sx: { marginY: 1.5 } }, [
          h(Chip, {
            color: !is_sale_guarantee() ? 'default' : 'secondary',
            label: !is_sale_guarantee() ? 'Email overview' : 'Sale guarantees',
          }),
        ]),
      ]),

      h(EmailField, {
        label: 'Client email',
        multiline: true,
        value: getClient(inv_item_ref.client_id)?.email ?? '',
      }),

      h(EmailField, {
        label: 'Subject',
        multiline: true,
        value: email_vars.subject ?? '',
      }),

      h(EmailField, {
        label: 'Firstname',
        value: email_vars.first_name ?? '',
      }),

      h(EmailField, {
        label: 'Order number(External ID)',
        value: email_vars.order_number ?? 0,
      }),

      h(EmailField, {
        label: 'TiqAssist price(client facing sale price)',
        value: email_vars.ta_price ?? 0,
        startAdornment: dollarAdornment,
        error: isImplausibleSalePrice(),
        helperText: getSalePriceHelperText(),
      }),

      !inv_price_detail.is_fee_split
        ? h(EmailField, {
            label: 'Fees',
            value: email_vars.feesxticket ?? 0,
            startAdornment: dollarAdornment,
          })
        : null,

      inv_price_detail.is_fee_split
        ? h(EmailField, {
            label: '3rd Party Fees',
            value: email_vars.marketplace_fee ?? 0,
            startAdornment: dollarAdornment,
          })
        : null,

      inv_price_detail.is_fee_split
        ? h(EmailField, {
            label: 'TiqAssist Commission',
            value: email_vars.commissionxticket ?? 0,
            startAdornment: dollarAdornment,
            endAdornment: inv_price_detail.is_free_listing
              ? h(InputAdornment, { position: 'end' }, [
                  h(LocalOfferOutlinedIcon, { fontSize: 'small' }),
                  h(
                    Typography,
                    { variant: 'caption', sx: { marginLeft: '4px' } },
                    'Free Listing',
                  ),
                ])
              : null,
          })
        : null,

      h(EmailField, {
        label: 'Payout',
        value: email_vars.payout ?? 0,
        startAdornment: dollarAdornment,
      }),

      h(EmailField, {
        label: 'Quantity',
        value: email_vars.quantity ?? 0,
      }),

      h(EmailField, {
        label: 'Total payout',
        value: email_vars.total_payout ?? 0,
        startAdornment: dollarAdornment,
      }),

      h(EmailField, {
        label: 'Payment processing date(PST)',
        value: email_vars.processing_date ?? '',
      }),

      h(EmailField, {
        label: 'BCC',
        value: 'sales@tiqassist.com',
      }),

      h(
        Stack,
        {
          direction: 'row',
          marginY: 2,
        },
        [
          h(TextField, {
            sx: { flexGrow: 1, display: 'flex' },
            label: 'Trustpilot BCC',
            variant: 'standard',
            size: 'small',
            value: 'tiqassist.com+fcbec4b42f@invite.trustpilot.com',
            disabled: true,
          }),
          h(Checkbox, {
            checked: is_trustpilot,
            onClick: () => setTrustpilot(!is_trustpilot),
          }),
        ],
      ),

      h(Stack, { sx: { position: 'relative', marginBottom: 2 } }, [
        h(TextField, {
          label: 'Analysis',
          multiline: true,
          minRows: 6,
          disabled: loading_analysis,
          value: sale_analysis,
          onChange: (e) => setSaleAnalysis(e.target.value),
          sx: { opacity: loading_analysis ? 0.35 : 1 },
          error: sale_analysis.trim().length === 0,
        }),
        loading_analysis ? h(LoadingMask, {}) : '',
      ]),

      !is_back_filled()
        ? h(
            Button,
            {
              key: 'submit',
              variant: 'contained',
              onClick: () => {
                const invalid_attr_selections = getInvalidAttributeSelections();
                if (!invalid_attr_selections.length) setSendingEmail(true);
                else
                  setNotification({
                    open: true,
                    severity: 'error' as AlertColor,
                    msg: `Invalid attribute selection: ${invalid_attr_selections[0]}. Please make sure it is filled up correctly.`,
                  });
              },
              disabled: !getClient(inv_item_ref.client_id)?.email,
            },
            ['Confirm', h(SendIcon, { sx: { fontSize: '16px', paddingX: 1 } })],
          )
        : '',

      h(
        Dialog,
        { open: sending_email, onClose: () => setSendingEmail(false) },
        [
          h(
            Box,
            {
              sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
              },
            },
            [
              h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 3 }, [
                !is_sale_guarantee()
                  ? 'Send sale email'
                  : 'Confirm sale guarantees',
              ]),

              h(Stack, { mb: 4, gap: 1, width: '100%' }, [
                h(
                  Typography,
                  {
                    variant: 'body1',
                    fontWeight: 'bold',
                  },
                  ['Selected attribute review:'],
                ),

                h(SalesAttributesOverview, {
                  pros: [...getAttributes().pros],
                  cons: [...getAttributes().cons],
                  getAttributeText,
                  size: 'small',
                }),
              ]),

              h(Stack, { mb: 4, gap: 1, width: '100%' }, [
                isImplausibleSalePrice()
                  ? h(
                      Typography,
                      {
                        variant: 'body1',
                        color: 'error',
                        fontWeight: 'bold',
                      },
                      [
                        'Subsidized sale price is either more than 25% higher than actual sale price or less than the actual sale price.',
                      ],
                    )
                  : '',

                !sale_analysis.trim().length
                  ? h(
                      Typography,
                      {
                        variant: 'body1',
                        color: 'error',
                        fontWeight: 'bold',
                      },
                      [
                        `Analysis is empty. ${
                          !is_sale_guarantee()
                            ? 'The email will be sent without any analysis!'
                            : ''
                        }`,
                      ],
                    )
                  : '',

                is_sale_guarantee()
                  ? h(Stack, {}, [
                      h(Typography, { variant: 'body1' }, [
                        'Emails are not usually sent for sale guarantees',
                      ]),
                      h(
                        Stack,
                        {
                          direction: 'row',
                        },
                        [
                          h(Checkbox, {
                            size: 'small',
                            checked: is_email_required,
                            onClick: () => setEmailRequired(!is_email_required),
                          }),
                          h(
                            Typography,
                            {
                              variant: 'body1',
                              mb: 0,
                              sx: { display: 'flex', alignItems: 'center' },
                            },
                            'Send email anyway',
                          ),
                        ],
                      ),
                    ])
                  : '',
              ]),

              h(
                'div',
                {
                  sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
                [
                  h(
                    Button,
                    {
                      variant: 'contained',
                      sx: { marginX: 2 },
                      color: 'error',
                      onClick: () => setSendingEmail(false),
                    },
                    ['No'],
                  ),
                  h(
                    Button,
                    {
                      variant: 'contained',
                      sx: { marginX: 2 },
                      color: 'success',
                      onClick: () => submitEmailForm(),
                    },
                    ['Yes'],
                  ),
                ],
              ),
            ],
          ),
        ],
      ),
    ],
  );
};

export default SalesEmailForm;
