import h from '../../../lib/react-hyperscript';
import { Button, Stack, Typography } from '@mui/material';

export default function PlayoffControl({
  onClick,
}: {
  onClick: (value: boolean) => void;
}) {
  return h(Stack, { pb: 2 }, [
    h(Typography, { variant: 'h4', mt: 4, mb: 2 }, 'Playoffs'),
    h(
      Typography,
      { mb: 2, align: 'left' },
      'Create a Playoff ticket package for the client and allow to manage playoff events only',
    ),
    h(
      Button,
      {
        variant: 'outlined',
        onClick: () => onClick(true),
      },
      'Create playoff package',
    ),
  ]);
}
