import h from '../../../lib/react-hyperscript';
import { default as SalesDataGrid } from './SalesDataGrid';
import FetchBackdrop from '../../../common/components/FetchBackdrop';

import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { FetchPageHeader } from '../../../common/components/FetchPageHeader';
import { useCachedData } from '../../../common/cached_data';

const SalesList = () => {
  const { loadData, error, loading, value, is_expired } =
    useCachedData('sales');
  useEffect(() => {
    if (is_expired) loadData();
  }, []);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchPageHeader, {
      header: 'Sales',
      state: { error, loading },
      doFetch: loadData,
    }),

    h(FetchBackdrop, { state: { error, loading } }),

    h(SalesDataGrid, {
      rows: value ?? [],
    }),
  ]);
};

export default SalesList;
