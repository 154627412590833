import h from '../../lib/react-hyperscript';
import { Box, Autocomplete, Stack, TextField } from '@mui/material';
import * as _ from 'ramda';
import { Team } from '../types';

export type TeamAutoCompleteProps = {
  teams: Team[];
  onChange?: (team: Team) => void;
  id: string;
  name: string;
  label: string;
  sx?: object;
  margin?: 'none' | 'normal' | 'dense';
  fontSize?: string;
  team_id?: number | null;
  disabled?: boolean;
};

export default function TeamAutoComplete({
  teams,
  team_id,
  id,
  name,
  onChange = () => {},
  label,
  margin,
  sx,
  disabled = false,
}: TeamAutoCompleteProps) {
  return h(Box, [
    h(Autocomplete<Team>, {
      sx,
      id,
      value: team_id ? _.find((c) => c.id === team_id, teams) : null,
      fullWidth: true,
      disablePortal: true,
      disabled,
      options: teams,
      getOptionLabel: (c) => `(${c.id}) ${c.name}`,
      renderOption: (props, c) => {
        return h(
          Stack,
          {
            ...(props as unknown as React.HTMLAttributes<HTMLDivElement>),
            direction: 'column',
            alignItems: 'left',
            sx: { m: 0 },
          },
          [
            h(
              Box,
              {
                sx: { width: '100%', textAlign: 'left', whiteSpace: 'nowrap' },
              },
              `${c.name}`,
            ),
            // h(
            //   Stack,
            //   {
            //     width: '100%',
            //     direction: 'row',
            //     justifyContent: 'start',
            //     spacing: 1,
            //     color: '#777',
            //   },
            //   [
            //     h(
            //       Box,
            //       {
            //         minWidth: '40px',
            //         textAlign: 'left',
            //         sx: { whiteSpace: 'nowrap' },
            //       },
            //       `${c.id}`,
            //     ),
            //     h(Box, `${c.email}`),
            //   ],
            // ),
          ],
        );
      },
      filterOptions: (teams, obj) => {
        const parts = obj.inputValue.toLowerCase().split(' ');

        return _.filter((c) => {
          return _.all(
            (p) =>
              _.any((s) => s.startsWith(p), c.name.toLowerCase().split(' ')) ||
              _.any(
                (s) => s.startsWith(p),
                c.name_short?.toLowerCase().split(' '),
              ) ||
              c.abbrev?.toLowerCase().startsWith(p) ||
              c.id.toString().startsWith(p),
            parts,
          );
        }, teams).splice(0, 100); // showing too many has a noticable delay
      },
      // @ts-ignore
      disableClearable: true,
      renderInput: (props) => {
        return h(TextField, {
          ...props,
          fullWidth: true,
          label,
          id,
          name,
          margin,
          sx: { autocomplete: 'off' },
          variant: 'standard',
        });
      },
      onChange: (_e, team) => onChange(team as Team),
    }),
  ]);
}
