import h from '../../lib/react-hyperscript';

import SimpleSelect from './SimpleSelect';
import { QualifyStatus, LeagueId, ConversionStage } from '../../common/types';
import { Stack } from '@mui/material';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import * as _ from 'ramda';

export type PackageFilters = {
  league?: LeagueId | null;
  qualification?: QualifyStatus | null;
  is_priced?: boolean | null;
  is_shown?: boolean | null;
  conversion_stage?: ConversionStage | null;
};

type YesNoAny = 'Any' | 'Yes' | 'No';

const YesNoAnySelect = (props: {
  label: string;
  onChange: (val: YesNoAny) => void;
  disabled?: boolean;
  value: YesNoAny;
}) => {
  return h(SimpleSelect<YesNoAny>, {
    sx: { width: 100 },
    label: props.label,
    optionValues: [
      { label: 'Any', value: 'Any' },
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' },
    ],
    onChange: props.onChange,
    disabled: props.disabled,
    value: props.value,
  });
};

export type QuickFilterSpec<T = string | number> = {
  id: string | number;
  label: string | number;
  optionValues?: { label: string; value: T }[];
  yesNoAny?: boolean;
};

export const QuickFilter = <T extends string | number>({
  spec,
  item,
  onChange,
}: {
  spec: QuickFilterSpec<T>;
  item?: GridFilterItem;
  onChange: (item: GridFilterItem) => void;
}) => {
  const setBooleanFilter = (field: string, val: boolean | null) => {
    onChange({
      field,
      operator: 'is',
      value: val && val.toString(),
    });
  };

  const setSimpleFilter = (field: string, val: string | number | null) => {
    onChange({
      field,
      operator: 'equals',
      value: val,
    });
  };

  return spec.yesNoAny
    ? h(YesNoAnySelect, {
        label: spec.label.toString(),
        onChange: (val) =>
          setBooleanFilter(
            spec.id.toString(),
            val === 'Any' ? null : val === 'Yes',
          ),
        value: item ? (item.value === 'true' ? 'Yes' : 'No') : 'Any',
      })
    : h(SimpleSelect<T | 'Any'>, {
        label: spec.label.toString(),
        optionValues: [
          { label: 'Any', value: 'Any' },
          ...(spec.optionValues ?? []),
        ],
        onChange: (val) =>
          setSimpleFilter(spec.id.toString(), val === 'Any' ? null : val),
        value: item ? item.value : 'Any',
      });
};

export const QuickFilterBar = ({
  specs,
  filterItems,
  onChange,
}: {
  specs: QuickFilterSpec[];
  filterItems: GridFilterItem[];
  onChange: (filterItems: GridFilterItem[]) => void;
}) => {
  const setFilterItem = (item: GridFilterItem) => {
    const newItems = _.filter((f) => f.field !== item.field, filterItems);
    if (!_.isNil(item.value)) {
      newItems.push(item);
    }
    onChange(newItems);
  };

  return h(
    Stack,
    { direction: 'row' },
    specs.map((spec) =>
      h(QuickFilter, {
        spec,
        item: _.find((i) => i.field === spec.id, filterItems),
        onChange: setFilterItem,
      }),
    ),
  );
};
