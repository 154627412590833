import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import h from '../../lib/react-hyperscript';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Campaign as CampaignIcon,
} from '@mui/icons-material';
import { useContext, useState } from 'react';
import { AppContext } from '../store/context';

type cellAction = 'delete' | 'edit' | 'broadcast';

export const getRenderCell = ({
  action,
  onClick,
  hideCell,
  button_color,
  confirm_popup_message,
}: {
  action: cellAction;
  onClick: (row: any) => Promise<void>;
  hideCell?: (row: any) => boolean;
  button_color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  confirm_popup_message?: string;
}) => {
  const getIconByAction = () => {
    switch (action) {
      case 'delete':
        return h(DeleteIcon);
      case 'edit':
        return h(EditIcon);
      case 'broadcast':
        return h(CampaignIcon);
      default:
        return '';
    }
  };

  return (params: GridRenderCellParams<any>) => {
    const { setNotification } = useContext(AppContext);
    const [showConfirmation, setConfirmation] = useState(false);
    const [is_loading, setLoading] = useState(false);
    const handleOnClick = (action: string) => {
      setLoading(true);
      onClick(params.row)
        .then(() => {
          setLoading(false);
          setNotification({
            open: true,
            severity: 'success' as AlertColor,
            msg: `You ${action} it successfully`,
          });
        })
        .catch(() => {
          setLoading(false);
          setNotification({
            open: true,
            severity: 'error' as AlertColor,
            msg: `Failed to ${action} row: id=${params.row.id}`,
          });
        });
    };
    return hideCell?.(params.row)
      ? null
      : h(Stack, {}, [
          is_loading
            ? h(CircularProgress, { size: 20, thickness: 4 })
            : h(
                IconButton,
                {
                  'aria-label': action,
                  size: 'small',
                  color: button_color,
                  onClick: () => {
                    if (action === 'delete') return setConfirmation(true);
                    setLoading(true);
                    handleOnClick(action);
                  },
                },
                [getIconByAction()],
              ),

          h(
            Dialog,
            { open: showConfirmation, onClose: () => setConfirmation(false) },
            [
              h(
                Box,
                {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                  },
                },
                [
                  h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 3 }, [
                    'Are you sure?',
                  ]),

                  h(Stack, { mb: 4, gap: 1, width: '100%' }, [
                    h(
                      Typography,
                      {
                        variant: 'body1',
                        fontWeight: 'bold',
                      },
                      [
                        confirm_popup_message ??
                          "You won't be able to undo the deletion.",
                      ],
                    ),
                  ]),

                  h(
                    'div',
                    {
                      sx: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                    },
                    [
                      h(
                        Button,
                        {
                          variant: 'contained',
                          sx: { marginX: 2 },
                          color: 'error',
                          onClick: () => setConfirmation(false),
                        },
                        ['No'],
                      ),
                      h(
                        Button,
                        {
                          variant: 'contained',
                          sx: { marginX: 2 },
                          color: 'success',
                          onClick: () => {
                            handleOnClick(action);
                            setConfirmation(false);
                          },
                        },
                        ['Yes'],
                      ),
                    ],
                  ),
                ],
              ),
            ],
          ),
        ]);
  };
};

export const getActionColumnDef = ({
  field,
  headerName,
  action,
  cellOnClick,
  hideCell,
  button_color,
  confirm_popup_message,
}: {
  field: string;
  headerName?: string;
  action: cellAction;
  cellOnClick: (row: any) => Promise<void>;
  hideCell?: (row: any) => boolean;
  button_color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  confirm_popup_message?: string;
}): GridColDef => {
  return {
    field,
    headerAlign: 'center',
    align: 'center',
    headerName,
    minWidth: 100,
    sortable: false,
    hideable: false,
    filterable: false,
    resizable: false,
    pinnable: false,
    disableColumnMenu: true,
    renderCell: getRenderCell({
      action,
      onClick: cellOnClick,
      hideCell,
      button_color,
      confirm_popup_message,
    }),
  };
};
