import h from '../../../lib/react-hyperscript';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseDataProvider, useBaseData } from '../../../common/base_data';

const column_specs: GridColDef[] = [
  {
    field: 'client_name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'client_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  { field: 'email', minWidth: 230, align: 'left' },
  { field: 'phone', minWidth: 140, align: 'left' },
  { field: 'bank_account_status', minWidth: 120 },
  {
    field: 'tax_status',
    minWidth: 120,
    valueGetter: ({ value }) =>
      value === 'submitted' ? 'w8_requested' : value,
  },
  {
    field: 'is_nonresident_alien',
    width: 75,
    type: 'boolean',
  },

  { field: 'bulk_payment_id', width: 75 },
  { field: 'invoice_external_ref', minWidth: 260 },
  { field: 'payment_processor', width: 100 },
  {
    field: 'payment_amount',
    headerName: '$amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 80,
  },
  {
    field: 'target_payment_date',
    width: 160,
    valueFormatter: format.datetime,
  },
  { field: 'status', width: 100 },
  {
    field: 'is_overdue',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'payment_initiated_date',
    headerName: 'initiated',
    width: 160,
    valueFormatter: format.datetime,
  },
  {
    field: 'payment_completed_date',
    headerName: 'completed',
    width: 160,
    valueFormatter: format.datetime,
  },

  { field: 'event_name', minWidth: 260 },
  {
    field: 'event_date_local',
    headerName: 'event_date',
    width: 160,
    valueFormatter: format.datetime,
  },
  { field: 'home_team', width: 80 },
  { field: 'away_team', width: 80 },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) =>
      row.seats ? row.seats[0] + '-' + row.seats[row.seats.length - 1] : '',
    width: 80,
  },
  { field: 'skybox_tags', width: 150 },
  {
    field: 'is_free_listing',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
];

const column_groups = [
  {
    groupId: 'Client info',
    children: [
      { field: 'client_id' },
      { field: 'client_name' },
      { field: 'email' },
      { field: 'phone' },
      { field: 'bank_account_status' },
      { field: 'tax_status' },
      { field: 'is_nonresident_alien' },
    ],
  },
  {
    groupId: 'Inventory',
    children: [
      { field: 'event_name' },
      { field: 'event_date_local' },
      { field: 'home_team' },
      { field: 'away_team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'skybox_tags' },
      { field: 'is_free_listing' },
      { field: 'is_playoff' },
    ],
  },
  {
    groupId: 'Payment details',
    children: [
      { field: 'bulk_payment_id' },
      { field: 'invoice_external_ref' },
      { field: 'payment_processor' },
      { field: 'payment_amount' },
      { field: 'target_payment_date' },
      { field: 'status' },
      { field: 'is_overdue' },
      { field: 'payment_initiated_date' },
      { field: 'payment_completed_date' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | string>[] = [
    {
      id: 'status',
      label: 'Status',
      optionValues: [
        { label: 'unpaid', value: 'unpaid' },
        { label: 'pending', value: 'pending' },
        { label: 'failed', value: 'failed' },
        { label: 'paid', value: 'paid' },
      ],
    },
    {
      id: 'payment_processor',
      label: 'Processor',
      optionValues: [
        { label: 'Dwolla', value: 'dwolla' },
        { label: 'bill.com', value: 'bill.com' },
        { label: 'Venmo', value: 'venmo' },
      ],
    },
  ];

  return filter_specs;
};

export default function PaymentsDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  return PageDataGrid({
    id: 'payments',
    getRowId: (row) => row.uuid,
    rows: rows,
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
  });
}
