import {
  ClientDrillDown,
  format,
  PageDataGrid,
} from '../../../common/components';
import { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import { getEventStatus } from '../../ticket-packages/components/EventsTable';
import DataGridCustomColumn from '../../ticket-packages/components/DataGridCustomColumn';
import * as api from '../../../common/api';
import { getActionColumnDef } from '../../../common/components/DataGridActionColumn';
import { deleteSingleEventInventory } from '../../../common/api';
import { useAsyncFn } from 'react-use';
import { stringDateTime } from '../../../common/util';
import DataGridNoteColumnV2 from '../../../common/components/DataGridNoteColumnV2';

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Event Details',
    children: [
      { field: 'created' },
      { field: 'client_name' },
      { field: 'client_id' },
      { field: 'ac_contact_id' },
      { field: 'event_id' },
      { field: 'event_name' },
      { field: 'event_date_local' },
      { field: 'is_client' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'ticket_notes' },
      { field: 'commission_experiment' },
      { field: 'stock_type' },
    ],
  },
  {
    groupId: 'Marketplace',
    children: [
      { field: 'list_price' },
      { field: 'tiq_commission' },
      { field: 'third_party_cost' },
      { field: 'status' },
      { field: 'is_marketplace_broadcasted' },
    ],
  },
  {
    groupId: 'Contact Info',
    children: [{ field: 'email' }, { field: 'phone' }],
  },
  { groupId: 'Note', children: [{ field: 'note' }] },
  {
    groupId: 'Actions',
    children: [{ field: 'DELETE' }],
    headerClassName: 'tiq-grid-naming-group',
  },
];

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    width: 120,
    resizable: false,
    hideable: false,
    pinnable: false,
    renderCell: (params) =>
      DataGridEntryButton(
        params,
        `https://skybox.vividseats.com/inventory?ef.keywords="${params.row.event_name}"&inf.section=${params.row.section}&inf.row=${params.row.row}`,
        params.row.is_playoff ? { color: 'warning.main' } : {},
      ),
  },
  stringDateTime({
    field: 'created',
    minWidth: 175,
  }),
  {
    field: 'client_name',
    minWidth: 150,
    maxWidth: 300,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'client_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  {
    field: 'ac_contact_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  {
    field: 'is_client',
    width: 75,
    type: 'boolean',
  },
  { field: 'event_id', width: 90 },
  { field: 'event_name', minWidth: 400 },
  stringDateTime({
    field: 'event_date_local',
    minWidth: 175,
  }),
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => {
      row.seats.sort();
      // get the first and last seat
      const first_seat = row.seats[0];
      const last_seat = row.seats[row.seats.length - 1];
      return first_seat + (last_seat === first_seat ? `-${last_seat}` : '');
    },
    minWidth: 50,
    maxWidth: 100,
  },
  {
    headerName: 'submission_note',
    field: 'ticket_notes',
    minWidth: 150,
  },
  {
    field: 'commission_experiment',
    width: 140,
    type: 'boolean',
    headerName: 'experiment',
    valueGetter: ({ value }) => value === 'experiment',
  },
  {
    field: 'stock_type',
    align: 'right',
    minWidth: 80,
    valueGetter: ({ row }) => row.skybox?.stockType,
  },

  { field: 'list_price', minWidth: 80, align: 'right' },
  {
    field: 'tiq_commission',
    headerName: 'commission',
    align: 'right',
    minWidth: 70,
  },
  {
    field: 'third_party_cost',
    headerName: 'third_party_cost',
    align: 'right',
    minWidth: 70,
  },
  {
    field: 'status',
    minWidth: 80,
    align: 'right',
    valueGetter: ({ row }) => row.event_status,
    renderCell: (params) =>
      getEventStatus({ inventory: params.row, size: 'small' }),
  },
];
const contact_info_columns: GridColDef[] = [
  { field: 'email', minWidth: 230, align: 'left' },
  { field: 'phone', minWidth: 140, align: 'left' },
];

export default function SingleEventDataGrid({
  rows,
  onDataUpdated,
}: {
  rows: any[];
  onDataUpdated: () => void;
}) {
  const [data, setData] = useState(rows);
  const [] = useAsyncFn(api.deleteSingleEventInventory);
  const onSaveSucceed = (row: any, field: string, value: any) => {
    const new_data = [...data];
    const target_idx = data.findIndex((item) => item.id === row.id);
    new_data[target_idx][field] = value;
    setData(new_data);
    onDataUpdated();
  };

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return PageDataGrid({
    id: 'single-events',
    rows: data,
    column_specs: [
      ...column_specs,

      DataGridCustomColumn({
        api_key_field: 'id',
        field: 'is_marketplace_broadcasted',
        headerName: 'verified_in_marketplace',
        onSaveSucceed,
        doSave: (row, e, api_key_field) =>
          api.updateInventoryMarketplaceBroadcasted(
            row[api_key_field as string],
            e,
          ),
        type: 'boolean',
        isHidden: ({ row }) =>
          row.is_marketplace_broadcasted === undefined ||
          row.is_marketplace_broadcasted === null,
      }),

      ...contact_info_columns,

      // DataGridNoteColumn({
      //   field: 'note',
      //   onSaveSucceed,
      //   doSave: api.updateInventoryNote,
      //   api_key_field: 'id',
      // }),
      DataGridNoteColumnV2({
        field: 'note',
        valueGetter: ({ row }) => row.note,
        headerName: 'inventory',
        saveNoteHeaderFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteHeader({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'inventory',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'note', value);
        },
        saveNoteBodyFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteDetail({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'inventory',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'has_notes', !!value);
        },
        getDataFn: (row: any, api_key_field: string) =>
          api.getNoteDetail({
            row,
            api_key_field,
            ref_source: 'inventory',
            ref_usage: 'general',
          }),
        getEntryDescription: (row) =>
          `${row.client_name}'s ticket for ${row.event_name}, ${format.datetime(
            { value: row.event_date_local },
          )} (sec ${row.section}, row ${row.row}, s [${row.seats}])`,
        hasNoteBody: (row) => !!row.has_notes,
        api_key_field: 'id',
      }),

      getActionColumnDef({
        field: 'DELETE',
        headerName: 'DELETE',
        action: 'delete',
        cellOnClick: async (row: any) => {
          await deleteSingleEventInventory(row.id);
          const new_data = [...data];
          const target_idx = data.findIndex((item) => item.id === row.id);
          new_data.splice(target_idx, 1);
          setData(new_data);
          onDataUpdated();
        },
        hideCell: ({ row }) => row.is_sold,
      }),
    ],
    column_groups: column_groups,
    filter_specs: [],
    add_button: true,
  });
}
