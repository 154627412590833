import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
} from '../../../common/components';
import DataGridNoteColumn from '../../ticket-packages/components/DataGridNoteColumn';
import * as api from '../../../common/api';
import { stringDateTime } from '../../../common/util';

const column_specs: GridColDef[] = [
  {
    field: 'tp_ids',
    headerName: 'ticket_packages',
    renderCell: (params) => {
      if (!params.value.length) return '';
      const tp_ids = params.value.filter((i: unknown) => !!i);
      return h(
        'div',
        {},
        tp_ids.map((item: unknown) =>
          h(
            Link,
            {
              id: `tp-${item}`,
              sx: {
                variant: 'soft',
                color: 'primary.main',
                border: '1px solid',
                borderRadius: '10px',
                px: 2,
                py: 0.2,
                ':hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                },
              },
              underline: 'none',
              href: `/ticket-packages/${item}`,
            },
            `${item}`,
          ),
        ),
      );
    },
  },
  {
    field: 'tps_keys',
    minWidth: 160,
    headerName: 'skybox',
    renderCell: (params) => {
      if (!params.value.length) return '';
      const tps_keys = params.value.filter((i: unknown) => !!i);
      return h(
        'div',
        {},
        tps_keys.map((item: unknown) =>
          h(
            Link,
            {
              id: `tps-key-${item}`,
              sx: {
                variant: 'soft',
                color: 'primary.main',
                border: '1px solid',
                borderRadius: '10px',
                px: 2,
                py: 0.2,
                ':hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                },
              },
              target: '_blank',
              rel: 'noopener',
              underline: 'none',
              href: `https://skybox.vividseats.com/inventory?inf.tag=ztps:${item}`,
            },
            `ztps:${item}`,
          ),
        ),
      );
    },
  },
  {
    field: 'client_ids',
    headerName: 'clients',
    minWidth: 170,
    renderCell: (params) => {
      if (!params.value.length) return '';
      const client_ids = params.value.filter((i: unknown) => !!i);
      const client_names = params.row.client_names.filter((i: unknown) => !!i);
      return h(
        'div',
        {},
        client_ids.map((item: number, idx: number) =>
          h('div', { id: `client_ids-${client_ids}` }, [
            h(ClientDrillDown, {
              label: client_names[idx],
              client_id: item,
            }),
          ]),
        ),
      );
    },
  },
  { field: 'event_name', minWidth: 300 },
  stringDateTime({
    field: 'event_date',
    minWidth: 220,
    headerName: 'event_date_local',
  }),
  {
    field: 'issue',
    minWidth: 120,
    valueGetter: ({ value, row }) => {
      switch (value) {
        case 'no_barcode':
          if (row.source === 'ticketmaster')
            return 'Barcode not locked in Ticketmaster.';
          else if (row.source === 'skybox')
            return 'Barcode not present in Skybox.';
          return 'Barcode not locked in Ticketmaster and missing from Skybox.';
        case 'no_tq_listing':
          return 'Inventory item not broadcasted on Ticketmaster marketplace.';
        case 'double_listing':
          return `Found a double listing at price $${
            row.double_listing_inv.tmr_price
          }. Checked at: ${format.datetime({
            value: new Date(row.double_listing_inv.created).toISOString(),
          })}`;
        default:
          return '';
      }
    },
  },
  { field: 'section', minWidth: 100 },
  { field: 'row', minWidth: 100 },
  { field: 'start_seat', minWidth: 100 },
  { field: 'end_seat', minWidth: 100 },
  { field: 'is_playoff', type: 'boolean' },
  { field: 'third_party_mobile_provider_id', minWidth: 200 },
];
const excluded_events = new Set([
  'Houston Rockets',
  'LA Clippers',
  'Minnesota Timberwolves',
  'Vegas Golden Knights',
  'LA Galaxy',
  'Dallas FC',
  'Montreal Impact',
  'Los Angeles Kings',
  'Brooklyn Nets',
  'Cleveland Cavaliers',
  'New Orleans Pelicans',
  'Los Angeles Galaxy',
  'St. Louis City SC',
  'Saint Louis FC',
]);

const mlb_teams = new Set([
  'Arizona Diamondbacks',
  'Atlanta Braves',
  'Baltimore Orioles',
  'Boston Red Sox',
  'Chicago Cubs',
  'Chicago White Sox',
  'Cincinnati Reds',
  'Cleveland Guardians',
  'Colorado Rockies',
  'Detroit Tigers',
  'Houston Astros',
  'Kansas City Royals',
  'Los Angeles Angels',
  'Los Angeles Dodgers',
  'Miami Marlins',
  'Milwaukee Brewers',
  'Minnesota Twins',
  'New York Mets',
  'New York Yankees',
  'Oakland Athletics',
  'Philadelphia Phillies',
  'Pittsburgh Pirates',
  'San Diego Padres',
  'Seattle Mariners',
  'San Francisco Giants',
  'St. Louis Cardinals',
  'Tampa Bay Rays',
  'Texas Rangers',
  'Toronto Blue Jays',
  'Washington Nationals',
]);

const column_groups = [
  {
    groupId: 'Entry',
    children: [
      { field: 'tp_ids' },
      { field: 'client_ids' },
      { field: 'tps_keys' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Inventory details',
    children: [
      { field: 'event_name' },
      { field: 'event_date' },
      { field: 'issue' },
      { field: 'section' },
      { field: 'row' },
      { field: 'start_seat' },
      { field: 'end_seat' },
      { field: 'is_playoff' },
      { field: 'third_party_mobile_provider_id' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
  {
    groupId: 'Notes',
    children: [
      { field: 'tp_notes' },
      { field: 'inv_notes' },
      { field: 'client_notes' },
    ],
    headerClassName: 'tiq-grid-naming-group',
  },
];

export default function ProblematicListingDataGrid({
  rows,
  is_loading,
}: {
  rows: any[];
  is_loading: boolean;
}) {
  const [table_data, setTableData] = useState<any[]>([]);
  useEffect(() => {
    const new_data = [];
    for (const item of rows) {
      if (
        !excluded_events.has(item.event_name.split(' at ').pop() ?? '') &&
        !mlb_teams.has(item.event_name.split(' at ').pop() ?? '')
      )
        new_data.push(item);
    }
    setTableData(new_data);
  }, [rows]);

  return PageDataGrid({
    id: 'problematic-listing-report',
    rows: table_data,
    column_specs: [
      ...column_specs,
      DataGridNoteColumn({
        field: 'tp_notes',
        onSaveSucceed: (row, field, value) => {
          const new_data = [];
          for (const td of table_data) {
            for (let item of td.tp_notes) {
              if (item.id === value.id) item.note = value.note;
            }
            new_data.push(td);
          }
          setTableData(new_data);
        },
        doSave: api.upsertNoBarcodeReportNoteV2,
        api_key_field: 'tp_ids',
        isHidden: ({ row }) =>
          row.tp_ids === undefined || row.tp_ids.length === 0,
      }),
      DataGridNoteColumn({
        field: 'inv_notes',
        onSaveSucceed: (row, field, value) => {
          const new_data = [];
          for (const td of table_data) {
            for (let item of td.inv_notes) {
              if (item.id === value.id) item.note = value.note;
            }
            new_data.push(td);
          }
          setTableData(new_data);
        },
        doSave: api.upsertNoBarcodeReportNoteV2,
        api_key_field: 'inv_ids',
        isHidden: ({ row }) =>
          row.inv_ids === undefined || row.inv_ids.length === 0,
      }),
      DataGridNoteColumn({
        field: 'client_notes',
        onSaveSucceed: (row, field, value) => {
          const new_data = [];
          for (const td of table_data) {
            for (let item of td.client_notes) {
              if (item.id === value.id) item.note = value.note;
            }
            new_data.push(td);
          }
          setTableData(new_data);
        },
        doSave: api.upsertNoBarcodeReportNoteV2,
        api_key_field: 'client_ids',
        isHidden: ({ row }) =>
          row.client_ids === undefined || row.client_ids.length === 0,
      }),
    ],
    column_groups,
    filter_specs: [],
    getRowId: (row) => row.uuid,
    initialState: {
      sorting: {
        sortModel: [
          { field: 'event_name', sort: 'asc' },
          { field: 'event_date', sort: 'asc' },
        ],
      },
    },
    hide_client_filter: true,
  });
}
