import h from '../../../lib/react-hyperscript';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { Stack, Typography } from '@mui/material';
import { useAsyncFn } from 'react-use';
import * as api from '../../../common/api';
import { useEffect } from 'react';
import AwaitingBroadcastDataGrid from './AwaitingBroadcastDataGrid';

export default function AwaitingBroadcastListPanel() {
  const [data, fetchData] = useAsyncFn(api.getAwaitingBroadcastTickets);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(
      Typography,
      { variant: 'h5', fontWeight: 'bold' },
      'Awaiting Broadcast Tickets',
    ),

    h(FetchBackdrop, { state: { error: data.error, loading: data.loading } }),

    h(AwaitingBroadcastDataGrid, {
      rows: data.value ?? [],
      reloadData: fetchData,
    }),
  ]);
}
