import h from '../../../lib/react-hyperscript';
import {
  Box,
  Paper,
  Typography,
  Step,
  Stepper,
  StepLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { useAsyncFn } from 'react-use';
import { useBaseData } from '../../../common/base_data';
import * as api from '../../../common/api';
import React, { useState } from 'react';
import InventoryEditor from './InventoryEditor';
import EventFinder from './EventFinder';
import { ClientEvent } from '../../../common/types';
import { useSearchParams } from 'react-router-dom';

export default function AddSingleEventInventoryPanel() {
  const [state, createSingleEventInventory] = useAsyncFn(
    api.createSingleEventInventory,
  );
  const {
    baseData: { getClients },
  } = useBaseData();
  const [active_step, setActiveStep] = useState(0);
  const [selected_event, setSelectedEvent] = React.useState<null | ClientEvent>(
    null,
  );
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [searchParams] = useSearchParams();
  return h(
    Box,
    {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    [
      h(FetchBackdrop, { state }),
      h(
        Box,
        {
          sx: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        },

        [
          h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 4, pt: 2 }, [
            `Add Single Event Ticket`,
          ]),
          h(
            Grid,
            {
              container: true,
              spacing: 12,
              columns: { xs: 1, md: 1 },
              sx: { width: '800px' },
            },
            [
              h(Grid, { key: 1, xs: 1 }, [
                h(Box, { pb: 2 }, [
                  h(
                    Stepper,
                    {
                      activeStep: active_step,
                      alternativeLabel: true,
                      sx: { padding: '0px 10px 10px 10px' },
                    },
                    [
                      h(Step, { key: 'event-searching' }, [
                        h(StepLabel, {}, [
                          h(Typography, { fontWeight: 'bold' }, [
                            `Choose Event`,
                          ]),
                        ]),
                      ]),
                      h(Step, { key: 'inventory-seat-info' }, [
                        h(StepLabel, {}, [
                          h(
                            Typography,
                            {
                              fontWeight: active_step >= 1 ? 'bold' : 'normal',
                            },
                            [`Create Inventory`],
                          ),
                        ]),
                      ]),
                    ],
                  ),
                ]),
                h(Paper, { elevation: 3 }, [
                  h(Box, { pr: 2, pb: 2, pl: 2, pt: 2 }, [
                    EventFinder({
                      init_data: {
                        default_search_text:
                          searchParams.get('event_description') ?? '',
                        default_search_date:
                          searchParams.get('event_date') ?? '',
                      },
                      display: active_step === 0 ? '' : 'none',
                      selected_event,
                      setSelectedEvent,
                      onNext: () => handleNext(),
                    }),

                    h(InventoryEditor, {
                      init_data: {
                        client_id: searchParams.get('client_id')
                          ? parseInt(searchParams.get('client_id') ?? '')
                          : null,
                        section: searchParams.get('section') ?? '',
                        row: searchParams.get('row') ?? '',
                        first_seat: searchParams.get('first_seat') ?? '',
                        last_seat: searchParams.get('last_seat') ?? '',
                        req_id: searchParams.get('req_id') ?? '',
                      },
                      event: selected_event,
                      display: active_step === 1 ? '' : 'none',
                      clients: getClients(),
                      onSubmit: (client_id, inv) =>
                        createSingleEventInventory(client_id, inv).then(
                          () => (window.location.href = `/single-event`),
                        ),
                      onBack: () => handleBack(),
                    }),
                  ]),
                ]),
              ]),
            ],
          ),
        ],
      ),
    ],
  );
}
