import h from '../../../lib/react-hyperscript';
import * as _ from 'ramda';
import {
  Stack,
  Link,
  Box,
  Typography,
  Button,
  Chip,
  Tooltip,
  Dialog,
} from '@mui/material';
import { useState, useEffect } from 'react';

import {
  CredsInfo,
  CredsValidationStatus,
  CredsStatus,
} from '../../../common/types';
import { Timeline } from '../../../common/components';

import { SimpleSelect } from '../../../common/components';
import LoadingButton from '@mui/lab/LoadingButton';

const optionValues = [
  {
    label: 'Pending',
    value: CredsValidationStatus.PENDING,
  },
  {
    label: 'Valid',
    value: CredsValidationStatus.VALID,
  },
  {
    label: 'Invalid',
    value: CredsValidationStatus.INVALID,
  },
];

function StatusControls({
  status,
  status_changed,
  onUpdate,
  onIsEditing = () => {},
}: {
  status: CredsValidationStatus;
  status_changed: Date;
  onUpdate: (qs: CredsValidationStatus) => void;
  onIsEditing?: (isEditing: boolean) => void;
}) {
  const [is_edit_mode, setIsEditMode] = useState(false);
  const [new_status, setNewCredsValidationStatus] = useState(status);

  const handleClickChange = () => {
    setIsEditMode(true);
  };

  const handleClickCancel = () => {
    setNewCredsValidationStatus(status);
    setIsEditMode(false);
  };

  const handleClickSave = () => {
    setIsEditMode(status === null || false);
    onUpdate(new_status as CredsValidationStatus);
  };

  const handleClickRevalidate = () => {
    onUpdate(CredsValidationStatus.VALID);
    setIsEditMode(false);
  };

  useEffect(() => {
    onIsEditing(is_edit_mode);
  }, [is_edit_mode, onIsEditing]);

  useEffect(() => {
    setNewCredsValidationStatus(status);
  }, [status]);

  const chipCommon = {
    variant: 'outlined' as 'outlined',
    sx: { fontSize: '16px' },
  };

  const status_chip = {
    [CredsValidationStatus.PENDING]: h(Chip, {
      ...chipCommon,
      label: h(Tooltip, {
        title: `Last updated: ${new Date(status_changed).toLocaleString()}`,
        children: h('', 'Pending'),
      }),
    }),
    [CredsValidationStatus.VALID]: h(Chip, {
      ...chipCommon,
      label: h(Tooltip, {
        title: `Last updated: ${new Date(status_changed).toLocaleString()}`,
        children: h('', 'Valid'),
      }),
      color: 'success',
    }),
    [CredsValidationStatus.INVALID]: h(Chip, {
      ...chipCommon,
      label: h(Tooltip, {
        title: `Last updated: ${new Date(status_changed).toLocaleString()}`,
        children: h('', 'Invalid'),
      }),
      variant: 'filled',
      color: 'error',
    }),
  }[new_status ?? status];

  return h(Stack, [
    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        sx: { width: '100%', height: '40px' },
      },
      [
        h(
          Stack,
          {
            direction: 'row',
            justifyContent: 'end',
            sx: { width: '100%' },
          },
          is_edit_mode
            ? [
                h(SimpleSelect<CredsValidationStatus>, {
                  autoFocus: true,
                  disabled: !is_edit_mode,
                  optionValues,
                  fullWidth: true,
                  sx: { mt: -1 },
                  label: '',
                  name: 'league_id',
                  defaultValue: new_status,
                  onChange: (val) => setNewCredsValidationStatus(val),
                }),
              ]
            : [status_chip],
        ),
        h(
          Stack,
          {
            direction: 'row',
            justifyContent: 'start',
            sx: { width: '100%' },
          },
          is_edit_mode
            ? [
                h(
                  Button,
                  {
                    key: 'cancel',
                    variant: 'outlined',
                    sx: { ml: 2, width: '90px' },
                    onClick: () => handleClickCancel(),
                  },
                  'Cancel',
                ),
                h(
                  Button,
                  {
                    key: 'save',
                    variant: 'contained',
                    sx: { ml: 2, width: '90px' },
                    onClick: handleClickSave,
                  },
                  'Save',
                ),
                status === CredsValidationStatus.VALID
                  ? h(
                      Button,
                      {
                        key: 'save',
                        variant: 'contained',
                        color: 'success',
                        sx: { ml: 2 },
                        onClick: handleClickRevalidate,
                      },
                      'Revalidate',
                    )
                  : null,
              ]
            : [
                h(
                  Button,
                  { key: 'edit', sx: { ml: 2 }, onClick: handleClickChange },
                  'Change',
                ),
              ],
        ),
      ],
    ),
  ]);
}

const formatDate = (date: Date | null) => {
  if (!date) return 'N/A';

  return (
    new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      month: '2-digit',
      year: 'numeric',
      day: '2-digit',
      timeZone: 'UTC',
    }) +
    ' ' +
    new Date(date).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    })
  );
};

export default function CredentialsControls({
  creds_status,
  creds_id,
  creds_status_changed,
  creds_username,
  creds_password_token,
  onUpdate,
  onIsEditing,
  onValidating,
  is_validation_supported,
}: CredsInfo & {
  onUpdate: (qs: CredsValidationStatus) => void;
  onIsEditing?: (isEditing: boolean) => void;
  onValidating: (creds_id: number) => Promise<void>;
  is_validation_supported: boolean;
}) {
  const timeline = [{ left: creds_status_changed, right: creds_status }];

  const timeline_str = _.pipe(
    // Ramda needs some help here with the typing
    (tl) => _.sortBy(_.prop('left'))(tl) as { left: Date; right: string }[],
    (tl) => _.filter(({ left }) => !!left, tl),
    _.map(({ left, right }) => ({
      left: formatDate(left),
      right,
    })),
  )(timeline);

  const validation_status =
    creds_status === CredsStatus.INVALIDATED
      ? CredsValidationStatus.INVALID
      : creds_status === CredsStatus.VALIDATED
      ? CredsValidationStatus.VALID
      : CredsValidationStatus.PENDING;

  const [is_validating_creds, setValidatingCreds] = useState(false);
  const [is_dialog_open, setDialogOpen] = useState(false);

  return h(Stack, { alignItems: 'center' }, [
    h(
      Stack,
      {
        direction: 'column',
        alignItems: 'center',
        sx: { mb: 1, border: '1px dotted black', p: 2, width: '80%' },
      },
      [
        h(Typography, creds_username ?? ''),

        creds_status === CredsStatus.NOT_SUBMITTED
          ? h(
              Typography,
              { fontWeight: 'bold', color: 'gray' },
              'Credentials not submitted',
            )
          : h(Stack, { gap: 1, mt: 1 }, [
              h(
                Link,
                {
                  key: 'view-password',
                  href:
                    process.env.REACT_APP_TOKENIZER_URL +
                    `/${creds_password_token}`,
                },
                'view password',
              ),
              h(
                LoadingButton,
                {
                  variant: 'text',
                  size: 'small',
                  disabled: !is_validation_supported,
                  loading: is_validating_creds,
                  onClick: async () => {
                    setDialogOpen(true);
                  },
                },
                is_validation_supported
                  ? 'validate credentials'
                  : 'automated validation not supported',
              ),
            ]),
      ],
    ),
    creds_status === CredsStatus.NOT_SUBMITTED
      ? ''
      : (h(Box, { sx: { mb: 1 } }, [h(Timeline, { steps: timeline_str })]),
        h(StatusControls, {
          status: validation_status,
          status_changed: creds_status_changed,
          onUpdate,
          onIsEditing,
        })),

    h(
      Dialog,
      {
        open: is_dialog_open,
        onClose: () => setDialogOpen(false),
      },
      [
        h(
          Box,
          {
            sx: {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 3,
            },
          },
          [
            h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 3 }, [
              'Validate Team Credentials',
            ]),

            h(Stack, { mb: 4, gap: 1, width: '100%' }, [
              h(
                Typography,
                {
                  variant: 'body1',
                  fontWeight: 'bold',
                },
                `This will validate the team credentials via Ticketmaster API.`,
              ),
              h(
                Typography,
                {
                  variant: 'body1',
                  fontWeight: 'bold',
                },
                'Clients may receive an email notification about this action from Ticketmaster.',
              ),
            ]),

            h(
              'div',
              {
                sx: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              },
              [
                h(
                  Button,
                  {
                    variant: 'contained',
                    sx: { marginX: 2 },
                    color: 'error',
                    onClick: () => setDialogOpen(false),
                  },
                  ['Cancel'],
                ),
                h(
                  Button,
                  {
                    variant: 'contained',
                    sx: { marginX: 2 },
                    color: 'success',
                    onClick: async () => {
                      setDialogOpen(false);
                      setValidatingCreds(true);
                      await onValidating(creds_id);
                      setValidatingCreds(false);
                    },
                  },
                  ['Yes'],
                ),
              ],
            ),
          ],
        ),
      ],
    ),
  ]);
}
