import { useState, createContext, useContext } from 'react';
import { sleep } from './util';
import * as indexDB from './index_db';

function isDateExpired(date: Date | null, seconds: number) {
  if (!date) return true;

  const diff = new Date().getTime() - date.getTime();
  return diff > seconds * 1000;
}

export const InitCachedDataModule = (
  fetchData: (pagination?: { page: number; pageSize: number }) => Promise<any>,
  expire_after: number = 300,
  paginationLoading: boolean = false,
  indexDatabaseConfig?: {
    module_name: string;
    is_enabled: boolean;
  },
) => {
  const [value, setValue] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [timestamp, setTimestamp] = useState<Date | null>(null);

  const loadDataFromIndexDB = async () => {
    if (!indexDatabaseConfig?.module_name) return false;
    try {
      const data = await indexDB.getFromDatabaseStore(
        indexDatabaseConfig.module_name,
        'data',
      );
      const created = (await indexDB.getFromDatabaseStore(
        indexDatabaseConfig.module_name,
        'created',
      )) as Date;
      if (data && !isDateExpired(created, expire_after)) {
        setValue(data);
        setTimestamp(new Date(created));
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const loadData = async (force_update = false) => {
    if (loading) return;
    resetData();
    setLoading(true);
    if (
      force_update &&
      indexDatabaseConfig?.is_enabled &&
      indexDatabaseConfig?.module_name
    )
      await indexDB.clearDatabaseStore(indexDatabaseConfig.module_name);
    try {
      let is_loaded = false;
      if (!force_update && indexDatabaseConfig?.is_enabled) {
        is_loaded = await loadDataFromIndexDB();
      }
      if (!is_loaded) {
        const resp = await fetchData();
        setValue(resp);
        setTimestamp(new Date());
        if (
          indexDatabaseConfig?.is_enabled &&
          indexDatabaseConfig?.module_name
        ) {
          await indexDB.saveToDatabaseStore(
            indexDatabaseConfig.module_name,
            'data',
            resp,
          );
          await indexDB.saveToDatabaseStore(
            indexDatabaseConfig.module_name,
            'created',
            new Date(),
          );
        }
      }
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const loadDataByPagination = async (force_update = false) => {
    if (!paginationLoading) return loadData();
    if (loading) return;
    resetData();
    setLoading(true);
    if (
      force_update &&
      indexDatabaseConfig?.is_enabled &&
      indexDatabaseConfig?.module_name
    )
      await indexDB.clearDatabaseStore(indexDatabaseConfig.module_name);
    let page = 0;
    const pageSize = 10000;
    let total_resp = [];
    try {
      let is_loaded = false;
      if (!force_update && indexDatabaseConfig?.is_enabled) {
        is_loaded = await loadDataFromIndexDB();
      }
      if (!is_loaded) {
        do {
          page++;
          const resp = await fetchData({ page, pageSize });
          total_resp.push(...resp);
          setValue([...total_resp]);
          await sleep(1000);
        } while (total_resp.length >= page * pageSize && page <= 10);
        setTimestamp(new Date());
        if (
          indexDatabaseConfig?.is_enabled &&
          indexDatabaseConfig?.module_name
        ) {
          await indexDB.saveToDatabaseStore(
            indexDatabaseConfig.module_name,
            'data',
            total_resp,
          );
          await indexDB.saveToDatabaseStore(
            indexDatabaseConfig.module_name,
            'created',
            new Date(),
          );
        }
      }
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const resetData = () => {
    setError(null);
    setValue([]);
    setTimestamp(null);
  };

  const invalidateData = () => {
    setTimestamp(null);
  };

  const is_expired = !value || isDateExpired(timestamp, expire_after);

  return {
    value,
    loading,
    error,
    timestamp,
    is_expired,
    loadData,
    invalidateData,
    loadDataByPagination,
  };
};

export const CachedDataContext = createContext(
  {} as {
    [key: string]: {
      value: any;
      loading: boolean;
      error: any;
      timestamp: Date | null;
      is_expired: boolean;
      loadData: (force_update?: boolean) => void;
      invalidateData: () => void;
      loadDataByPagination: (force_update?: boolean) => void;
    };
  },
);

export const useCachedData = (module_name: string) =>
  useContext(CachedDataContext)[module_name];
