import h from '../../lib/react-hyperscript';
import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { format } from './QueryDataGrid';
import RefreshIcon from '@mui/icons-material/Refresh';

export const FetchPageHeader = ({
  header,
  state,
  doFetch,
}: {
  header: string;
  state: any;
  doFetch: (force_update?: boolean) => void;
}) => {
  const [response_time, setResponseTime] = useState<string | null>(null);

  useEffect(() => {
    setResponseTime(state.loading ? null : new Date().toISOString());
  }, [state]);

  return h(Box, { mb: 4 }, [
    h(Typography, { variant: 'h5', fontWeight: 'bold' }, header),
    h(
      Stack,
      {
        direction: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        width: '100%',
      },
      [
        h(Stack, { gap: 4, direction: 'row', alignItems: 'center' }, [
          h(
            Typography,
            { variant: 'body1', textAlign: 'end' },
            `Last loaded at: ${
              response_time
                ? `${format.datetime({ value: response_time })}`
                : ''
            }`,
          ),
        ]),
        state.loading
          ? h(
              Box,
              {
                height: 40,
                width: 40,
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              },
              [h(CircularProgress, { color: 'inherit', size: 24 })],
            )
          : h(IconButton, {
              onClick: () => doFetch(true),
              children: h(RefreshIcon, {}),
            }),
      ],
    ),
  ]);
};
