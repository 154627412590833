import { BaseData, LeagueId } from './types';
import { getBaseData } from './api';
import * as _ from 'ramda';
import React from 'react';

export const fetchBaseData = async (
  opts: {
    include_archived?: boolean;
    include_test_data?: boolean;
  } = {},
) => {
  // Load defaults from localStorage
  const include_archived =
    opts.include_archived ??
    JSON.parse(window.localStorage.getItem('include_archived') ?? 'false');

  const include_test_data =
    opts.include_test_data ??
    JSON.parse(window.localStorage.getItem('include_test_data') ?? 'false');

  window.localStorage.setItem(
    'include_archived',
    JSON.stringify(include_archived),
  );
  window.localStorage.setItem(
    'include_test_data',
    JSON.stringify(include_test_data),
  );

  const data = await getBaseData({ include_test_data });
  return baseData({ include_archived, include_test_data }, data);
};

export const BaseDataContext = React.createContext(
  {} as {
    fetchBaseData: typeof fetchBaseData;
    baseData: ReturnType<typeof baseData>;
  },
);

export const useBaseData = () => React.useContext(BaseDataContext);

export type BaseDataProvider = ReturnType<typeof baseData>;

const baseData = (
  {
    include_archived,
    include_test_data,
  }: { include_archived: boolean; include_test_data: boolean },
  data: BaseData,
) => {
  const getTeamsForLeague = (league_id: LeagueId) => {
    return _.filter((t) => t.league === league_id, data.teams);
  };

  const getLeagueIds = () => {
    return _.pluck('league')(_.sortBy(_.prop('seq'), data.leagues));
  };

  const getSeasonStartYears = () => {
    return _.uniq(
      _.pluck('start_year')(_.sortBy(_.prop('start_year'), data.seasons)),
    );
  };

  const getLeagueById = (league_id: LeagueId) => {
    return data.leagues.find((l) => l.league === league_id);
  };

  const getClients = () => {
    return _.filter((c) => c.is_client || include_test_data, data.clients);
  };

  const _clientsById = _.indexBy(_.prop('id'), getClients());

  const getClient = (client_id: number) => {
    return _clientsById[client_id];
  };

  const _occUserById = _.indexBy(_.prop('id'), data.occ_users);

  const getOccUserById = (user_id: number) => {
    return _occUserById[user_id];
  };

  const getPlayoffRoundsForCurrenLeague = (league_id: LeagueId) => {
    const season = getLeagueById(league_id)?.selling_season;
    return _.filter(
      (r) => r.league === league_id && r.season === season,
      data.playoff_rounds,
    ).sort((a, b) => a.round_order - b.round_order);
  };

  const getPlayoffRoundsForAllLeagues = () => {
    const grouped_playoff_rounds = _.groupBy(
      _.prop('name'),
      data.playoff_rounds,
    );
    const res = [];
    for (const pr_name in grouped_playoff_rounds) {
      const round_list = grouped_playoff_rounds[pr_name];
      res.push({
        name: pr_name,
        order: round_list.reduce(
          (val, cur_obj) =>
            val > cur_obj.round_order ? val : cur_obj.round_order,
          0,
        ),
        leagues: round_list.map((r) => ({
          id: r.id,
          league_name: r.league_name,
          league: r.league,
          season: r.season,
        })),
      });
    }
    return res.sort((a, b) => a.order - b.order);
  };

  const getPlayoffRoundById = (id: number) => {
    return data.playoff_rounds.find((pr) => pr.id === id);
  };

  const _teamsByName = _.indexBy(_.prop('name'), data.teams);
  const _teamsById = _.indexBy(_.prop('id'), data.teams);

  const getTeamNameShortByTeam = (team_name: string) => {
    if (!team_name) return '';
    return _teamsByName[team_name.trim()]?.name_short ?? team_name;
  };

  const getSalesBackFillDate = () => {
    return new Date(
      process.env.REACT_APP_SALE_CONFIRMATION_START_DATE ??
        '2023-02-08T00:00:00.000Z',
    );
    // return process.env.REACT_APP_NODE_ENV === 'development'
    //   ? new Date('2000-01-01T00:00:00.000Z')
    //   : new Date('2023-02-08T00:00:00.000Z');
  };

  const getTeamTmSystemId = (team_id: number) => {
    return _teamsById[team_id]?.ticketmaster_system_id;
  };

  const getTeam = (team_id: number) => _teamsById[team_id];

  const _clientsHavingNotesById = _.indexBy(
    _.prop('id'),
    data.clientsHavingNotes,
  );
  const isAnyClientNotes = (client_id: number) =>
    _clientsHavingNotesById[client_id];
  const recordClientNoteStatus = (client_id: number, has_notes: boolean) => {
    if (has_notes) _clientsHavingNotesById[client_id] = { id: client_id };
    else delete _clientsHavingNotesById[client_id];
  };

  const _packagesHavingNotesById = _.indexBy(
    _.prop('id'),
    data.packagesHavingNotes,
  );
  const isAnyPackageNotes = (package_id: number) =>
    _packagesHavingNotesById[package_id];
  const recordPackageNoteStatus = (package_id: number, has_notes: boolean) => {
    if (has_notes) _packagesHavingNotesById[package_id] = { id: package_id };
    else delete _packagesHavingNotesById[package_id];
  };

  const hydrateClients = (rows: { client_id: number }[]) => {
    return rows.map((row) => {
      const client = getClient(row.client_id);
      return {
        ...row,
        client_name: client?.first_name + ' ' + client?.last_name,
      };
    });
  };

  const hydrateTeams = (
    rows: { home_team_id: number; away_team_id: number }[],
  ) => {
    return rows.map((row) => {
      const hteam = getTeam(row.home_team_id);
      const ateam = getTeam(row.away_team_id);
      return {
        ...row,
        league: hteam?.league,
        team: hteam?.name_short,
        team_abbrev: hteam?.abbrev,
        opponent: ateam?.name_short,
        opponent_abbrev: ateam?.abbrev,
      };
    });
  };

  return {
    include_archived,
    include_test_data,
    getTeamsForLeague,
    getLeagueIds,
    getSeasonStartYears,
    getClients,
    getClient,
    getPlayoffRoundsForCurrenLeague,
    getTeamNameShortByTeam,
    getSalesBackFillDate,
    getPlayoffRoundsForAllLeagues,
    getPlayoffRoundById,
    getLeagueById,
    getOccUserById,
    getTeamTmSystemId,
    getTeam,
    isAnyClientNotes,
    isAnyPackageNotes,
    recordClientNoteStatus,
    recordPackageNoteStatus,
    hydrateClients,
    hydrateTeams,
  };
};
