import h from '../../lib/react-hyperscript';
import DocumentationPanel from './components/DocumentationPanel';
import Documentation from './components/Documentation';

const routing = {
  element: h(DocumentationPanel),
  children: [
    {
      path: '',
      element: h(Documentation),
    },
  ],
};

export { routing };
