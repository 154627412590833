import h from '../../../lib/react-hyperscript';
import {
  Stack,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toCommissionStr } from '../../../common/helper';
import { Commission, LeagueId } from '../../../common/types';
import debounce from '../../../common/util/debounce';

export default function TicketPackageCommission({
  tp_id,
  commissions,
  commission,
  commission_tier,
  onUpdate,
  onIsEditing = () => {},
  getCommissionByTier,
}: {
  tp_id: number;
  commissions: Commission[];
  commission?: string;
  commission_tier: string | null;
  onUpdate: (com_tier: string, tiq_commission: string | null) => void;
  onIsEditing?: (isEditing: boolean) => void;
  getCommissionByTier: (commission_tier: string) => Promise<{
    commission: string;
    third_party_cost: string;
    tp_source?: {
      league: LeagueId;
      id: number;
      team_abbrev: string;
      section: string;
      row: string;
      first_seat: string;
      last_seat: string;
      season: number;
    };
  }>;
}) {
  const currentCommissionTier = commission_tier || '';
  const isValidCommissionTier = (tier: string) =>
    commissions.find((c) => c.commission_tier === tier && c.is_active)
      ?.commission_tier || '';
  const [is_edit_mode, setIsEditMode] = useState(false);
  const [selectedCommissionTier, setselectedCommissionTier] = useState(
    isValidCommissionTier(currentCommissionTier) ? currentCommissionTier : '',
  );
  const [newCommission, setNewCommission] = useState(
    toCommissionStr(commission ?? ''),
  );
  const [most_recent_tier, setMostRecentTier] = useState<{
    league: LeagueId;
    id: number;
    team_abbrev: string;
    section: string;
    row: string;
    first_seat: string;
    last_seat: string;
    commission: string;
    season: number;
  } | null>(null);

  const handleClickChange = () => {
    setselectedCommissionTier(
      isValidCommissionTier(currentCommissionTier) ? currentCommissionTier : '',
    );
    setNewCommission(toCommissionStr(commission ?? ''));
    setIsEditMode(true);
  };

  const handleClickCancel = () => {
    setselectedCommissionTier(currentCommissionTier);
    setNewCommission(toCommissionStr(commission ?? ''));
    setIsEditMode(false);
  };

  const handleClickSave = () => {
    setIsEditMode(false);
    onUpdate(
      selectedCommissionTier,
      (parseFloat(newCommission || '0') / 100).toFixed(4),
    );
  };

  const isStandardCommission = (
    commission: string | null,
    commission_tier: string | null,
  ) => {
    if (!commission || !commission_tier) return false;
    return !!commissions.find(
      (item) =>
        item.commission_tier === commission_tier &&
        +`${item.tiq_commission}` === +commission &&
        item.is_active,
    );
  };

  const debouncedGetCommissionByTier = debounce(async () => {
    const resp = await getCommissionByTier(selectedCommissionTier);
    setNewCommission(toCommissionStr(resp.commission));
    if (resp.tp_source)
      setMostRecentTier({ ...resp.tp_source, commission: resp.commission });
    else setMostRecentTier(null);
  }, 500);

  useEffect(() => {
    onIsEditing(is_edit_mode);
  }, [is_edit_mode, onIsEditing]);

  useEffect(() => {
    if (is_edit_mode && selectedCommissionTier) {
      debouncedGetCommissionByTier();
    }
  }, [selectedCommissionTier]);

  useEffect(() => {
    if (isValidCommissionTier(selectedCommissionTier)) {
      getCommissionByTier(selectedCommissionTier).then((resp) => {
        if (resp.tp_source)
          setMostRecentTier({ ...resp.tp_source, commission: resp.commission });
      });
    }
  }, [commission]);

  return h('div', [
    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        gap: 2,
        sx: { width: '100%', minHeight: '40px' },
      },

      [
        h(
          Stack,
          {
            direction: 'row',
            justifyContent: is_edit_mode ? 'end' : 'center',
            alignItems: 'center',
            gap: is_edit_mode ? 1 : 3,
          },

          is_edit_mode
            ? [
                h(FormControl, { size: 'small' }, [
                  h(InputLabel, { sx: { background: '#fff' } }, 'Tier'),
                  h(
                    Select,
                    {
                      autoFocus: true,
                      value: selectedCommissionTier,
                      sx: { minWidth: '130px' },
                      onChange: (e) => {
                        setselectedCommissionTier(e.target.value as string);
                      },
                    },
                    commissions
                      .filter((item) => item.is_active)
                      .map((c) =>
                        h(
                          MenuItem,
                          {
                            key: c.commission_tier,
                            value: c.commission_tier,
                          },
                          `${c.commission_tier}`,
                        ),
                      ),
                  ),
                ]),
                h(TextField, {
                  type: 'number',
                  disabled: !is_edit_mode,
                  size: 'small',
                  id: 'tiq_commission',
                  label: 'TA Commission',
                  name: 'tiq_commission',
                  value: newCommission,
                  onChange: (e) => {
                    console.log(e.target.value);
                    setNewCommission(e.target.value);
                  },
                  sx: {
                    width: '110px',
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: 'none',
                      },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                      fontWeight: isStandardCommission(
                        (parseFloat(newCommission || '0') / 100).toFixed(4),
                        selectedCommissionTier,
                      )
                        ? 400
                        : 'bold',
                    },
                  },
                  InputProps: {
                    endAdornment: h(InputAdornment, { position: 'end' }, '%'),
                  },
                }),
              ]
            : [
                h(
                  Typography,
                  { variant: 'h5', sx: { textWrap: 'nowrap' } },
                  currentCommissionTier
                    ? `Tier ${currentCommissionTier}`
                    : '--',
                ),

                h(Stack, { direction: 'row', gap: 0.5, alignItems: 'center' }, [
                  commission
                    ? h(
                        Typography,
                        { variant: 'h6' },
                        `${toCommissionStr(commission)}%`,
                      )
                    : null,
                  commission
                    ? h(
                        Typography,
                        {
                          variant: 'subtitle1',
                          fontWeight: 'bold',
                          sx: { textWrap: 'nowrap' },
                        },
                        isStandardCommission(commission, commission_tier)
                          ? ''
                          : '(non-standard)',
                      )
                    : null,
                ]),
              ],
        ),
        h(
          Stack,
          {
            direction: 'row',
            justifyContent: 'start',
            gap: 2,
          },
          is_edit_mode
            ? [
                h(
                  Button,
                  {
                    key: 'cancel',
                    variant: 'outlined',
                    onClick: () => handleClickCancel(),
                  },
                  'Cancel',
                ),
                h(
                  Button,
                  {
                    key: 'save',
                    variant: 'contained',
                    onClick: handleClickSave,
                    disabled: !newCommission,
                  },
                  'Save',
                ),
              ]
            : [
                h(
                  Button,
                  { key: 'edit', sx: { ml: 2 }, onClick: handleClickChange },
                  'Change',
                ),
              ],
        ),
      ],
    ),

    h(
      Stack,
      {
        sx: { width: '100%' },
      },
      most_recent_tier && tp_id !== most_recent_tier.id
        ? [
            h(
              Stack,
              {
                direction: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              },
              [
                h(
                  Typography,
                  { fontSize: '13px' },
                  `Most recent tier ${selectedCommissionTier}: `,
                ),
                h(
                  Typography,
                  { fontSize: '13px' },
                  `${toCommissionStr(most_recent_tier.commission)}% `,
                ),
                h(
                  Typography,
                  { fontSize: '13px' },
                  isStandardCommission(
                    most_recent_tier.commission,
                    selectedCommissionTier,
                  )
                    ? ''
                    : '(non-standard)',
                ),
              ],
            ),
            h(
              Stack,
              {
                direction: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
              },
              [
                h(
                  Typography,
                  { fontSize: '13px' },
                  `${most_recent_tier.season}`,
                ),
                h(
                  Typography,
                  { fontSize: '13px' },
                  `${most_recent_tier.league}`,
                ),
                h(
                  Typography,
                  { fontSize: '13px' },
                  `${most_recent_tier.team_abbrev}`,
                ),
                h(
                  Typography,
                  { fontSize: '13px' },
                  `(S:${most_recent_tier.section}`,
                ),
                h(Typography, { fontSize: '13px', fontWeight: 'bold' }, '|'),
                h(
                  Typography,
                  { fontSize: '13px' },
                  `R:${most_recent_tier.row}`,
                ),
                h(Typography, { fontSize: '13px', fontWeight: 'bold' }, '|'),
                h(
                  Typography,
                  { fontSize: '13px' },
                  `S:${most_recent_tier.first_seat}-${most_recent_tier.last_seat})`,
                ),
              ],
            ),
          ]
        : [
            h(
              Typography,
              { fontSize: '13px' },
              selectedCommissionTier
                ? `Most recent tier ${selectedCommissionTier}: None`
                : '',
            ),
          ],
    ),

    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        gap: 1,
        sx: { width: '100%' },
      },
      [
        h(
          Typography,
          {
            fontSize: '13px',
            sx: {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
            onClick: () => {
              window.open(
                'https://docs.google.com/spreadsheets/d/1uf2IlkOZwkk0c0C_SF3q4d8wqHqI2ffKhEUYd2gn7zw/edit?gid=961367321#gid=961367321',
                '_blank',
              );
            },
          },
          'Rate table',
        ),
      ],
    ),
  ]);
}
