import h from '../../lib/react-hyperscript';
import { CircularProgress } from '@mui/material';

const LoadingBlock = ({
  height,
  spinSize,
}: {
  height: string;
  spinSize?: number;
}) => {
  return h(
    'div',
    {
      style: {
        width: '100%',
        height,
        backgroundColor: 'rgba(0,0,0,0.05)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    [
      h('div', { style: { display: 'flex', justifyContent: 'center' } }, [
        h(CircularProgress, {
          size: spinSize ?? 40,
          sx: {
            // position: 'absolute',
            // top: '42%',
            // left: '45%',
            height: '100%',
            width: '100%',
          },
        }),
      ]),
    ],
  );
};

export default LoadingBlock;
