import h from '../../lib/react-hyperscript';
import { Drawer as MuiDrawer, Stack } from '@mui/material';

const ApplicationMenu = ({
  open,
  onClose,
  isCollapsable,
  children,
}: {
  open: boolean;
  isCollapsable: boolean;
  //onMenuClicked: (id: string) => void;
  onClose: () => void;
  children?: [];
}) => {
  return h(
    MuiDrawer,
    {
      anchor: 'right',
      variant: isCollapsable ? 'temporary' : 'persistent',
      elevation: 15,
      open,
      onClose,
    },
    [
      h(
        Stack,
        {
          justifyContent: 'space-between',
          sx: {
            width: 340,
            height: '100%',
            marginTop: '60px',
            marginBottom: '15px',
          },
          role: 'presentation',
        },
        children,
      ),
    ],
  );
};

export default ApplicationMenu;
