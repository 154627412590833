import h from '../../lib/react-hyperscript';
import ProblematicListingPanel from './components/ProblematicListingPanel';
import ProblematicListingListPanel from './components/ProblematicListingListPanel';

const routing = {
  element: h(ProblematicListingPanel),
  children: [
    {
      path: '',
      element: h(ProblematicListingListPanel),
    },
  ],
};

export { routing };
