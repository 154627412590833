import h from '../../../lib/react-hyperscript';
import CachedDataPanel from '../../../common/components/CachedDataPanel';
import TeamPricingDataGrid from './TeamPricingDataGrid';

export default function EventPricingPanel() {
  return h(CachedDataPanel, {
    header: 'Team Pricing',
    cache_key: 'team_pricing',
    componentFn: ({ rows }) =>
      h(TeamPricingDataGrid, {
        rows,
      }),
  });
}
