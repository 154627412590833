import { useBaseData, BaseDataProvider } from '../../../common/base_data';
import {
  ClientDrillDown,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import * as _ from 'ramda';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';

import DataGridEntryLink from '../../../common/components/DataGridEntryLink';
import { LeagueId } from '../../../common/types';
import { PackageDrillDown } from '../../../common/components/PackageDrillDown';

import * as agg from './aggregatePricingColumns';

const column_specs: GridColDef[] = [
  {
    field: 'pkg_id',
    headerName: 'pkg_id',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.id },
        `/pricing/inventory?filter.01.package_id=equals%2C${params.row.id}&v=2`,
      ),
  },
  {
    field: 'client_name',
    headerName: 'client',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    valueGetter: ({ row }) => row.client_name,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'package',
    headerName: 'package',
    minWidth: 270,
    valueGetter: ({ row }) =>
      `'${row.season - 2000} ${row.league} ${row.team_abbrev} • ${
        row.section
      } • ${row.row} • ${row.first_seat + '-' + row.last_seat}`,
    renderCell: (params) =>
      PackageDrillDown({
        label: params.value,
        package_id: params.row.id,
      }),
  },
  {
    field: 'is_amp',
    headerName: 'amp',
    width: 75,
    type: 'boolean',
  },

  ...agg.column_specs,

  {
    field: 'client_id',
  },
  { field: 'season', width: 75 },
  { field: 'league', width: 75 },
  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'team',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.team },
        `/pricing/packages?filter.01.team=equals%2C${params.row.team}&filter.02.season=equals%2C${params.row.season}&filterOp=and&v=2`,
      ),
  },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => row.first_seat + '-' + row.last_seat,
    minWidth: 50,
    maxWidth: 75,
  },
  {
    field: 'is_shown',
    width: 75,
    type: 'boolean',
  },
];

const column_groups = [
  ...agg.column_groups,
  {
    groupId: 'Package',
    children: [
      { field: 'pkg_id' },
      { field: 'client_name' },
      { field: 'package' },
      { field: 'is_amp' },
    ],
  },
  {
    groupId: 'Package Details',
    children: [
      { field: 'client_id' },
      { field: 'season' },
      { field: 'league' },
      { field: 'is_playoff' },
      { field: 'team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'amp' },
      { field: 'is_shown' },
    ],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | LeagueId>[] = [
    ...agg.buildFilterSpecs(baseData),

    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
  ];
  return filter_specs;
};

const hydrateRows = (
  baseData: BaseDataProvider,
  rows: { client_id: number; team_id: number }[],
) => {
  return rows.map((row) => {
    const client = baseData.getClient(row.client_id);
    const hteam = baseData.getTeam(row.team_id);
    return {
      ...row,
      client_name: client?.first_name + ' ' + client?.last_name,
      team: hteam?.name_short,
      team_abbrev: hteam?.abbrev,
    };
  });
};

export default function PackagePricingDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  rows = hydrateRows(baseData, rows);
  return PageDataGrid({
    id: 'ticket_package_pricing',
    getRowId: (row) => row.id + '-' + row.is_amp,
    rows: _.filter((r) => r.is_client || baseData.include_test_data, rows),
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
  });
}
