import { Stack } from '@mui/material';
import * as _ from 'ramda';
import h from '../../lib/react-hyperscript';
import { format } from '.';

const PercentColorCell = ({ value }: { value?: number }) => {
  if (_.isNil(value)) {
    return null;
  }
  const color =
    value >= 0
      ? `rgba(220, 100, 100, ${Math.min(value, 0.8)})`
      : `rgba(100, 100, 220, ${Math.min(-value, 0.8)})`;
  return h(
    Stack,
    {
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      sx: {
        backgroundColor: color,
        textAlign: 'right',
      },
    },
    format.percent({ value }) as string,
  );
};

export default PercentColorCell;
