import h from '../../lib/react-hyperscript';
import { Box, Autocomplete, Stack, TextField } from '@mui/material';
import * as _ from 'ramda';

import { Client } from '../types';

export type ClientAutoCompleteProps = {
  clients: Client[];
  onChange?: (client: Client) => void;
  id: string;
  name: string;
  label: string;
  sx?: object;
  margin?: 'none' | 'normal' | 'dense';
  fontSize?: string;
  client_id?: number | null;
  disabled?: boolean;
};

export default function ClientAutoComplete({
  clients,
  client_id,
  id,
  name,
  onChange = () => {},
  label,
  margin,
  sx,
  disabled = false,
}: ClientAutoCompleteProps) {
  return h(Box, [
    h(Autocomplete<Client>, {
      sx,
      id,
      value: client_id ? _.find((c) => c.id === client_id, clients) : null,
      fullWidth: true,
      disablePortal: true,
      disabled,
      options: clients,
      getOptionLabel: (c) => `(${c.id}) ${c.first_name} ${c.last_name}`,
      renderOption: (props, c) => {
        return h(
          Stack,
          {
            ...(props as unknown as React.HTMLAttributes<HTMLDivElement>),
            direction: 'column',
            alignItems: 'left',
            sx: { m: 0 },
          },
          [
            h(
              Box,
              {
                sx: { width: '100%', textAlign: 'left', whiteSpace: 'nowrap' },
              },
              `${c.first_name} ${c.last_name}`,
            ),
            h(
              Stack,
              {
                width: '100%',
                direction: 'row',
                justifyContent: 'start',
                spacing: 1,
                color: '#777',
              },
              [
                h(
                  Box,
                  {
                    minWidth: '40px',
                    textAlign: 'left',
                    sx: { whiteSpace: 'nowrap' },
                  },
                  `${c.id}`,
                ),
                h(Box, `${c.email}`),
              ],
            ),
          ],
        );
      },
      filterOptions: (clients, obj) => {
        const parts = obj.inputValue.toLowerCase().split(' ');

        return _.filter((c) => {
          return _.all(
            (p) =>
              c.first_name.toLowerCase().startsWith(p) ||
              c.last_name.toLowerCase().startsWith(p) ||
              c.email.toLowerCase().startsWith(p) ||
              c.id.toString().startsWith(p),
            parts,
          );
        }, clients).splice(0, 100); // showing too many has a noticable delay
      },
      renderInput: (props) => {
        return h(TextField, {
          ...props,
          fullWidth: true,
          label,
          id,
          name,
          margin,
          sx: { autocomplete: 'off' },
          variant: 'outlined',
        });
      },
      onChange: (_e, client) => onChange(client as Client),
    }),
  ]);
}
