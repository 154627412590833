import SalesPanel from './components/SalesPanel';
import SalesListPanel from './components/SalesListPanel';
import SalesConfirmationPanel from './components/SalesConfirmationPanel';
import h from '../../lib/react-hyperscript';

const routing = {
  element: h(SalesPanel),
  children: [
    {
      path: '',
      element: h(SalesListPanel),
    },
    {
      path: ':inventory_id',
      element: h(SalesConfirmationPanel),
    },
  ],
};

export { routing };
