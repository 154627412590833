import { ReportsPage } from '../types';
import { useTheme } from '@mui/material/styles';
import { useAsyncFn } from 'react-use';
import { useEffect, useState } from 'react';
import h from '../../lib/react-hyperscript';
import { Button, Stack, Typography } from '@mui/material';
import FetchBackdrop from './FetchBackdrop';
import MDEditor from '@uiw/react-md-editor';
import LoadingButton from '@mui/lab/LoadingButton';
import { lightFormat } from 'date-fns';

export default function MarkdownEditor({
  title,
  getDataFn,
  saveDataFn,
}: {
  title: string;
  getDataFn: () => Promise<ReportsPage>;
  saveDataFn: (value: string) => Promise<ReportsPage>;
}) {
  const theme = useTheme();

  const [fetchedPage, fetchPage] = useAsyncFn(getDataFn);
  const [savedPage, savePage] = useAsyncFn(saveDataFn);

  const [source, setSource] = useState('');
  const [page, setPage] = useState(null as null | ReportsPage);
  const [is_edit_mode, setIsEditMode] = useState(false);

  const updatePage = (page?: ReportsPage) => {
    if (page !== undefined) {
      setSource(page.source);
      setPage(page);
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  useEffect(() => {
    updatePage(fetchedPage.value);
  }, [fetchedPage.value]);

  useEffect(() => {
    updatePage(savedPage.value);
  }, [savedPage.value]);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchBackdrop, { state: fetchedPage }),

    h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 4 }, title),

    is_edit_mode
      ? h(MDEditor, {
          value: source,
          onChange: (source) => {
            setSource(source ?? '');
          },
          height: 600,
        })
      : h('div', { style: { textAlign: 'left' } }, [
          h(MDEditor.Markdown, {
            source,
            style: { backgroundColor: 'transparent' },
          }),
        ]),

    h(
      Stack,
      {
        width: '100%',
        direction: 'row',
        gap: 2,
        justifyContent: 'flex-end',
        alignItems: 'baseline',
        sx: { mt: 1 },
      },

      is_edit_mode
        ? [
            h(
              LoadingButton,
              {
                variant: 'contained',
                sx: { width: 100 },
                onClick: async () => {
                  await savePage(source);
                },
                loading: savedPage.loading,
                disabled: page?.source === source,
              },
              ['Save'],
            ),
            h(
              Button,
              {
                variant: 'outlined',
                sx: { width: 100 },
                onClick: async () => {
                  await fetchPage();
                  setIsEditMode(false);
                },
              },
              ['Cancel'],
            ),
          ]
        : [
            page &&
              h(
                Typography,
                { color: theme.palette.grey[400] },
                `${lightFormat(new Date(page.created), 'MM-dd-yyyy hh:mm a')}`,
              ),
            h(
              Button,
              {
                variant: 'text',
                sx: { color: theme.palette.grey[400] },
                onClick: () => setIsEditMode(true),
              },
              ['Edit'],
            ),
          ],
    ),
  ]);
}
