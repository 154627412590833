import h from '../../lib/react-hyperscript';
import ClientsPanel from './components/ClientsPanel';
import ClientsList from './components/ClientsList';
import ClientPanel from './components/ClientPanel';

const routing = {
  element: h(ClientsPanel),
  children: [
    {
      path: '',
      element: h(ClientsList),
    },
    {
      path: ':client_id',
      element: h(ClientPanel),
    },
  ],
};

export { routing };
