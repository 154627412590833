import h from '../../lib/react-hyperscript';
import { Popover, Stack, IconButton } from '@mui/material';

import { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { purple } from '@mui/material/colors';

import * as api from '../api';
import ClientCard from './ClientCard';
import { Subject as SubjectIcon } from '@mui/icons-material';
import NoteDetailsDialog from './NoteDetailsDialog';
import { useBaseData } from '../base_data';

export type ClientDrillDownProps = { label: string; client_id: number };

export default function ClientDrillDown({
  label,
  client_id,
}: ClientDrillDownProps) {
  const [state, doFetch] = useAsyncFn(api.getClientSummary);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [is_dialog_open, setDialogOpen] = useState(false);
  const {
    baseData: { isAnyClientNotes, recordClientNoteStatus },
  } = useBaseData();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!state.loading && !state.value)
      doFetch(client_id).catch(() => {
        console.error(state.error);
      });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return h('span', [
    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'center',
        sx: {
          cursor: 'pointer',
          borderRadius: 999,
          backgroundColor: purple[50],
          padding: '0 7px 0 0',
          color: 'secondary.dark',
        },
        onClick: handleClick,
      },
      [
        // h(ExpandCircleDown, {
        //   color: 'secondary',
        //   sx: { verticalAlign: 'baseline', fontSize: '0.8em' },
        // }),

        h(IconButton, {
          sx: { margin: '-3px 0' },
          size: 'small',
          onClick: (e) => {
            e.stopPropagation();
            setDialogOpen(true);
          },
          children: h(SubjectIcon, {
            color: isAnyClientNotes(client_id) ? 'info' : 'disabled',
            sx: { fontSize: 18 },
          }),
        }),
        label,
      ],
    ),
    h(
      Popover,
      {
        open: Boolean(anchorEl),
        anchorEl,
        onClose: handleClose,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      },
      [
        h(ClientCard, {
          client_summary: state.value
            ? { ...state.value, id: client_id }
            : undefined,
          error: state.error,
        }),
      ],
    ),
    is_dialog_open
      ? h(NoteDetailsDialog, {
          cur_header: '',
          entry_desc: '',
          getNoteAbstractionFn: () =>
            api.getNoteAbstract({
              row: { client_id },
              api_key_field: 'client_id',
              ref_source: 'client_info',
              ref_usage: 'general',
            }),
          saveNoteBodyFn: async (new_value) => {
            await api.updateNoteDetail({
              row: { client_id },
              new_value,
              api_key_field: 'client_id',
              ref_source: 'client_info',
              ref_usage: 'general',
            });
            const has_notes = await api.isAnyNotes(client_id, 'client_info');
            recordClientNoteStatus(client_id, has_notes);
          },
          saveNoteHeaderFn: async (new_value) => {
            await api.updateNoteHeader({
              row: { client_id },
              new_value,
              api_key_field: 'client_id',
              ref_source: 'client_info',
              ref_usage: 'general',
            });
            const has_notes = await api.isAnyNotes(client_id, 'client_info');
            recordClientNoteStatus(client_id, has_notes);
          },
          getDataFn: () =>
            api.getNoteDetail({
              row: { client_id },
              api_key_field: 'client_id',
              ref_source: 'client_info',
              ref_usage: 'general',
            }),
          onClose: () => setDialogOpen(false),
        })
      : null,
  ]);
}
