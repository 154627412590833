import h from '../../../lib/react-hyperscript';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useContext, useState } from 'react';
import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../common/store/context';
import { GridColumnHeaderParams } from '@mui/x-data-grid/models/params/gridColumnHeaderParams';
import { Edit as EditIcon } from '@mui/icons-material';
import { SimpleSelect } from '../../../common/components';

const NoteCell = ({
  props,
  onSaveSucceed,
  doSave,
  api_key_field,
}: {
  props: GridRenderCellParams;
  onSaveSucceed: (row: any, field: string, value: any) => void;
  doSave: (row: any, value: any, api_key_field: string) => Promise<void>;
  api_key_field: string;
}) => {
  const { value, field, row } = props; // row data

  const is_multiple_notes = !!value && typeof value === 'object'; // either string or array

  const [note_input, setNote] = useState(
    (is_multiple_notes ? value[0]?.note : value) ?? '',
  );
  const [selected_note_id, setSelectedNoteId] = useState(
    is_multiple_notes ? value[0]?.id : null,
  );

  const { setNotification } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const elem_id = open ? 'simple-popover' : undefined;
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateNote = async () => {
    const new_value = is_multiple_notes
      ? { id: selected_note_id, note: note_input }
      : note_input;
    onSaveSucceed(row, field, new_value);
    setLoading(true);
    handleClose();
    doSave(row, new_value, api_key_field)
      .then(() => {
        setNotification({
          open: true,
          severity: 'success' as AlertColor,
          msg: `Note has been updated successfully`,
        });
        setLoading(false);
      })
      .catch(() => {
        setNotification({
          open: true,
          severity: 'error' as AlertColor,
          msg: `Failed to update note`,
        });
        setLoading(false);
      });
  };

  return h(
    'div',
    {
      style: { width: '100%', height: '100%' },
    },
    [
      h(
        Box,
        {
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            width: '100%',
            height: '100%',
          },
          'aria-describedby': elem_id,
          onClick: handleClick,
        },
        [
          loading ? h(CircularProgress, { size: 18 }) : null,
          h(
            Typography,
            {
              sx: {
                fontSize: 14,
                width: '100%',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
            is_multiple_notes ? value[0]?.note : value,
          ),
        ],
      ),
      h(
        Popover,
        {
          id: elem_id,
          open: open,
          anchorEl: anchorEl,
          onClose: handleClose,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
        [
          h(
            'div',
            {
              style: {
                padding: '10px 5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              },
            },
            [
              is_multiple_notes && value.length > 1
                ? h(SimpleSelect, {
                    size: 'small',
                    options: value.map(
                      (item: { id: number; note: string }) => item.id,
                    ),
                    fullWidth: true,
                    label: `${api_key_field}`,
                    name: 'id',
                    defaultValue: selected_note_id,
                    onChange: (id) => {
                      setSelectedNoteId(id);
                      setNote(
                        value.find(
                          (item: { id: number; note: string }) =>
                            item.id === id,
                        )?.note,
                      );
                    },
                  })
                : null,

              h(TextField, {
                id: 'note',
                label: 'Note',
                name: 'note',
                size: 'small',
                multiline: true,
                maxRows: 3,
                defaultValue: note_input,
                fullWidth: true,
                onChange: (e) => setNote(e.target.value),
              }),

              h(
                Button,
                {
                  key: 'submit',
                  type: 'submit',
                  variant: 'contained',
                  sx: { width: '100%' },
                  onClick: (_) => updateNote(),
                },
                'Save',
              ),
            ],
          ),
        ],
      ),
    ],
  );
};

const StatusHeader = (props: GridColumnHeaderParams) => {
  return h(
    Box,
    {
      sx: {
        display: 'flex',
        justifyContent: 'between',
        alignItems: 'center',
        gap: 1,
      },
    },
    [
      h(Typography, { sx: { fontSize: 14 } }, props.field),
      h(Tooltip, {
        title: 'Double click cell to start editing',
        children: h(EditIcon, { color: 'info', fontSize: 'small' }),
      }),
    ],
  );
};

const DataGridNoteColumn = ({
  field,
  headerName,
  minWidth,
  onSaveSucceed,
  doSave,
  api_key_field,
  isHidden,
}: {
  field: string;
  headerName?: string;
  minWidth?: number;
  onSaveSucceed: (row: any, field: string, value: any) => void;
  doSave: (row: any, value: any, api_key_field: string) => Promise<void>;
  api_key_field: string;
  isHidden?: ({ row }: { row: any }) => boolean;
}): GridColDef => {
  const renderNoteHeader = (params: GridColumnHeaderParams) => {
    return h(StatusHeader, { ...params });
  };

  const renderNoteCell = (params: GridRenderCellParams) => {
    return (isHidden ? !isHidden(params) : true)
      ? h(NoteCell, {
          props: params,
          onSaveSucceed,
          doSave,
          api_key_field,
        })
      : h(Stack, {
          style: {
            cursor: 'not-allowed',
            width: '100%',
            height: '100%',
          },
        });
  };

  return {
    field,
    headerName: headerName ?? field,
    minWidth: minWidth ?? 220,
    renderHeader: renderNoteHeader,
    renderCell: renderNoteCell,
    cellClassName: (params: GridCellParams) => {
      return (isHidden ? !isHidden(params) : true) ? '' : 'is-disabled';
    },
    sortComparator: (a, b) => {
      let x, y;
      if (a && b && typeof a === 'object' && typeof b === 'object') {
        x = a.reduce((e: string, c: { note?: string }) => {
          return e + (c.note || ' ');
        }, '');
        y = b.reduce((e: string, c: { note: string }) => {
          return e + (c.note || ' ');
        }, '');
      } else {
        x = a || '';
        y = b || '';
      }
      return x === y ? 0 : x > y ? 1 : -1;
    },
  };
};

export default DataGridNoteColumn;
