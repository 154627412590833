import h from '../../lib/react-hyperscript';
import { Link } from '@mui/material';

const DataGridEntryLink = (
  row: { id: string | number },
  path: string,
  { color = 'primary.main' } = {},
) => {
  return h(
    Link,
    {
      sx: {
        variant: 'soft',
        color: `${color}`,
      },
      href: path,
    },
    row.id,
  );
};

export default DataGridEntryLink;
