import h from '../../../lib/react-hyperscript';
import FetchBackdrop from '../../../common/components/FetchBackdrop';

import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import * as api from '../../../common/api';
import PlayoffGameDataGrid from './PlayoffGameDataGrid';
import PlayoffGameEditor from './PlayoffGameEditor';
import { getActionColumnDef } from '../../../common/components/DataGridActionColumn';
import { EventStatus, PlayoffGameEx } from '../../../common/types';

export function PlayoffGameListPanel() {
  const [state, setState] = useState<{
    error?: Error;
    loading: boolean;
  }>({ loading: false });
  const [table_data, setTableData] = useState([]);

  const [is_dialog_open, setDialog] = useState(false);
  const [dialog_data, setDialogData] = useState<PlayoffGameEx>();

  const saveNewListableGame = ({
    playoff_round_id,
    home_team_id,
    status,
  }: {
    playoff_round_id: number;
    home_team_id: number;
    status: EventStatus;
  }) => {
    setState({ loading: true });
    api
      .upsertPlayoffGame({ playoff_round_id, home_team_id, status })
      .then(() => {
        setState({ loading: false });
        loadData();
      })
      .catch((err: Error) => {
        setState({ loading: false, error: err });
      });
  };

  const deleteListableGameById = async ({
    playoff_round_id,
    home_team_id,
  }: {
    playoff_round_id: number;
    home_team_id: number;
  }) => {
    setState({ loading: true });
    try {
      await api.deletePlayoffGame({ playoff_round_id, home_team_id });
      setState({ loading: false });
    } catch (err) {
      setState({ loading: false, error: err as Error });
      return;
    }
    loadData();
  };

  const loadData = () => {
    setState({ loading: true });
    api
      .getPlayoffGames()
      .then((resp) => {
        setState({ loading: false });
        setTableData(resp.playoff_games ?? []);
      })
      .catch((err: Error) => {
        setState({ loading: false, error: err });
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!is_dialog_open) setDialogData(undefined);
  }, [is_dialog_open]);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(
      Typography,
      { variant: 'h5', fontWeight: 'bold', mb: 4 },
      'Playoff Rounds',
    ),

    h(FetchBackdrop, { state }),

    h(
      Stack,
      { direction: 'row', justifyContent: 'space-between', sx: { mb: 2 } },
      [
        h(Box),
        h(
          Button,
          {
            variant: 'contained',
            onClick: () => setDialog(true),
            sx: {
              height: '50px',
              width: '100px',
              whiteSpace: 'nowrap',
              flexShrink: 0,
            },
          },
          'Add',
        ),
      ],
    ),

    h(PlayoffGameDataGrid, {
      rows: table_data,
      actionColumns: [
        getActionColumnDef({
          field: 'DELETE',
          headerName: 'Delete',
          action: 'delete',
          cellOnClick: deleteListableGameById,
        }),
      ],
    }),

    h(Dialog, { open: is_dialog_open }, [
      h(PlayoffGameEditor, {
        lpg: dialog_data,
        onCancel: () => setDialog(false),
        onSave: saveNewListableGame,
      }),
    ]),
  ]);
}
