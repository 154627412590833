import h from '../../lib/react-hyperscript';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useBaseData } from '../base_data';
import { useAsyncFn } from 'react-use';
import { useEffect, useState } from 'react';
import {
  Subject as SubjectIcon,
  Wysiwyg as HeaderIcon,
} from '@mui/icons-material';
import LoadingBlock from './LoadingBlock';
import { format } from './QueryDataGrid';
import LoadingButton from '@mui/lab/LoadingButton';

const NoteEditor = ({
  value,
  setValue,
}: {
  value: string | null;
  setValue: (value: string) => void;
}) => {
  return h(Stack, { sx: { paddingTop: 1 } }, [
    h(TextField, {
      id: 'note',
      label: '',
      name: 'note',
      size: 'small',
      multiline: true,
      rows: 17,
      maxRows: 17,
      value,
      fullWidth: true,
      onChange: (e) => setValue(e.target.value),
    }),
  ]);
};

const NoteDetailsDialog = ({
  cur_header,
  entry_desc,
  getDataFn,
  getNoteAbstractionFn,
  saveNoteBodyFn,
  saveNoteHeaderFn,
  onClose,
}: {
  cur_header: string;
  entry_desc: string;
  getDataFn: () => Promise<any>;
  getNoteAbstractionFn?: () => Promise<any>;
  saveNoteBodyFn: (new_value: any) => Promise<void>;
  saveNoteHeaderFn: (new_value: any) => Promise<void>;
  onClose: () => void;
}) => {
  const {
    baseData: { getOccUserById },
  } = useBaseData();
  const [fetchedPage, fetchPage] = useAsyncFn(getDataFn);
  const [updatedHeader, updateHeader] = useAsyncFn(async (header: string) => {
    await saveNoteHeaderFn(header);
  });
  const [updatedNote, updateNote] = useAsyncFn(async (note: string) => {
    await saveNoteBodyFn(note);
    setEditingMode(false);
  });
  const [abstraction, fetchAbstraction] = useAsyncFn(
    getNoteAbstractionFn || (async () => {}),
  );

  // Note header
  const [header, setHeader] = useState(cur_header);
  const [local_cur_header, setLocalCurHeader] = useState(cur_header);
  const [short_description, setShortDescription] = useState(entry_desc);
  const [is_editing_header, setEditingHeader] = useState(false);
  // Note details
  const [is_editing_mode, setEditingMode] = useState(false);
  const [latest_note, setLatestNote] = useState('');

  const handleSaveNote = async (note: any) => {
    await updateNote(note);
    await fetchPage();
  };

  const handleEditHeader = () => {
    setEditingHeader(true);
    setTimeout(() => {
      document.getElementById('note_header')?.focus();
    }, 100);
  };

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  useEffect(() => {
    if (!fetchedPage.loading && fetchedPage.value) {
      setLatestNote(fetchedPage.value?.raw_body ?? '');
      if (!cur_header) {
        handleEditHeader();
      }
    }
  }, [fetchedPage.loading]);

  useEffect(() => {
    fetchAbstraction();
  }, [fetchAbstraction]);

  useEffect(() => {
    if (!abstraction.loading && abstraction.value) {
      setHeader(abstraction.value?.header);
      setLocalCurHeader(abstraction.value?.header);
      setShortDescription(abstraction.value?.description);
    }
  }, [abstraction.loading]);

  return h(
    Dialog,
    {
      open: true,
      onClose,
      fullWidth: true,
      maxWidth: 'md',
      scroll: 'paper',
      sx: { top: '64px' },
    },
    [
      h(
        DialogTitle,
        {
          sx: {
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'start',
            gap: 2,
          },
        },
        [
          h(HeaderIcon, {
            color: 'action',
            fontSize: 'medium',
            sx: { paddingTop: '5px' },
          }),
          abstraction.loading
            ? h(LoadingBlock, { height: '100%', spinSize: 20 })
            : h(Stack, { direction: 'column', sx: { width: '100%' } }, [
                !is_editing_header
                  ? h(
                      'div',
                      {
                        id: 'note_header_display',
                        style: { minHeight: '32px' },
                        onClick: () => {
                          if (!updatedHeader.loading) handleEditHeader();
                        },
                      },
                      [
                        header
                          ? h(
                              Typography,
                              {
                                sx: {
                                  fontSize: 20,
                                  fontWeight: 'bold',
                                  background: updatedHeader.loading
                                    ? '#eeeeee'
                                    : 'none',
                                },
                              },
                              `${header}`,
                            )
                          : h(
                              Typography,
                              { sx: { fontSize: 20, color: 'text.secondary' } },
                              `Click to enter a header`,
                            ),
                      ],
                    )
                  : h(TextField, {
                      id: 'note_header',
                      label: 'Header',
                      placeholder: 'Enter header here',
                      name: 'header',
                      size: 'small',
                      multiline: false,
                      value: header,
                      fullWidth: true,
                      onChange: (e) => setHeader(e.target.value),
                      onBlur: () => {
                        setEditingHeader(false);
                        if (header !== local_cur_header) updateHeader(header);
                      },
                    }),
                h(
                  Typography,
                  { sx: { fontSize: 14, color: 'text.secondary' } },
                  short_description,
                ),
              ]),
        ],
      ),
      h(
        DialogTitle,
        {
          sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 1,
          },
        },
        [
          h(
            Stack,
            {
              direction: 'row',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
              sx: { fontSize: 18, fontWeight: 'bold' },
            },
            [h(SubjectIcon, { color: 'action', fontSize: 'medium' }), 'Notes'],
          ),
          h(
            Stack,
            { direction: 'row', gap: 4, alignItems: 'center' },
            !is_editing_mode && fetchedPage.value?.history_body.length
              ? [
                  h(
                    Button,
                    {
                      key: 'edit',
                      variant: 'contained',
                      color: 'primary',
                      size: 'small',
                      onClick: () => setEditingMode(true),
                    },
                    'Edit',
                  ),
                ]
              : [],
          ),
        ],
      ),

      h(
        DialogContent,
        { sx: { paddingLeft: 8, paddingRight: 3 } },
        !is_editing_mode
          ? [
              h(TableContainer, { sx: { minHeight: '400px' } }, [
                fetchedPage.loading
                  ? h(LoadingBlock, { height: '400px' })
                  : fetchedPage.value?.history_body.length &&
                    !!fetchedPage.value?.raw_body
                  ? h(
                      Table,
                      {
                        sx: {
                          size: 'small',
                          ariaLabel: 'Notes',
                        },
                      },
                      [
                        h(TableHead, [
                          h(TableRow, [
                            h(
                              TableCell,
                              {
                                sx: {
                                  pb: '5px',
                                  pt: '5px',
                                  fontWeight: 'bold',
                                },
                              },
                              '',
                            ),
                            h(
                              TableCell,
                              {
                                sx: {
                                  pb: '5px',
                                  pt: '5px',
                                  fontWeight: 'bold',
                                },
                              },
                              '',
                            ),
                          ]),
                        ]),
                        h(
                          TableBody,
                          fetchedPage.value?.history_body.map(
                            (item: any, index: number) => {
                              return h(
                                TableRow,
                                {
                                  key: index,
                                  sx: {
                                    display: 'grid',
                                    gridTemplateColumns: '270px 1fr',
                                  },
                                },
                                [
                                  h(
                                    TableCell,
                                    {
                                      sx: {
                                        pb: '0',
                                        pt: '0',
                                        px: 0.5,
                                        border: 'none',
                                        background: 'rgb(250,250,250)',
                                      },
                                    },
                                    [
                                      h(
                                        'div',
                                        {
                                          style: {
                                            display: 'flex',
                                            gap: '15px',
                                            alignItems: 'start',
                                          },
                                        },
                                        [
                                          h(
                                            Typography,
                                            {
                                              sx: {
                                                fontSize: 14,
                                                color: 'text.secondary',
                                                maxWidth: '150px',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                              },
                                            },
                                            [
                                              format.datetime({
                                                value: item.created,
                                              }),
                                            ],
                                          ),

                                          item.author_id !==
                                          fetchedPage.value.history_body[
                                            index - 1
                                          ]?.author_id
                                            ? h(
                                                Typography,
                                                {
                                                  sx: {
                                                    fontSize: 14,
                                                    color: getOccUserById(
                                                      item.author_id,
                                                    ).color,
                                                    maxWidth: '110px',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                  },
                                                },
                                                [
                                                  getOccUserById(item.author_id)
                                                    .name,
                                                ],
                                              )
                                            : '',
                                        ],
                                      ),
                                    ],
                                  ),
                                  h(
                                    TableCell,
                                    {
                                      sx: {
                                        pb: '0',
                                        pt: '0',
                                        border: 'none',
                                      },
                                    },
                                    [
                                      h(InputBase, {
                                        id: `nb-${index}`,
                                        disabled: true,
                                        size: 'small',
                                        multiline: true,
                                        value: item.content,
                                        fullWidth: true,
                                        disableInjectingGlobalStyles: true,
                                        sx: {
                                          py: 0,
                                          color: '#000000!important',
                                          '.Mui-disabled': {
                                            '-webkit-text-fill-color':
                                              'unset!important',
                                          },
                                        },
                                      }),
                                    ],
                                  ),
                                ],
                              );
                            },
                          ) ?? [],
                        ),
                      ],
                    )
                  : h(
                      Button,
                      {
                        sx: {
                          width: '100%',
                          height: '60px',
                          justifyContent: 'start',
                          alignItems: 'start',
                          background: '#e4e6ea',
                          padding: '8px 12px',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#d0d4db',
                            boxShadow: 'none',
                          },
                        },
                        size: 'small',
                        onClick: () => setEditingMode(true),
                      },
                      [
                        h(
                          Typography,
                          {
                            fontWeight: 550,
                            fontSize: 14,
                            color: 'rgba(0, 0, 0, 0.87)',
                          },
                          'Add more detailed notes...',
                        ),
                      ],
                    ),
              ]),
            ]
          : [
              h(NoteEditor, { value: latest_note, setValue: setLatestNote }),

              h(Stack, { direction: 'row', pt: 1, gap: 1 }, [
                h(
                  LoadingButton,
                  {
                    key: 'save',
                    variant: 'contained',
                    color: 'primary',
                    size: 'small',
                    loading: updatedNote.loading,
                    disabled:
                      latest_note === (fetchedPage.value?.raw_body || ''),
                    onClick: () => handleSaveNote(latest_note),
                  },
                  'Save',
                ),

                h(
                  Button,
                  {
                    key: 'cancel',
                    variant: 'outlined',
                    color: 'primary',
                    size: 'small',
                    onClick: () => setEditingMode(false),
                  },
                  'Cancel',
                ),
              ]),
            ],
      ),
      h(
        DialogActions,
        { sx: { padding: '16px' } },
        !is_editing_mode && fetchedPage.value?.id
          ? [
              h(Typography, {}, 'Last edited by '),
              h(
                Typography,
                { fontWeight: 'bold' },
                ` ${getOccUserById(fetchedPage.value?.author_id).name}`,
              ),
              ',',
              h(
                Typography,
                {},
                `at ${format.datetime({
                  value: fetchedPage.value?.created,
                })}`,
              ),
            ]
          : [],
      ),
    ],
  );
};

export default NoteDetailsDialog;
