import h from '../../../lib/react-hyperscript';
import { Stack, Box, useTheme, Fab } from '@mui/material';
import { Navigation as NavigationIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { FetchBackdrop } from '../../../common/components';
import { useAsyncFn } from 'react-use';
import { useEffect, useLayoutEffect, useState } from 'react';
import * as api from '../../../common/api';
import { useParams } from 'react-router-dom';
import ApplicationMenu from '../../../common/components/ApplicationMenu';
import SalesComparableSections from './SalesComparableSections';
import SalesClientHistory from './SalesClientHistory';
import SalesEmailForm from './SalesEmailForm';
import SalesEmailHistory from './SalesEmailHistory';
import SalesHomeSchedule from './SalesHomeSchedule';
import SalesOverview from './SalesOverview';
import SalesAttributes from './SalesAttributes';
import { AttributeItem } from '../../../common/types';
import { SalesAttributesHistory } from './SalesAttributesHistory';
import * as _ from 'ramda';

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const fetchFunc = async (opts: {
  id: number;
  comp_sections?: string;
  refresh?: boolean;
  updateFn?: () => Promise<void>;
}) => {
  if (opts.updateFn) {
    await opts.updateFn();
  }

  return await api.getSalesReports(opts.id, opts.comp_sections, opts.refresh);
};

export default function SalesConfirmationPanel() {
  const { inventory_id } = useParams<{ inventory_id: string }>();
  const id = parseInt(inventory_id as string, 10);

  const [state, doFetch] = useAsyncFn(fetchFunc);

  useEffect(() => {
    doFetch({ id });
  }, [id, doFetch]);

  const analysis = state.value;
  const sale_email = analysis?.sale_confirmation;
  const inv_item = analysis?.inv_item;
  const comp_list = analysis?.comps ?? [];
  const sale_statistics = analysis?.sale_statistics;
  const total_listings = analysis?.total_listings;
  const tm_event_link = analysis?.tm_event_link;
  const pricing_factors = analysis?.pricing_factors ?? [];

  const grouped_pricing_factors = _.groupBy(_.prop('name'), pricing_factors);
  const getPricingFactorById = _.indexBy(_.prop('id'), pricing_factors);

  // Seat-chart availability priority: (1. skybox event) > (2. tm_event) > (3. our_db_value)
  const [seat_chart_resource, setSeatChartResource] = useState(
    tm_event_link
      ? `https://mapsapi.tmol.io/maps/geometry/3/event/${analysis.tm_event_link}/staticImage`
      : '',
  );
  useEffect(() => {
    if (!seat_chart_resource)
      setSeatChartResource(
        tm_event_link
          ? `https://mapsapi.tmol.io/maps/geometry/3/event/${tm_event_link}/staticImage`
          : '',
      );
  }, [tm_event_link]);

  // sale comparable price
  const [comp_price, setCompPrice] = useState('');
  const [selection_model, setSelectionModel] = useState<GridRowSelectionModel>(
    [],
  );
  const updateSelectionModel = (price_str: string) => {
    // find the first item on the table that match the price
    if (!price_str) return setSelectionModel([]);
    const first_match = comp_list.find(
      (item) => item.price === parseFloat(price_str),
    );
    return setSelectionModel(!!first_match ? [first_match.id] : []);
  };
  const getCurrentCompSale = () => {
    const [selected_item_id] = selection_model;
    const comp_sale = comp_list.find((item) => item.id === selected_item_id);
    return {
      id: comp_sale ? comp_sale.id : '',
      section: comp_sale ? comp_sale.section : '',
      row: comp_sale ? comp_sale.row : '',
      seats: comp_sale ? `${comp_sale.first_seat}-${comp_sale.last_seat}` : '',
      price: comp_sale ? comp_sale.price : parseFloat(comp_price),
      market_place: comp_sale
        ? comp_sale.market_place
        : 'Unknown(manually entered)',
    };
  };

  // Sales attributes
  const [attributes_list, setAttributesList] = useState<AttributeItem[]>([]);
  const getAttributes = () => {
    let attributes_payload: {
      pros: { id: number; value: string }[];
      cons: { id: number; value: string }[];
    } = {
      pros: [],
      cons: [],
    };

    for (const attr of attributes_list) {
      if (!attr.selected || !attr.value) continue;
      const { id, type } = attr.options.find(
        (item) => item.id === attr.value,
      ) as { id: number; type: 'pros' | 'cons' };

      attributes_payload[type].push({
        id,
        value: attr.input.value ?? '',
      });
    }

    return attributes_payload;
  };
  const getAttributeText = (id: number, input?: string) => {
    const { description } = pricing_factors.find((item) => item.id === id) ?? {
      description: '',
    };
    return input ? description.replace('{{input}}', input) : description;
  };
  const getInvalidAttributeSelections = () => {
    let res = [];
    for (const attr of attributes_list) {
      if (!attr.selected) continue;
      if (!attr.value) res.push(attr.title);
      if (attr.input.type && !attr.input.value) res.push(attr.title);
    }
    return res;
  };

  // Responsive drawer
  const theme = useTheme();
  const [window_width] = useWindowSize();
  const isCollapsable = () => window_width <= theme.breakpoints.values.md;
  const [is_drawer_open, setDrawerState] = useState(true);
  useEffect(() => {
    setDrawerState(true);
  }, [isCollapsable()]);

  return h(
    Stack,
    {
      alignItems: 'center',
      height: '100%',
    },
    [
      h(FetchBackdrop, { state }),

      !(analysis && inv_item)
        ? null
        : h(Box, { width: '100%' }, [
            h(
              Stack,
              {
                alignItems: 'center',
                sx: { marginRight: isCollapsable() ? '' : '350px' },
              },
              [
                h(Stack, { gap: 2, sx: { width: '100%', marginTop: 2 } }, [
                  h(SalesHomeSchedule, { inv_item, setSeatChartResource }),
                ]),

                h(
                  Grid,
                  {
                    width: '100%',
                    container: true,
                    marginTop: 2,
                  },
                  [
                    h(Grid, { sm: 12, md: 12, xl: 5, padding: 1 }, [
                      h(SalesOverview, {
                        inv_item,
                        sale_statistics,
                        tm_event_link: analysis.tm_event_link,
                      }),
                    ]),
                    h(Grid, { sm: 12, md: 12, xl: 7, padding: 1 }, [
                      !!sale_email
                        ? h(SalesAttributesHistory, {
                            selected_pricing_factors:
                              sale_email.pricing_factors?.filter(
                                (pf) => !!getPricingFactorById[pf.id],
                              ) ?? [],
                            getAttributeText,
                            isAttributePositive: (id) =>
                              getPricingFactorById[id].is_positive,
                          })
                        : h(SalesAttributes, {
                            inv_item,
                            total_listings,
                            attributes_list,
                            grouped_pricing_factors,
                            getPricingFactorById,
                            setAttributesList,
                          }),
                    ]),
                  ],
                ),

                h(
                  Stack,
                  {
                    sx: {
                      gap: 2,
                      width: '100%',
                      marginTop: 2,
                      marginBottom: 6,
                    },
                  },
                  [
                    h(SalesComparableSections, {
                      inv_item,
                      comps: analysis.comps,
                      total_listings,
                      seat_chart: seat_chart_resource || analysis.seat_chart,
                      doFetch,
                      selection_model,
                      setSelectionModel,
                      setCompPrice,
                    }),

                    h(SalesClientHistory, {
                      client_sales: analysis.client_sales,
                    }),
                  ],
                ),
              ],
            ),

            h(
              ApplicationMenu,
              {
                open: is_drawer_open,
                isCollapsable: isCollapsable(),
                onClose: () => {
                  setDrawerState(false);
                },
              },
              [
                !!sale_email
                  ? h(SalesEmailHistory, { sale_email })
                  : h(SalesEmailForm, {
                      inv_item,
                      inv_price_detail: analysis?.inv_price_detail,
                      doFetch,
                      comp_price,
                      setCompPrice,
                      updateSelectionModel,
                      getCurrentCompSale,
                      getAttributes,
                      getAttributeText,
                      getInvalidAttributeSelections,
                    }),
              ],
            ),

            h(
              Fab,
              {
                variant: 'extended',
                color: 'primary',
                sx: { zIndex: 100, position: 'fixed', bottom: 20, right: 20 },
                onClick: () => setDrawerState(true),
              },
              [h(NavigationIcon), 'Actions'],
            ),
          ]),
    ],
  );
}
