import h from '../../../lib/react-hyperscript';
import CachedDataPanel from '../../../common/components/CachedDataPanel';
import InventoryPricingDataGrid from './InventoryPricingDataGrid';

export default function InventoryPricingPanel() {
  return h(CachedDataPanel, {
    header: 'Inventory Pricing',
    cache_key: 'inventory_pricing',
    componentFn: ({ rows }) =>
      h(InventoryPricingDataGrid, {
        rows,
      }),
    paginationLoading: true,
  });
}
