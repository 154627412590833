import h from '../../../lib/react-hyperscript';
import { useAsyncFn } from 'react-use';
import * as api from '../../../common/api';
import { useEffect } from 'react';
import { Link, Stack, Typography } from '@mui/material';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import SeasonDataGrid from './SeasonDataGrid';
import { FetchPageHeader } from '../../../common/components/FetchPageHeader';

export default function SeasonList() {
  const [state, doFetch] = useAsyncFn(api.getSeasons);
  useEffect(() => {
    doFetch();
  }, []);
  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchPageHeader, { header: 'Season Calendars', state, doFetch }),

    h(
      Link,
      {
        underline: 'hover',
        color: 'inherit',
        href: 'https://docs.google.com/presentation/d/1OfsQ_6dPjEldLje3Iz92Q_TK7M_cH0qn14GPd66davs/edit#slide=id.g23aa2191765_0_0',
        key: 'https://docs.google.com/presentation/d/1OfsQ_6dPjEldLje3Iz92Q_TK7M_cH0qn14GPd66davs/edit#slide=id.g23aa2191765_0_0',
      },
      'TQ Season Calendar',
    ),

    h(FetchBackdrop, { state }),

    h(SeasonDataGrid, {
      rows: state.value ?? [],
    }),
  ]);
}
