import h from '../../lib/react-hyperscript';
import { LeagueId } from '../types';

import {
  SportsFootball,
  SportsBasketball,
  SportsBaseball,
  SportsSoccer,
  SportsHockey,
} from '@mui/icons-material';

export default function LeagueIcon({
  league,
  fontSize,
}: {
  league: LeagueId;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium';
}) {
  return {
    NFL: h(SportsFootball, { fontSize: fontSize ? fontSize : undefined }),
    NBA: h(SportsBasketball, { fontSize: fontSize ? fontSize : undefined }),
    NHL: h(SportsHockey, { fontSize: fontSize ? fontSize : undefined }),
    MLB: h(SportsBaseball, { fontSize: fontSize ? fontSize : undefined }),
    MLS: h(SportsSoccer, { fontSize: fontSize ? fontSize : undefined }),
    NCAAB: h(SportsBasketball, { fontSize: fontSize ? fontSize : undefined }),
    NCAAF: h(SportsFootball, { fontSize: fontSize ? fontSize : undefined }),
  }[league];
}
