import h from '../../../lib/react-hyperscript';
import debounce from '../../../common/util/debounce';
import {
  AlertColor,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ClientAutoComplete, SimpleSelect } from '../../../common/components';
import { toCommissionStr } from '../../../common/helper';
import {
  Client,
  ClientEvent,
  LeagueId,
  SingleEventInventory,
} from '../../../common/types';

import { default as React, useContext, useEffect, useState } from 'react';
import { useCommissions } from '../../../common/hooks/useCommissions';
import { useNavigate } from 'react-router-dom';
import {
  Stadium as StadiumIcon,
  Today as TodayIcon,
} from '@mui/icons-material';
import { AppContext } from '../../../common/store/context';
import * as api from '../../../common/api';

export default function InventoryEditor({
  init_data,
  event,
  onSubmit,
  onBack,
  clients = [],
  display,
}: {
  init_data: {
    client_id: number | null;
    section: string | null;
    row: string | null;
    first_seat: string | null;
    last_seat: string | null;
    req_id: string | null;
  };
  event: ClientEvent | null;
  onSubmit: (client_id: number, single_event_inv: SingleEventInventory) => void;
  onBack?: () => void;
  clients?: Client[];
  display?: string;
}) {
  const navigate = useNavigate();
  const { setNotification } = useContext(AppContext);

  const [client_id, setClientId] = useState<null | number>(init_data.client_id);
  const [commission_tier, setCommissionTier] = useState('');
  const [commission, setCommission] = useState('');
  const [most_recent_tier, setMostRecentTier] = useState<{
    league: LeagueId;
    id: number;
    team_abbrev: string;
    section: string;
    row: string;
    first_seat: string;
    last_seat: string;
    commission: string;
  } | null>(null);
  const [selected_stock_type, setSelectedStockType] =
    useState('MOBILE_TRANSFER');

  const { commissions, doFetchCommissions } = useCommissions();

  const debouncedGetCommissionByTier = debounce(async () => {
    if (client_id && commission_tier) {
      const resp = await api.getCommissionDetailsByTier(
        client_id,
        commission_tier,
      );
      setCommission(toCommissionStr(resp.commission));
      if (resp.tp_source)
        setMostRecentTier({ ...resp.tp_source, commission: resp.commission });
      else setMostRecentTier(null);
    }
  }, 500);

  const formValidation = (form: SingleEventInventory) => {
    if (!form.section) return 'section is required';
    if (!form.row) return 'row is required';
    if (!form.first_seat || !form.last_seat) return 'seats is incomplete.';
    if (!form.commission) return 'commission is required.';
    return '';
  };
  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (event === null) return;
    const form: SingleEventInventory = {
      event_id: event.id,
      section: data.get('section') as string,
      row: data.get('row') as string,
      first_seat: data.get('first_seat') as string,
      last_seat: data.get('last_seat') as string,
      stock_type: selected_stock_type,
      team_id: event.performer?.id,
      req_id: init_data.req_id ? parseInt(init_data.req_id) : undefined,
      commission: commission ? String(parseFloat(commission) / 100) : '',
      third_party_cost: '0.0990',
    };

    const form_validation = formValidation(form);
    if (form_validation) {
      setNotification({
        open: true,
        severity: 'error' as AlertColor,
        msg: `${form_validation}`,
      });
      return;
    }

    onSubmit(client_id as number, form);
  };

  const isStandardCommission = (
    commission: string | null,
    commission_tier: string | null,
  ) => {
    if (!commission || !commission_tier) return false;
    return !!commissions.find(
      (item) =>
        item.commission_tier === commission_tier &&
        +`${item.tiq_commission}` === +commission &&
        item.is_active,
    );
  };

  useEffect(() => {
    if (client_id && commission_tier) {
      debouncedGetCommissionByTier();
    } else {
      setMostRecentTier(null);
    }
  }, [client_id, commission_tier]);

  useEffect(() => {
    doFetchCommissions();
  }, [doFetchCommissions]);

  return h(
    Box,
    {
      key: 'inventory-editor',
      component: 'form',
      onSubmit: handleSave,
      noValidate: true,
      sx: { display },
    } as any, // How do you type a Box element as a form?
    [
      h(Stack, { mt: 2, mb: 4, spacing: 0.5 }, [
        h(Box, event?.name),
        h(
          Stack,
          {
            direction: 'row',
            sx: {
              alignItems: 'center',
              width: 'fit-content',
            },
          },
          [
            h(TodayIcon, {
              sx: {
                color: 'gray',
                width: '18px',
                height: '18px',
                mr: 1,
              },
            }),
            h(
              Box,
              `${new Date(event?.date + 'Z').toLocaleDateString('en-US', {
                weekday: 'short',
                month: '2-digit',
                year: 'numeric',
                day: '2-digit',
                timeZone: 'UTC',
              })}`,
            ),
          ],
        ),
        h(
          Stack,
          {
            direction: 'row',
            sx: {
              alignItems: 'center',
              width: 'fit-content',
            },
          },
          [
            h(StadiumIcon, {
              sx: {
                color: 'gray',
                width: '18px',
                height: '18px',
                mr: 1,
              },
            }),
            h(
              Box,
              `${event?.venue?.name}, ${event?.venue?.city}, ${event?.venue?.state}`,
            ),
          ],
        ),
      ]),
      h(ClientAutoComplete, {
        clients,
        client_id,
        id: 'client_id',
        name: 'client_id',
        label: 'Client',
        onChange: (client) => setClientId(client?.id ?? null),
        sx: { mb: 3 },
      }),

      [
        h(Box, { pb: 1 }, [
          h(
            Stack,
            {
              direction: 'row',
              alignItems: 'center',
              gap: 3,
            },
            [
              h(FormControl, { fullWidth: true }, [
                h(
                  InputLabel,
                  { sx: { background: '#fff' } },
                  'Commission tier',
                ),
                h(
                  Select,
                  {
                    value: commission_tier,
                    sx: { textAlign: 'left' },
                    onChange: (e) => {
                      setCommissionTier(e.target.value as string);
                    },
                  },
                  commissions
                    .filter((item) => item.is_active)
                    .map((c) =>
                      h(
                        MenuItem,
                        {
                          key: c.commission_tier,
                          value: c.commission_tier,
                        },
                        c.commission_tier,
                      ),
                    ),
                ),
              ]),
              h(TextField, {
                type: 'number',
                size: 'medium',
                id: 'tiq_commission',
                label: 'Commission',
                name: 'tiq_commission',
                required: true,
                value: commission,
                onChange: (e) => {
                  setCommission(e.target.value);
                },
                sx: {
                  width: '350px',
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                    fontWeight: isStandardCommission(
                      (parseFloat(commission || '0') / 100).toFixed(4),
                      commission_tier,
                    )
                      ? 400
                      : 'bold',
                  },
                },
                InputProps: {
                  endAdornment: h(InputAdornment, { position: 'end' }, '%'),
                },
              }),
            ],
          ),
          h(
            Stack,
            {
              sx: { width: '100%' },
            },
            most_recent_tier
              ? [
                  h(
                    Stack,
                    {
                      direction: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                    },
                    [
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `Most recent tier ${commission_tier}: `,
                      ),
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `${toCommissionStr(most_recent_tier.commission)}% ${
                          isStandardCommission(
                            most_recent_tier.commission,
                            commission_tier,
                          )
                            ? ''
                            : '(non-standard)'
                        }`,
                      ),
                    ],
                  ),
                  h(
                    Stack,
                    {
                      direction: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                    },
                    [
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `(${most_recent_tier.league}`,
                      ),
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `${most_recent_tier.team_abbrev}`,
                      ),
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `S${most_recent_tier.section}`,
                      ),
                      h(
                        Typography,
                        { fontSize: '13px', fontWeight: 'bold' },
                        '|',
                      ),
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `R${most_recent_tier.row}`,
                      ),
                      h(
                        Typography,
                        { fontSize: '13px', fontWeight: 'bold' },
                        '|',
                      ),
                      h(
                        Typography,
                        { fontSize: '13px' },
                        `S${most_recent_tier.first_seat}-${most_recent_tier.last_seat})`,
                      ),
                    ],
                  ),
                ]
              : [
                  h(
                    Typography,
                    { fontSize: '13px' },
                    commission_tier
                      ? `Most recent tier ${commission_tier}: None`
                      : '',
                  ),
                ],
          ),
          h(
            Stack,
            {
              direction: 'row',
              alignItems: 'baseline',
              justifyContent: 'center',
              gap: 1,
              sx: { width: '100%' },
            },
            [
              h(
                Typography,
                {
                  fontSize: '13px',
                  sx: {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  },
                  onClick: () => {
                    window.open(
                      'https://docs.google.com/spreadsheets/d/1uf2IlkOZwkk0c0C_SF3q4d8wqHqI2ffKhEUYd2gn7zw/edit?gid=961367321#gid=961367321',
                      '_blank',
                    );
                  },
                },
                'Rate table.',
              ),
            ],
          ),
        ]),
      ],

      h(SimpleSelect, {
        optionValues: [
          { label: 'Mobile Transfer', value: 'MOBILE_TRANSFER' },
          { label: 'Flash', value: 'FLASH' },
        ],
        value: selected_stock_type,
        sx: { mt: 3, mb: 1 },
        fullWidth: true,
        label: 'Ticket stock type',
        name: 'stock_type',
        onChange: (value) => {
          setSelectedStockType(`${value}`);
        },
      }),

      h(TextField, {
        margin: 'normal',
        required: true,
        fullWidth: true,
        id: 'section',
        label: 'Section',
        name: 'section',
        defaultValue: init_data.section,
      }),

      h(TextField, {
        margin: 'normal',
        required: true,
        fullWidth: true,
        id: 'row',
        label: 'Row',
        name: 'row',
        defaultValue: init_data.row,
      }),

      h(
        Stack,
        {
          direction: 'row',
          spacing: 2,
          alignItems: 'center',
          mt: 2,
        },
        [
          h(TextField, {
            required: true,
            id: 'first_seat',
            label: 'First seat',
            name: 'first_seat',
            defaultValue: init_data.first_seat,
            inputProps: {
              type: 'number',
              pattern: '[0-9]*',
            },
          }),
          h(TextField, {
            required: true,
            id: 'last_seat',
            label: 'Last Seat',
            name: 'last_seat',
            defaultValue: init_data.last_seat,
            inputProps: {
              type: 'number',
              pattern: '[0-9]*',
            },
          }),
          h(FormControlLabel, {
            disabled: true,
            label: 'Aisle Seats',
            sx: { whiteSpace: 'nowrap' },
            control: h(Checkbox, {
              id: 'is_aisle_seats',
              name: 'is_aisle_seats',
            }),
          }),
        ],
      ),

      h(
        Stack,
        {
          direction: 'row',
          alignItems: 'baseline',
          justifyContent: 'center',
          spacing: 2,
          sx: { width: '100%', mt: 3 },
        },

        [
          h(
            Button,
            {
              key: 'cancel',
              variant: 'outlined',
              sx: { width: '100%' },
              onClick: () => {
                if (onBack) onBack();
                else navigate(`/single-event`);
                return false;
              },
            },
            onBack ? 'Back' : 'Cancel',
          ),
          h(
            Button,
            {
              key: 'submit',
              type: 'submit',
              variant: 'contained',
              sx: { width: '100%' },
            },
            'Create',
          ),
        ],
      ),
    ],
  );
}
