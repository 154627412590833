import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';
import h from './lib/react-hyperscript';
import { router } from './routing';
import React from 'react';
import { orange } from '@mui/material/colors';
import { Auth0Provider } from '@auth0/auth0-react';

import {
  RouterProvider,
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LinkProps } from '@mui/material/Link';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return href.toString().startsWith('http')
    ? h('a', { ...other, ref, href: href.toString() })
    : h(RouterLink, {
        ref,
        to: href,
        ...other,
      });
});

// function Router(props: { children?: React.ReactNode }) {
//   const { children } = props;
//   if (typeof window === 'undefined') {
//     return h(StaticRouter, { location: '/' }, [children]);
//   }

//   return h(MemoryRouter, [children]);
// }

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    warning: {
      light: orange[100],
      main: orange['A700'],
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// Handle Auth0 invitation
const getAuth0Invitation = () => {
  const path = window.location.pathname;
  const search = window.location.search;
  const search_params = new URLSearchParams(
    search.substring(search.indexOf('?')),
  );
  if (path === '/login') return search_params.get('invitation') ?? undefined;
};

root.render(
  h(
    Auth0Provider,
    {
      domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        organization: process.env.REACT_APP_AUTH0_ORG_ID,
        invitation: getAuth0Invitation(),
      },
      cacheLocation: 'localstorage',
    },
    [h(ThemeProvider, { theme }, [h(RouterProvider, { router })])],
  ),
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
