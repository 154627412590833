import h from '../../../lib/react-hyperscript';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useContext, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import { SimpleSelect } from '../../../common/components';
import {
  AlertColor,
  Box,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import * as api from '../../../common/api';
import { EventStatus } from '../../../common/types';
import { AppContext } from '../../../common/store/context';
import { GridColumnHeaderParams } from '@mui/x-data-grid/models/params/gridColumnHeaderParams';
import { Edit as EditIcon } from '@mui/icons-material';

const StatusSelector = ({
  props,
  loading_cell_ref,
  setLoadingCellRef,
}: {
  props: GridRenderCellParams;
  loading_cell_ref: { [key: number]: boolean };
  setLoadingCellRef: (value: { [key: number]: boolean }) => void;
}) => {
  const { id, value, field, row } = props;
  const apiRef = useGridApiContext();
  const { setNotification } = useContext(AppContext);

  const toggleLoading = (value: boolean) => {
    const new_ref = { ...loading_cell_ref };
    new_ref[id as number] = value;
    setLoadingCellRef({ ...new_ref });
  };

  const updateEventStatus = async (e: EventStatus) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: e,
    });
    apiRef.current.stopCellEditMode({ id, field });

    toggleLoading(true);
    api
      .upsertPlayoffGame({
        playoff_round_id: row.playoff_round_id,
        home_team_id: row.home_team_id,
        status: e,
      })
      .then(() => {
        setNotification({
          open: true,
          severity: 'success' as AlertColor,
          msg: `The ${row.playoff_round} of ${row.home_team} was updated successfully`,
        });
        toggleLoading(false);
      })
      .catch(() => {
        setNotification({
          open: true,
          severity: 'success' as AlertColor,
          msg: `The ${row.playoff_round} of ${row.home_team} was updated successfully`,
        });
        toggleLoading(false);
      });
  };

  return h(SimpleSelect, {
    optionValues: [
      { value: 'pending', label: 'Pending' },
      { value: 'listable', label: 'Listable' },
      { value: 'not_happening', label: 'Not happening' },
      { value: 'completed', label: 'Completed' },
    ],
    isCellMode: true,
    autoFocus: true,
    label: 'Event status',
    defaultValue: value,
    onChange: (e) => updateEventStatus(e as EventStatus),
  });
};

const StatusCell = ({
  props,
  loading,
}: {
  props: GridRenderCellParams;
  loading: boolean;
}) => {
  return h(
    Box,
    {
      sx: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      },
    },
    [
      loading ? h(CircularProgress, { size: 18 }) : null,
      h(Typography, { sx: { fontSize: 14 } }, props.value),
    ],
  );
};

const StatusHeader = (props: GridColumnHeaderParams) => {
  return h(
    Box,
    {
      sx: {
        display: 'flex',
        justifyContent: 'between',
        alignItems: 'center',
        gap: 1,
      },
    },
    [
      h(Typography, { sx: { fontSize: 14 } }, props.field),
      h(Tooltip, {
        title: 'Double click cell to start editing',
        children: h(EditIcon, { color: 'info', fontSize: 'small' }),
      }),
    ],
  );
};

const EventStatusColumn = (): GridColDef => {
  const [loading_cell_ref, setLoadingCellRef] = useState<{
    [key: number]: boolean;
  }>({});

  const renderStatusHeader = (params: GridColumnHeaderParams) => {
    return h(StatusHeader, { ...params });
  };

  const renderStatusSelectEditInputCell: GridColDef['renderCell'] = (
    params: GridRenderCellParams,
  ) => {
    return h(StatusSelector, {
      props: params,
      loading_cell_ref,
      setLoadingCellRef,
    });
  };

  const renderStatusCell = (params: GridRenderCellParams) => {
    const loading = loading_cell_ref[params.id as number] ?? false;
    return h(StatusCell, { props: params, loading });
  };

  return {
    field: 'status',
    minWidth: 150,
    editable: true,
    renderHeader: renderStatusHeader,
    renderEditCell: renderStatusSelectEditInputCell,
    renderCell: renderStatusCell,
    cellClassName: (params: GridCellParams) => {
      if (loading_cell_ref[params.id as number]) return 'is-disabled';
      return '';
    },
  };
};

export default EventStatusColumn;
