import h from '../../../lib/react-hyperscript';
import { ClientTicketPackageEx } from '../../../common/types';
import {
  Box,
  Stack,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Tooltip,
  Dialog,
  Checkbox,
} from '@mui/material';
import * as _ from 'ramda';
import ErrorIcon from '@mui/icons-material/Error';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

const InventoryRow = ({
  id,
  event_date,
  event_name,
}: {
  id?: number;
  event_date?: string;
  event_name?: string;
}) => {
  const dateStr = new Date(event_date + 'Z').toLocaleDateString('en-US', {
    weekday: 'short',
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
    timeZone: 'UTC',
  });

  return h(
    Grid,
    {
      key: id,
      container: true,
      alignItems: 'center',
      width: '100%',
      gap: 1,
    },
    [
      h(
        Grid,
        {
          xs: 3,
          sx: {
            display: 'flex',
            justifyContent: 'center',
          },
        },
        [`ID: ${id}`],
      ),
      h(Grid, { xs: 8 }, [
        h(
          Typography,
          {
            variant: 'body1',
            gutterBottom: true,
            sx: {
              mb: 0,
              textAlign: 'start',
            },
          },
          [`${event_name}`],
        ),
        h(
          Typography,
          {
            variant: 'body1',
            gutterBottom: true,
            sx: {
              mb: 0,
              textAlign: 'start',
            },
          },
          [`${dateStr}`],
        ),
      ]),
    ],
  );
};

export default function TicketPackageInventoryControls({
  ticket_package,
  selected_inventory,
  selected_events,
  has_unsaved_changes,
  onCreateInventory,
  onDeleteInventory,
  onShowToggle,
  onShowBanner,
  onAmpPricingToggled,
  has_unpriced_inventory,
  is_delete_toggled,
  onDeleteToggle,
  getInventoryInfo,
  is_amp_available,
}: {
  ticket_package: ClientTicketPackageEx;
  selected_inventory: Record<number, boolean>;
  selected_events: Record<number, boolean>;
  has_unsaved_changes: boolean;
  onCreateInventory: (event_ids: number[]) => void;
  onDeleteInventory: (inv_ids: number[]) => void;
  onShowToggle: (value: boolean) => void;
  onShowBanner: (value: boolean) => void;
  onAmpPricingToggled: () => Promise<void>;
  has_unpriced_inventory: boolean;
  is_delete_toggled: boolean;
  onDeleteToggle: (value: boolean) => void;
  getInventoryInfo: (id: number) => {
    id?: number;
    event_date?: string;
    event_name?: string;
  };
  is_amp_available: boolean;
}) {
  const event_ids = _.map(
    (k) => parseInt(k),
    _.keys(_.pickBy(_.identity, selected_events)),
  );
  const inv_ids = _.map(
    (k) => parseInt(k),
    _.keys(_.pickBy(_.identity, selected_inventory)),
  );

  const [delete_inventory, setDeleteInventory] = useState(false);
  const [is_amp_loading, setAmpLoading] = useState(false);

  const getDashboardControl = () => {
    // prevent old data that show dashboard without commission(allow to switch back)
    const invalid_commission =
      !ticket_package.commission && !ticket_package.is_shown;
    const waiting_for_tickets = ticket_package.is_awaiting_tickets;
    return h(FormControlLabel, {
      control: h(Switch, {
        disabled: invalid_commission || waiting_for_tickets,
        onChange: (e) => onShowToggle(e.target.checked),
        checked: ticket_package.is_shown,
      }),
      label: !invalid_commission
        ? h('div', { style: { display: 'flex', alignItems: 'center' } }, [
            'Show inventory ',
            !ticket_package.is_playoff && has_unpriced_inventory
              ? h(Tooltip, {
                  title: 'Some inventory need to be priced',
                  placement: 'top',
                  children: h(ErrorIcon, {
                    color: 'warning',
                  }),
                })
              : null,
          ])
        : 'No commission set up',
    });
  };

  const getWaitingTicketsInAccountBannerControl = () => {
    return h(FormControlLabel, {
      label: h(Stack, { direction: 'row', gap: 1 }, [
        `Show "waiting for tickets in account" alert`,
        h(Tooltip, {
          title:
            'This alert is shown as a banner during the confirmation process and only affects returning customers, not new registrations',
          placement: 'top',
          children: h(HelpOutlineIcon, {
            color: 'action',
          }),
        }),
      ]),
      sx: { whiteSpace: 'nowrap', display: 'flex' },
      control: h(Switch, {
        id: 'is_awaiting_tickets',
        name: 'is_awaiting_tickets',
        checked: ticket_package.is_awaiting_tickets,
        disabled: ticket_package.is_shown,
        onChange: (_e, value) => {
          onShowBanner(value);
        },
      }),
    });
  };

  return h(
    Stack,
    {
      direction: 'column',
      gap: 3,
      alignItems: 'start',
      sx: { width: '100%' },
    },
    !ticket_package.in_selling_season
      ? [
          h(
            Typography,
            { sx: { mb: 2 } },
            `Inventory can be created when selling season begins`,
          ),
        ]
      : [
          h(Stack, {}, [
            getWaitingTicketsInAccountBannerControl(),
            getDashboardControl(),
          ]),
          h(
            Stack,
            {
              direction: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              sx: { width: '100%' },
            },
            [
              h(
                Stack,
                {
                  direction: 'row',
                  alignItems: 'center',
                  gap: 3,
                  sx: { width: '100%' },
                },
                [
                  h(
                    Button,
                    {
                      variant: 'contained',
                      sx: { width: '200px' },
                      color: is_delete_toggled ? 'error' : 'primary',
                      fullWidth: false,
                      disabled:
                        has_unsaved_changes ||
                        (event_ids.length === 0 && !is_delete_toggled) ||
                        (inv_ids.length === 0 && is_delete_toggled),
                      onClick: () => {
                        if (is_delete_toggled) setDeleteInventory(true);
                        else onCreateInventory(event_ids);
                      },
                    },
                    [
                      h(Stack, { alignItems: 'center', spacing: -1 }, [
                        h(
                          Box,
                          is_delete_toggled
                            ? 'Delete Inventory'
                            : has_unsaved_changes && event_ids.length > 0
                            ? 'Save Changes First'
                            : 'Create Inventory',
                        ),
                        h(
                          Box,
                          { sx: { fontSize: 'small', textTransform: 'none' } },
                          is_delete_toggled
                            ? `(${inv_ids.length} inventory)`
                            : `(${event_ids.length} events)`,
                        ),
                      ]),
                    ],
                  ),

                  h(FormControlLabel, {
                    control: h(Switch, {
                      color: 'error',
                      onChange: (e) => onDeleteToggle(e.target.checked),
                      checked: is_delete_toggled,
                    }),
                    label: h(
                      'div',
                      { style: { display: 'flex', alignItems: 'center' } },
                      [
                        'Delete Mode ',
                        h(Tooltip, {
                          title:
                            'Listed, broadcasted, sold or split inventory cannot be deleted through the OCC.',
                          placement: 'top',
                          children: h(HelpOutlineIcon, {
                            color: 'action',
                          }),
                        }),
                      ],
                    ),
                  }),
                ],
              ),

              h(
                Stack,
                {
                  direction: 'row',
                  alignItems: 'center',
                  justifyContent: 'end',
                  sx: { width: '100%' },
                },
                [
                  h(
                    LoadingButton,
                    {
                      variant: 'outlined',
                      color: 'primary',
                      onClick: async (_e) => {
                        setAmpLoading(true);
                        await onAmpPricingToggled();
                        setAmpLoading(false);
                      },
                      disabled: !is_amp_available,
                      loading: is_amp_loading,
                    },
                    [
                      h(
                        'div',
                        { style: { display: 'flex', alignItems: 'center' } },
                        [
                          h(Stack, { alignItems: 'center', spacing: -1 }, [
                            h(Box, {}, 'Price with AMP'),
                          ]),
                        ],
                      ),
                    ],
                  ),
                ],
              ),
            ],
          ),

          h(
            Dialog,
            {
              open: delete_inventory,
              onClose: () => setDeleteInventory(false),
            },
            [
              h(
                Box,
                {
                  sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                  },
                },
                [
                  h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 3 }, [
                    'Delete inventory',
                  ]),

                  h(Stack, { mb: 4, gap: 1, width: '100%' }, [
                    h(
                      Typography,
                      {
                        variant: 'body1',
                        fontWeight: 'bold',
                      },
                      [`Review following ${inv_ids.length} inventory:`],
                    ),

                    h(
                      Stack,
                      {
                        direction: 'column',
                        alignItems: 'start',
                        spacing: 2,
                        width: '100%',
                        height: '245px',
                        overflow: 'scroll',
                      },
                      inv_ids.map((id) => InventoryRow(getInventoryInfo(id))),
                    ),
                  ]),

                  h(
                    'div',
                    {
                      sx: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                    },
                    [
                      h(
                        Button,
                        {
                          variant: 'contained',
                          sx: { marginX: 2 },
                          color: 'error',
                          onClick: () => setDeleteInventory(false),
                        },
                        ['No'],
                      ),
                      h(
                        Button,
                        {
                          variant: 'contained',
                          sx: { marginX: 2 },
                          color: 'success',
                          onClick: () => {
                            onDeleteInventory(inv_ids);
                            setDeleteInventory(false);
                          },
                        },
                        ['Yes'],
                      ),
                    ],
                  ),
                ],
              ),
            ],
          ),
        ],
  );
}
