import h from '../../../lib/react-hyperscript';
import {
  Box,
  Paper,
  Typography,
  Autocomplete,
  TextField,
  Button,
  IconButton,
  ButtonGroup,
  lighten,
  Accordion,
  AccordionSummary,
  Stack,
  AccordionDetails,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { format } from '../../../common/components';
import {
  InventoryItem,
  MarketListing,
  SalesReports,
} from '../../../common/types';
import { useEffect, useState } from 'react';
import * as api from '../../../common/api';
import { useParams } from 'react-router-dom';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

const comps_column_specs: GridColDef[] = [
  { field: 'price', valueFormatter: format.dollars },
  {
    field: 'section',
    valueGetter: ({ row }) =>
      isNaN(parseInt(row.section)) ? row.section : parseInt(row.section),
  },
  {
    field: 'row',
    valueGetter: ({ row }) =>
      isNaN(parseInt(row.row)) ? row.row : parseInt(row.row),
  },
  {
    field: 'seats',
    valueGetter: ({ row }) => row.first_seat + '-' + row.last_seat,
  },
  { field: 'q_split' },
];

type ComparableSectionOption = {
  id: number;
  comp_sections: string;
};

const ComparableSectionSearch = ({
  onSubmitSearch,
}: {
  onSubmitSearch: (opts: {
    id: number;
    comp_sections?: string;
  }) => Promise<SalesReports>;
}) => {
  const { inventory_id } = useParams<{ inventory_id: string }>();
  const id = parseInt(inventory_id as string, 10);

  const [comp_query, setCompQuery] = useState('');
  const [comp_section_options, setCompSectionOptions] = useState<
    ComparableSectionOption[]
  >([]);

  const loadCompSections = async () => {
    const dt = await api.getComparableSection(id);
    setCompSectionOptions(dt);
  };

  const saveCompSections = async () => {
    await api.upsertComparableSection(id, comp_query.replace(/\s+/g, ''));
    await loadCompSections();
  };

  const deleteCompSections = async (id: number) => {
    await api.deleteComparableSection(id);
    await loadCompSections();
  };

  useEffect(() => {
    loadCompSections();
  }, []);

  return h(
    Grid,
    {
      container: true,
      width: '100%',
      alignItems: 'center',
      marginX: 0,
      paddingX: 0,
    },
    [
      h(
        Grid,
        {
          key: 'comparable_section_input',
          sm: 12,
          md: 12,
          lg: 8,
          paddingX: 0,
          width: '100%',
        },
        [
          h(Autocomplete, {
            id: 'comparable-section-search',
            freeSolo: true,
            size: 'small',
            options: comp_section_options,
            filterSelectedOptions: true,
            value: comp_query,
            onInputChange: (event, newValue) => setCompQuery(newValue),
            getOptionLabel: (option) => {
              if (typeof option === 'string') return option;
              // @ts-ignore
              return option.comp_sections_query;
            },
            renderOption: (props, option) =>
              h('li', { ...props }, [
                // @ts-ignore
                h(
                  Grid,
                  {
                    container: true,
                    width: '100%',
                    alignItems: 'center',
                  },
                  [
                    h(Grid, { sm: 11 }, [
                      h(
                        Typography,
                        {
                          sx: {
                            wordWrap: 'break-word',
                          },
                        },
                        // @ts-ignore
                        option.comp_sections_query,
                      ),
                    ]),
                    h(Grid, { sm: 1 }, [
                      h(
                        IconButton,
                        {
                          onClick: (e) => {
                            e.stopPropagation();
                            // @ts-ignore
                            deleteCompSections(option.id);
                          },
                        },
                        [h(HighlightOffIcon)],
                      ),
                    ]),
                  ],
                ),
              ]),
            renderInput: (params) =>
              h(TextField, {
                ...params,
                label: 'Comparable Sections',
                placeholder: '',
              }),
          }),
        ],
      ),
      h(
        Grid,
        {
          key: 'comparable_section_btn_group',
          sm: 12,
          md: 12,
          lg: 4,
          paddingX: 0,
        },
        [
          h(ButtonGroup, { sx: { width: '90%' }, size: 'small' }, [
            h(
              Button,
              {
                variant: 'outlined',
                sx: { width: '100%' },
                onClick: () =>
                  onSubmitSearch({
                    id,
                    comp_sections: comp_query.replace(/\s+/g, ''),
                  }),
              },
              ['Show'],
            ),
            h(
              Button,
              {
                variant: 'outlined',
                sx: { width: '100%' },
                onClick: () => saveCompSections(),
              },
              ['Save'],
            ),
          ]),
        ],
      ),
    ],
  );
};

const SalesComparableSections = ({
  inv_item,
  comps,
  seat_chart,
  doFetch,
  selection_model,
  setSelectionModel,
  setCompPrice,
  total_listings,
}: {
  inv_item: InventoryItem;
  comps: MarketListing[];
  seat_chart?: string;
  doFetch: (opts: {
    id: number;
    comp_sections?: string;
  }) => Promise<SalesReports>;
  selection_model: GridRowSelectionModel;
  setSelectionModel: (value: GridRowSelectionModel) => void;
  setCompPrice: (value: string) => void;
  total_listings?: number;
}) => {
  const getRowColor = (cur_row: string) => {
    let row_diff = parseInt(cur_row) - parseInt(inv_item.row);
    if (isNaN(row_diff))
      row_diff = cur_row.charCodeAt(0) - inv_item.row.charCodeAt(0);
    if (isNaN(row_diff) || row_diff > 4) return '';
    return 'comparable-row';
  };
  const [is_expanded, setExpanded] = useState(true);

  const seatChartComponent = () => {
    return seat_chart
      ? h('img', {
          src: seat_chart,
          width: '100%',
        })
      : h(Typography, 'Seating chart unavailable');
  };

  return h(Accordion, {
    sx: { marginY: '0!important' },
    expanded: is_expanded,
    children: [
      h(AccordionSummary, { sx: { cursor: 'default!important' } }, [
        h(
          Grid,
          {
            container: true,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          [
            h(Stack, { direction: 'row', alignItems: 'center' }, [
              h(
                IconButton,
                { size: 'small', onClick: () => setExpanded(!is_expanded) },
                [h(is_expanded ? ExpandLessIcon : ExpandMoreIcon)],
              ),
              h(
                Typography,
                {
                  variant: 'h6',
                  gutterBottom: true,
                  sx: { fontWeight: 'bold', mb: 0 },
                },
                'Comparable sections',
              ),
            ]),
          ],
        ),
      ]),

      h(AccordionDetails, { sx: { paddingX: 3 } }, [
        h(
          Grid,
          {
            container: true,
            spacing: 1,
            width: '100%',
            alignItems: 'center',
          },
          [
            h(
              Grid,
              {
                key: 1,
                sm: 12,
                md: 12,
                lg: 6,
                alignItems: 'center',
                width: '100%',
              },
              [
                h(
                  Typography,
                  {
                    variant: 'body1',
                  },
                  `Total listings: ${total_listings ?? 'Unknown'}`,
                ),
                h(ComparableSectionSearch, { onSubmitSearch: doFetch }),
                h(
                  Box,
                  {
                    sx: {
                      height: '500px',
                      '.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                        {
                          display: 'none',
                        },
                      '& .comparable-row': {
                        bgcolor: (theme) =>
                          lighten(theme.palette.info.main, 0.9),

                        '&:hover': {
                          bgcolor: (theme) =>
                            `${lighten(
                              theme.palette.info.main,
                              0.7,
                            )}!important`,
                        },
                      },
                    },
                  },
                  [
                    h(DataGrid, {
                      rows: comps,
                      columns: comps_column_specs,
                      // rowsPerPageOptions: [10],
                      pagination: true,
                      checkboxSelection: true,
                      disableRowSelectionOnClick: true,
                      autoPageSize: true,
                      density: 'compact',
                      initialState: {
                        sorting: {
                          sortModel: [{ field: 'price', sort: 'asc' }],
                        },
                        pagination: {
                          paginationModel: { page: 1, pageSize: 100 },
                        },
                      },
                      pageSizeOptions: [25, 50, 100],
                      rowSelectionModel: selection_model,
                      onRowDoubleClick: ({ row }) => {
                        setSelectionModel([row.id]);
                        setCompPrice(row.price.toFixed(2));
                      },
                      onRowSelectionModelChange: (ids) => {
                        const recent_selection = ids.pop();
                        setSelectionModel(
                          recent_selection ? [recent_selection] : [],
                        );
                        const recent_selection_price = comps
                          .find((item) => item.id === recent_selection)
                          ?.price.toFixed(2);
                        if (!!recent_selection_price)
                          setCompPrice(recent_selection_price);
                      },
                      getRowClassName: ({ row }) => getRowColor(row.row),
                    }),
                  ],
                ),
              ],
            ),
            h(Grid, { key: 2, sm: 12, md: 12, lg: 6, alignItems: 'center' }, [
              seatChartComponent(),
            ]),
          ],
        ),
      ]),
    ],
  });
};

export default SalesComparableSections;
