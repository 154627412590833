import h from '../../../lib/react-hyperscript';
import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { useAsyncFn } from 'react-use';
import TicketPackageEditor from './PackageEditor';

import * as api from '../../../common/api';
import { useBaseData } from '../../../common/base_data';
import { useEffect } from 'react';
import { ClientTicketPackageEx } from '../../../common/types';
import { useCachedData } from '../../../common/cached_data';

export default function AddTicketPackagePanel({
  pkg,
  onCancel,
}: {
  pkg?: ClientTicketPackageEx;
  onCancel?: () => void;
}) {
  const [state, createPackage] = useAsyncFn(api.createPackage);

  const {
    baseData: { getTeamsForLeague, getLeagueIds, getClients },
  } = useBaseData();

  useEffect(() => {
    if (state?.value?.id) {
      window.location.href = `/ticket-packages/${state.value.id}`;
    }
  }, [state]);

  return h(
    Box,
    {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    [
      h(FetchBackdrop, { state }),
      h(
        Box,
        {
          sx: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        },

        [
          h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 4, pt: 2 }, [
            `Add ${!!pkg ? ' Playoffs ' : ' '}Ticket Package`,
          ]),
          h(
            Grid,
            {
              container: true,
              spacing: 12,
              columns: { xs: 1, md: 1 },
              sx: { maxWidth: '500px' },
            },

            [
              h(Grid, { key: 1, xs: 1 }, [
                h(Box, { pb: 2 }, []),
                h(Paper, { elevation: 3 }, [
                  h(Box, { pr: 2, pb: 2, pl: 2, pt: 2 }, [
                    h(TicketPackageEditor, {
                      pkg,
                      onCancel,
                      variant: 'add',
                      league_ids: getLeagueIds(),
                      clients: getClients(),
                      getTeamsForLeague,
                      onAdd: (client_id, pkg) => createPackage(client_id, pkg),
                    }),
                  ]),
                ]),
              ]),
            ],
          ),
        ],
      ),
    ],
  );
}
