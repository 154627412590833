import h from '../../lib/react-hyperscript';
import { default as MuiTimeline } from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

export default function Timeline({
  steps,
}: {
  steps: { left: string; right: string }[];
}) {
  return h(
    MuiTimeline,
    { sx: { margin: 0, padding: 0 } },
    steps.map(({ left, right }, i) =>
      h(TimelineItem, { sx: { minHeight: '50px' } }, [
        h(
          TimelineOppositeContent,
          { color: 'text.secondary', fontSize: '14px' },
          left,
        ),
        h(TimelineSeparator, [
          h(TimelineDot),
          i < steps.length - 1 ? h(TimelineConnector) : null,
        ]),
        h(TimelineContent, { fontSize: '14px' }, right),
      ]),
    ),
  );
}
