import h from '../../lib/react-hyperscript';
import PlayoffGamesPanel from './components/PlayoffGamesPanel';
import { PlayoffGameListPanel } from './components/PlayoffGameListPanel';

const routing = {
  element: h(PlayoffGamesPanel),
  children: [
    {
      path: '',
      element: h(PlayoffGameListPanel),
    },
  ],
};

export { routing };
