import h from '../../../lib/react-hyperscript';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  IconButton,
  Input,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import {
  AttributeItem,
  InventoryItem,
  PricingFactor,
} from '../../../common/types';
import { durationDays } from '../../../common/helper';
import { SimpleSelect } from '../../../common/components';
import { useBaseData } from '../../../common/base_data';

const getAttributeItem = (
  key: string,
  opts: PricingFactor[],
): AttributeItem => {
  const [opt1, opt2] = opts;
  return {
    id: key,
    auto_suggested: false,
    disabled: false,
    selected: false,
    order: opt1.order as number,
    title: key,
    category: opt1.category,
    options: [
      {
        id: opt1.is_positive ? opt1.id : opt2?.id,
        label: (opt1.is_positive
          ? opt1.description
          : opt2?.description) as string,
        type: 'pros',
      },
      {
        id: !opt1.is_positive ? opt1.id : opt2?.id,
        label: (!opt1.is_positive
          ? opt1.description
          : opt2?.description) as string,
        type: 'cons',
      },
    ],
    value: opt2 ? undefined : opt1.id,
    input: {
      value: '',
      type: '',
      editable: false,
      placeholder: '',
    },
  };
};

export default function SalesAttributes({
  inv_item,
  total_listings,
  attributes_list,
  grouped_pricing_factors,
  getPricingFactorById,
  setAttributesList,
}: {
  inv_item: InventoryItem;
  total_listings?: number;
  attributes_list: AttributeItem[];
  grouped_pricing_factors: { [key: string]: PricingFactor[] };
  getPricingFactorById: { [key: number]: PricingFactor };
  setAttributesList: (new_attributes: AttributeItem[]) => void;
}) {
  const {
    baseData: { getTeamNameShortByTeam },
  } = useBaseData();

  const isOppositeFactor = (id1?: number, id2?: number) =>
    id1 &&
    id2 &&
    getPricingFactorById[id1].is_positive !==
      getPricingFactorById[id2].is_positive;

  useEffect(() => {
    const new_attr_list = [];
    for (const key in grouped_pricing_factors) {
      const item: AttributeItem = getAttributeItem(
        key,
        grouped_pricing_factors[key],
      );

      // Auto-suggesting by default for some attributes
      const duration = inv_item.listed
        ? durationDays(
            new Date(inv_item.listed),
            new Date(inv_item.event_date_local),
          )
        : undefined;
      // @ts-ignore
      const event_day = inv_item.skybox.eventDayOfWeek as string;
      // fill up default value
      switch (key) {
        case 'Time listed before game':
          item.input.value = `${duration}`;
          item.auto_suggested = true;
          if (duration === undefined) {
            item.auto_suggested = false;
            item.input.editable = true;
            item.input.type = 'number';
            item.input.placeholder = 'days';
          } else if (duration >= 60) item.value = item.options[0].id;
          else if (duration <= 30) item.value = item.options[1].id;
          break;
        case 'Day of week':
          item.input.value = event_day;
          item.auto_suggested = true;
          if (event_day === 'Thursday') item.disabled = true;
          else if (
            event_day === 'Friday' ||
            event_day === 'Saturday' ||
            event_day === 'Sunday'
          )
            item.value = item.options[0].id;
          else item.value = item.options[1].id;
          break;
        case 'Amount of inventory':
          item.input.value = `${total_listings}`;
          break;
        case 'Key Injury':
          item.value = item.options[1].id;
          item.input.editable = true;
          item.input.type = 'text';
          item.input.placeholder = 'Player names';
          break;
        case 'General resale value, opponent':
          item.input.value = getTeamNameShortByTeam(inv_item.away_team ?? '');
          break;
        case 'Away team performance compared to expectations':
          item.input.value = getTeamNameShortByTeam(inv_item.away_team ?? '');
          break;
        case 'Away team likelihood to make playoffs':
          item.input.value = getTeamNameShortByTeam(inv_item.away_team ?? '');
          break;
        case 'Home team performance compared to expectations':
          item.input.value = getTeamNameShortByTeam(inv_item.home_team ?? '');
          break;
        case 'Home team likelihood to make playoffs':
          item.input.value = getTeamNameShortByTeam(inv_item.home_team ?? '');
          break;
      }

      new_attr_list.push(item);
    }

    setAttributesList(new_attr_list);
  }, [inv_item]);

  const [sort_by, setSortBy] = useState('any');

  const sortAttributesList = (prop: string) => {
    const new_attr_list = [...attributes_list];
    setAttributesList(
      new_attr_list.sort((a, b) => {
        if (prop === 'unselected') {
          if (a.selected && !b.selected) return 1;
          if (!a.selected && b.selected) return -1;
        }
        if (prop === 'selected') {
          if (a.selected && !b.selected) return -1;
          if (!a.selected && b.selected) return 1;
        }
        return a.order - b.order;
      }),
    );
  };

  const [is_expanded, setExpanded] = useState(true);

  return h(Accordion, {
    expanded: is_expanded,
    children: [
      h(AccordionSummary, { sx: { cursor: 'default!important' } }, [
        h(
          Grid,
          {
            container: true,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          [
            h(Stack, { direction: 'row', alignItems: 'center' }, [
              h(
                IconButton,
                { size: 'small', onClick: () => setExpanded(!is_expanded) },
                [h(is_expanded ? ExpandLessIcon : ExpandMoreIcon)],
              ),
              h(
                Typography,
                {
                  variant: 'h6',
                  gutterBottom: true,
                  sx: { fontWeight: 'bold', mb: 0 },
                },
                'Attributes (Customer education)',
              ),
            ]),
            is_expanded
              ? h(SimpleSelect, {
                  optionValues: [
                    { value: 'any', label: 'Any' },
                    { value: 'selected', label: 'Selected' },
                    { value: 'unselected', label: 'Unselected' },
                  ],
                  label: 'Sort by',
                  defaultValue: sort_by,
                  onChange: (e) => {
                    sortAttributesList(e as string);
                    setSortBy(e as string);
                  },
                })
              : '',
          ],
        ),
      ]),

      h(AccordionDetails, { sx: { paddingX: 3 } }, [
        h(
          Stack,
          {
            direction: 'column',
            alignItems: 'start',
            spacing: 1,
            width: '100%',
            height: '230px',
            overflow: 'scroll',
          },
          attributes_list.map((item) =>
            h(
              Grid,
              {
                key: item.id,
                container: true,
                alignItems: 'center',
                width: '100%',
                sx: {
                  borderBottom: 0.3,
                  cursor: item.disabled ? 'not-allowed' : '',
                },
              },
              [
                h(
                  Grid,
                  { xs: 1, sx: { display: 'flex', justifyContent: 'start' } },
                  [
                    h(Checkbox, {
                      checked: item.selected,
                      disabled: item.disabled,
                      size: 'small',
                      onClick: (e) => {
                        if (item.disabled) return;
                        setAttributesList(
                          attributes_list.map((att) => {
                            if (att.id === item.id)
                              att.selected = !item.selected;
                            else if (
                              att.category === item.category &&
                              isOppositeFactor(att.value, item.value)
                            )
                              att.selected = false;
                            return att;
                          }),
                        );
                        sortAttributesList(sort_by);
                      },
                    }),
                  ],
                ),

                h(
                  Grid,
                  {
                    xs: 1.5,
                    sx: {
                      display: 'flex',
                      justifyContent: 'start',
                      gap: 0.4,
                    },
                  },
                  item.options.map((option) =>
                    h(Tooltip, {
                      title: option.label ?? '',
                      placement: 'top-start',
                      children: h(
                        option.type === 'pros'
                          ? SentimentVerySatisfiedIcon
                          : SentimentVeryDissatisfiedIcon,
                        {
                          color:
                            !item.disabled && item.value === option.id
                              ? option.type === 'pros'
                                ? 'success'
                                : 'error'
                              : 'disabled',
                          sx: {
                            opacity: !option.id ? 0 : 1,
                            cursor: item.disabled
                              ? 'not-allowed'
                              : !item.auto_suggested && !!option.id
                              ? 'pointer'
                              : '',
                          },
                          onClick: (_) => {
                            if (
                              item.disabled ||
                              item.auto_suggested ||
                              !option.id
                            )
                              return;
                            setAttributesList(
                              attributes_list.map((att) => {
                                if (att.id === item.id) att.value = option.id;
                                else if (
                                  item.selected &&
                                  att.category === item.category &&
                                  isOppositeFactor(att.value, option.id)
                                )
                                  att.selected = false;

                                return att;
                              }),
                            );
                          },
                        },
                      ),
                    }),
                  ),
                ),

                h(
                  Grid,
                  {
                    xs: 6.5,
                    sx: { display: 'flex', justifyContent: 'start' },
                  },
                  [
                    h(
                      Typography,
                      {
                        variant: 'body2',
                        gutterBottom: true,
                        color: item.disabled ? 'gray' : '',
                        sx: {
                          fontWeight: 'bold',
                          mb: 0,
                          textAlign: 'start',
                        },
                      },
                      item.title,
                    ),
                  ],
                ),

                h(
                  Grid,
                  {
                    xs: 3,
                    sx: { display: 'flex', justifyContent: 'start', pl: 1 },
                  },
                  [
                    item.input.type
                      ? h(Input, {
                          placeholder: item.input.placeholder,
                          type: item.input.type,
                          size: 'small',
                          defaultValue: item.input.value,
                          error: item.selected && !item.input.value,
                          onBlur: (e) => {
                            if (item.disabled) return;
                            setAttributesList(
                              attributes_list.map((att) => {
                                if (att.input && att.id === item.id)
                                  att.input.value = e.target.value;
                                return att;
                              }),
                            );
                          },
                        })
                      : h(
                          Typography,
                          {
                            variant: 'body2',
                            gutterBottom: true,
                            color: item.disabled ? 'gray' : '',
                            sx: { mb: 0 },
                          },
                          `${item.input.value}`,
                        ),
                  ],
                ),
              ],
            ),
          ),
        ),
        h(
          Typography,
          {
            variant: 'subtitle2',
            sx: { mb: 0, textAlign: 'end' },
          },
          `Total selected: ${
            attributes_list.filter(
              (item) =>
                item.selected &&
                item.value &&
                getPricingFactorById[item.value]?.is_positive,
            ).length
          } pros vs. ${
            attributes_list.filter(
              (item) =>
                item.selected &&
                item.value &&
                !getPricingFactorById[item.value]?.is_positive,
            ).length
          } cons`,
        ),
      ]),
    ],
  });
}
