// Helper function for reuse
import { lightFormat } from 'date-fns';
import * as _ from 'ramda';

/* Ticket package commission */
export const toCommissionStr = (commission: string | number | null) => {
  if (commission === null || commission === '') {
    return '';
  }

  const c =
    typeof commission === 'string' ? parseFloat(commission) : commission;

  return (Math.round(c * 10000) / 100).toFixed(2);
};

export const nextUtcWeekday = (next: number, from: Date | null) => {
  // keep in mind, the values are about UTC
  // from_str: expect it in UTC format
  // return UTC str
  if (!from) return '';
  const nextDate = new Date(from);
  nextDate.setUTCDate(
    nextDate.getUTCDate() + ((next + 7 - nextDate.getUTCDay()) % 7 || 7),
  );
  const [_, day, month, year] = nextDate.toUTCString().split(' ');
  return `${month} ${day}, ${year}`;
};

export const nextLocalWeekday = (next: number, from: Date | string | null) => {
  if (!from) return '';
  const nextDate = new Date(from);
  nextDate.setDate(
    nextDate.getDate() + ((next + 7 - nextDate.getDay()) % 7 || 7),
  );
  const [_, month, day, year] = nextDate.toDateString().split(' ');
  return `${month} ${day}, ${year}`;
};

export const durationDays = (a: Date, b: Date) =>
  Math.round((b.getTime() - a.getTime()) / (1000 * 3600 * 24));

export const convertObjectToString = (obj: { [key: string]: unknown }) => {
  let prop_arr = [];
  for (const key in obj) {
    prop_arr.push(`${key}: ${obj[key]}`);
  }
  return prop_arr.join('\n');
};

export const utcToPstDateString = (utc_timestamp: string) => {
  if (!utc_timestamp) return '';
  const formatter = new Intl.DateTimeFormat([], {
    timeZone: 'America/Los_Angeles',
  });
  const pst_date = formatter.format(new Date(utc_timestamp)); // '03/02/2023'
  return new Date(pst_date).toDateString();
};

export const convertMillisecondsToTime = (milliseconds: number) => {
  const totalSeconds = milliseconds / 1000;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return { hours, minutes, seconds };
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const fmt = {
  dollars: (value: number | string | null) =>
    typeof value === 'number'
      ? formatter.format(Math.round(value))
      : typeof value === 'string'
      ? formatter.format(Math.round(parseFloat(value)))
      : '',

  datetime: (value: string) =>
    value
      ? lightFormat(new Date(value), 'MM-dd-yyyy hh:mm a').toLowerCase()
      : null,

  date: (value: string) =>
    value ? lightFormat(new Date(value), 'MM-dd-yyyy').toLowerCase() : null,

  time: (value: string) =>
    value ? lightFormat(new Date(value), 'hh:mm a').toLowerCase() : null,

  dateSort: (value: string) =>
    value ? lightFormat(new Date(value), 'yyyy-MM-dd').toLowerCase() : null,

  timeSort: (value: string) =>
    value ? lightFormat(new Date(value), 'HH:mm').toLowerCase() : null,

  dayOfWeek: (value: string) => {
    const dateObj = new Date(value);
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days[dateObj.getDay()];
  },

  percent: (value: number) =>
    _.isNil(value) ? null : Math.round(value * 100) + '%',

  percentWithTwoDecimals: (value: number) =>
    _.isNil(value) ? null : (Math.round(value * 10000) / 100).toFixed(2) + '%',
};

export const calculateTimeDifference = (startTime: Date) => {
  const diff_milliseconds = Math.abs(
    new Date(startTime).getTime() - new Date().getTime(),
  );
  const totalSeconds = diff_milliseconds / 1000;
  const days = Math.floor(totalSeconds / 3600 / 24);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds);

  const getTimeToNow = (value: number, unit: string) =>
    `${value} ${unit}${value > 1 ? 's' : ''}`;

  return days
    ? getTimeToNow(days, 'day')
    : hours
    ? getTimeToNow(hours, 'hour')
    : minutes
    ? getTimeToNow(minutes, 'minute')
    : getTimeToNow(seconds, 'second');
};
