import h from '../../lib/react-hyperscript';
import PricingPanel from './components/PricingPanel';
import PackagePricingPanel from './components/PackagePricingPanel';
import EventPricingPanel from './components/EventPricingPanel';
import TeamPricingPanel from './components/TeamPricingPanel';
import InventoryPricingPanel from './components/InventoryPricingPanel';
import InventoryHistoryPanel from './components/InventoryHistoryPanel';

const routing = {
  element: h(PricingPanel),
  children: [
    {
      path: 'inventory',
      element: h(InventoryPricingPanel),
    },
    {
      path: 'events',
      element: h(EventPricingPanel),
    },
    {
      path: 'teams',
      element: h(TeamPricingPanel),
    },
    {
      path: 'packages',
      element: h(PackagePricingPanel),
    },
    {
      path: 'inventory/:id',
      element: h(InventoryHistoryPanel),
    },
  ],
};

export { routing };
