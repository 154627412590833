import h from '../../../lib/react-hyperscript';
import { Outlet, useLocation } from 'react-router-dom';
import { Breadcrumbs, Link, Stack } from '@mui/material';

const Level = ({ label, href }: { label: string; href: string }) =>
  h(Stack, [
    h(
      Link,
      {
        sx: {
          variant: 'soft',
          justifySelf: 'flex-start',
          textAlign: 'left',
        },
        href: href,
        mb: 1,
      },
      label,
    ),
  ]);

const Levels = () => {
  return h(Stack, { ml: 6, spacing: 4, direction: 'row' }, [
    Level({ label: 'Teams', href: '/pricing/teams' }),
    Level({ label: 'Events', href: '/pricing/events' }),
    Level({ label: 'Packages', href: '/pricing/packages' }),
    Level({ label: 'Inventory', href: '/pricing/inventory' }),
  ]);
};

export default function PricingPanel() {
  const location = useLocation();
  const path = location.pathname.split('/').filter((p) => p !== '');

  return h(
    Stack,
    {
      flexGrow: 1,
      direction: 'column',
    },
    [
      h(
        Stack,
        {
          direction: 'row',
        },
        [
          h(
            Breadcrumbs,
            { 'aria-label': 'breadcrumb' },
            path.map((p) => {
              const href = '/pricing';
              return h(
                Link,
                {
                  underline: 'hover',
                  color: 'inherit',
                  href: href,
                  key: href,
                },
                p,
              );
            }),
          ),
          h(Levels),
        ],
      ),
      h(Outlet),
    ],
  );
}
