import h from '../../lib/react-hyperscript';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useContext, useState } from 'react';
import {
  AlertColor,
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppContext } from '../store/context';
import { GridColumnHeaderParams } from '@mui/x-data-grid/models/params/gridColumnHeaderParams';
import { Edit as EditIcon, Subject as SubjectIcon } from '@mui/icons-material';
import NoteDetailsDialog from './NoteDetailsDialog';

const NoteCell = ({
  props,
  saveNoteHeaderFn,
  saveNoteBodyFn,
  getDataFn,
  getEntryDescription,
  showNoteBodyIcon,
  api_key_field,
}: {
  props: GridRenderCellParams;
  saveNoteBodyFn: (
    row: any,
    value: any,
    api_key_field: string,
  ) => Promise<void>;
  saveNoteHeaderFn: (
    row: any,
    value: any,
    api_key_field: string,
  ) => Promise<void>;
  getDataFn: (row: any, api_key_field: string) => Promise<any>;
  getEntryDescription: (row: any) => string;
  showNoteBodyIcon?: (row: any) => boolean;
  api_key_field: string;
}) => {
  const { value, field, row } = props; // row data

  const { setNotification } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [is_dialog_open, setDialogOpen] = useState(false);

  const onSaveNotes = async (
    saveFn: () => Promise<void>,
    type: 'header' | 'note',
  ) => {
    setLoading(true);
    try {
      await saveFn();
      setNotification({
        open: true,
        severity: 'success' as AlertColor,
        msg: `${
          type === 'header' ? 'Header' : 'Notes'
        } has been updated successfully`,
      });
    } catch (e) {
      setNotification({
        open: true,
        severity: 'error' as AlertColor,
        msg: `Failed to update ${type === 'header' ? 'header' : 'notes'}`,
      });
    }
    setLoading(false);
  };

  return h(
    'div',
    {
      style: { width: '100%', height: '100%' },
    },
    [
      h(
        Box,
        {
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            width: '100%',
            height: '100%',
          },
          onClick: (_: any) => {
            setDialogOpen(true);
          },
        },
        [
          loading ? h(CircularProgress, { size: 18 }) : null,
          showNoteBodyIcon?.(row)
            ? h(SubjectIcon, { color: 'info', fontSize: 'small' })
            : null,
          h(
            Typography,
            {
              sx: {
                fontSize: 14,
                width: '100%',
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
            value,
          ),
        ],
      ),

      is_dialog_open
        ? h(NoteDetailsDialog, {
            cur_header: row[field] ?? '',
            entry_desc: `${getEntryDescription(row)}`,
            saveNoteBodyFn: (new_value) =>
              onSaveNotes(
                () => saveNoteBodyFn(row, new_value, api_key_field),
                'note',
              ),
            saveNoteHeaderFn: (new_value) =>
              onSaveNotes(
                () => saveNoteHeaderFn(row, new_value, api_key_field),
                'header',
              ),
            getDataFn: () => getDataFn(row, api_key_field),
            onClose: () => setDialogOpen(false),
          })
        : null,
    ],
  );
};

const StatusHeader = ({ header_name }: { header_name: string }) => {
  return h(
    Box,
    {
      sx: {
        display: 'flex',
        justifyContent: 'between',
        alignItems: 'center',
        gap: 1,
      },
    },
    [
      h(Typography, { sx: { fontSize: 14 } }, header_name),
      h(Tooltip, {
        title: 'Double click cell to start editing',
        children: h(EditIcon, { color: 'info', fontSize: 'small' }),
      }),
    ],
  );
};

const DataGridNoteColumnV2 = ({
  field,
  valueGetter,
  headerName,
  minWidth,
  saveNoteHeaderFn,
  saveNoteBodyFn,
  getDataFn,
  getEntryDescription,
  hasNoteBody,
  api_key_field,
  isHidden,
}: {
  field: string;
  headerName?: string;
  minWidth?: number;
  saveNoteHeaderFn: (
    row: any,
    value: string,
    api_key_field: string,
  ) => Promise<void>;
  saveNoteBodyFn: (
    row: any,
    value: string,
    api_key_field: string,
  ) => Promise<void>;
  getDataFn: (row: any, api_key_field: string) => Promise<any>;
  getEntryDescription: (row: any) => string;
  api_key_field: string;
  isHidden?: ({ row }: { row: any }) => boolean;
  hasNoteBody?: (row: any) => boolean;
  valueGetter?: (params: GridCellParams) => any;
}): GridColDef => {
  const renderNoteHeader = (_: GridColumnHeaderParams) => {
    return h(StatusHeader, { header_name: headerName ?? field });
  };

  const renderNoteCell = (params: GridRenderCellParams) => {
    return (isHidden ? !isHidden(params) : true)
      ? h(NoteCell, {
          props: params,
          saveNoteHeaderFn,
          saveNoteBodyFn,
          getDataFn,
          api_key_field,
          getEntryDescription,
          showNoteBodyIcon: hasNoteBody,
        })
      : h(Stack, {
          style: {
            cursor: 'not-allowed',
            width: '100%',
            height: '100%',
          },
        });
  };

  return {
    field,
    headerName: headerName ?? field,
    minWidth: minWidth ?? 220,
    valueGetter: valueGetter ?? ((params: GridCellParams) => params.value),
    renderHeader: renderNoteHeader,
    renderCell: renderNoteCell,
    cellClassName: (params: GridCellParams) => {
      return (isHidden ? !isHidden(params) : true) ? '' : 'is-disabled';
    },
    sortComparator: (a, b) => {
      let x, y;
      if (a && b && typeof a === 'object' && typeof b === 'object') {
        x = a.reduce((e: string, c: { note?: string }) => {
          return e + (c.note || ' ');
        }, '');
        y = b.reduce((e: string, c: { note: string }) => {
          return e + (c.note || ' ');
        }, '');
      } else {
        x = a || '';
        y = b || '';
      }
      return x === y ? 0 : x > y ? 1 : -1;
    },
  };
};

export default DataGridNoteColumnV2;
