import { GridColDef } from '@mui/x-data-grid-pro';
import { format } from '../components';

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const assertionError = (msg?: string) => {
  const err = new Error(msg);
  err.name = 'AssertionError';
  return err;
};

export const assertIsDefined: <T>(
  val: T,
  msg?: string,
) => asserts val is NonNullable<T> = (val, msg) => {
  if (val === undefined || val === null) {
    throw assertionError(msg ?? `Expected value to be defined`);
  }
};

export const stringDateTime = (options: GridColDef) => ({
  type: 'dateTime',
  valueFormatter: format.datetime,
  valueGetter: ({ value }: { value: string }) =>
    value ? new Date(value) : null,
  ...options,
});

const _treatAsUtc = (date: Date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const daysBetween = (start_date: Date, end_date: Date): number => {
  const millis_per_day = 24 * 60 * 60 * 1000;
  return millisBetween(start_date, end_date) / millis_per_day;
};

export const hoursBetween = (start_date: Date, end_date: Date): number => {
  const millis_per_hour = 60 * 60 * 1000;
  return millisBetween(start_date, end_date) / millis_per_hour;
};

// https://stackoverflow.com/questions/542938/how-to-calculate-number-of-days-between-two-dates
export const millisBetween = (start_date: Date, end_date: Date): number => {
  return _treatAsUtc(end_date).getTime() - _treatAsUtc(start_date).getTime();
};
