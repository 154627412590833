import h from '../../lib/react-hyperscript';
import { CircularProgress } from '@mui/material';

const LoadingMask = () => {
  return h(
    'div',
    {
      style: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 100,
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    [
      h(CircularProgress, {
        sx: {
          position: 'absolute',
          top: '42%',
          left: '45%',
          height: '100%',
          width: '100%',
        },
      }),
    ],
  );
};

export default LoadingMask;
