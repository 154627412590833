import {
  ClientDrillDown,
  PageDataGrid,
  format,
} from '../../../common/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCachedData } from '../../../common/cached_data';
import * as api from '../../../common/api';

const column_specs: GridColDef[] = [
  {
    field: 'client_name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'client_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  { field: 'email', minWidth: 230, align: 'left' },
  { field: 'phone', minWidth: 140, align: 'left' },
  { field: 'bank_account_status', minWidth: 120 },
  {
    field: 'tax_status',
    minWidth: 120,
    valueGetter: ({ value }) =>
      value === 'submitted' ? 'w8_requested' : value,
  },
  {
    field: 'is_nonresident_alien',
    width: 75,
    type: 'boolean',
  },

  { field: 'invoice_external_ref', minWidth: 260 },
  { field: 'payment_processor', width: 100 },
  {
    field: 'payment_amount',
    headerName: '$amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 80,
  },
  {
    field: 'target_payment_date',
    width: 160,
    valueFormatter: format.datetime,
  },

  { field: 'event_name', minWidth: 260 },
  {
    field: 'event_date_local',
    headerName: 'event_date',
    width: 160,
    valueFormatter: format.datetime,
  },
  { field: 'home_team', width: 80 },
  { field: 'away_team', width: 80 },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) =>
      row.seats ? row.seats[0] + '-' + row.seats[row.seats.length - 1] : '',
    width: 80,
  },
  { field: 'skybox_tags', width: 150 },
  {
    field: 'is_free_listing',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
];

const column_groups = [
  { groupId: ' ', children: [{ field: 'SELECT' }] },
  {
    groupId: 'Client info',
    children: [
      { field: 'client_id' },
      { field: 'client_name' },
      { field: 'email' },
      { field: 'phone' },
      { field: 'bank_account_status' },
      { field: 'tax_status' },
      { field: 'is_nonresident_alien' },
    ],
  },
  {
    groupId: 'Inventory',
    children: [
      { field: 'event_name' },
      { field: 'event_date_local' },
      { field: 'home_team' },
      { field: 'away_team' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'skybox_tags' },
      { field: 'is_free_listing' },
      { field: 'is_playoff' },
    ],
  },
  {
    groupId: 'Payment details',
    children: [
      { field: 'invoice_external_ref' },
      { field: 'payment_processor' },
      { field: 'payment_amount' },
      { field: 'target_payment_date' },
    ],
  },
];
export default function OverduePaymentsDataGrid({
  rows,
  reloadData,
}: {
  rows: any[];
  reloadData: (opts: { updateFn?: () => Promise<void> }) => Promise<void>;
}) {
  const { invalidateData: invalidateBulkPaymentsCache } =
    useCachedData('bulk_payments');
  const { invalidateData: invalidatePaymentsCache } = useCachedData('payments');
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  useEffect(() => {
    setRowSelectionModel(rows.map((row) => row.inventory_id));
  }, [rows]);

  const [payment_amount, setPaymentAmount] = useState(0);
  const calculatePaymentAmount = () => {
    let total = 0;
    rowSelectionModel.forEach((id) => {
      const row = rows.find((row) => row.inventory_id === id);
      total += +row.payment_amount;
    });
    setPaymentAmount(total);
  };

  const [showConfirmation, setConfirmation] = useState(false);

  return PageDataGrid({
    id: 'overdue_payments',
    rows,
    getRowId: (row) => row.inventory_id,
    column_specs,
    column_groups,
    filter_specs: [],
    checkboxSelection: true,
    onRowSelectionModelChange: (newRowSelectionModel) => {
      setRowSelectionModel(newRowSelectionModel);
    },
    rowSelectionModel: rowSelectionModel,
    customTopRightComponent: h(Stack, {}, [
      h(Button, {
        onClick: () => {
          calculatePaymentAmount();
          setConfirmation(true);
        },
        variant: 'contained',
        children: 'Initiate selected',
      }),
      h(
        Dialog,
        { open: showConfirmation, onClose: () => setConfirmation(false) },
        [
          h(
            Box,
            {
              sx: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
              },
            },
            [
              h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 3 }, [
                'Initiate approved overdue payments',
              ]),

              h(Stack, { mb: 4, gap: 1, width: '100%' }, [
                h(Typography, { variant: 'body1' }, [
                  `Selected items: ${rowSelectionModel.length}`,
                ]),
                h(Typography, { variant: 'body1' }, [
                  `Total amount: $${payment_amount}`,
                ]),
                h(Typography, { variant: 'body1' }, [`Processor: Dwolla`]),
              ]),

              h(
                'div',
                {
                  sx: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
                [
                  h(
                    Button,
                    {
                      variant: 'contained',
                      sx: { marginX: 2 },
                      color: 'error',
                      onClick: () => setConfirmation(false),
                    },
                    ['Cancel'],
                  ),
                  h(
                    Button,
                    {
                      variant: 'contained',
                      sx: { marginX: 2 },
                      color: 'success',
                      onClick: () => {
                        reloadData({
                          updateFn: () =>
                            api.initiateOverduePayments(
                              rowSelectionModel.map((id) =>
                                parseInt(id as string),
                              ),
                            ),
                        });
                        invalidateBulkPaymentsCache();
                        invalidatePaymentsCache();
                        setConfirmation(false);
                      },
                    },
                    ['Confirm'],
                  ),
                ],
              ),
            ],
          ),
        ],
      ),
    ]),
  });
}
