import h from '../../../lib/react-hyperscript';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { useCachedData } from '../../../common/cached_data';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { FetchPageHeader } from '../../../common/components/FetchPageHeader';
import SingleEventDataGrid from './SingleEventDataGrid';

const SingleEventTicketListPanel = () => {
  const { loadData, error, loading, value, is_expired, invalidateData } =
    useCachedData('single_event_tickets');
  useEffect(() => {
    if (is_expired) loadData();
  }, []);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchPageHeader, {
      header: 'Single Events',
      state: { error, loading },
      doFetch: loadData,
    }),

    h(FetchBackdrop, { state: { error, loading } }),

    h(SingleEventDataGrid, {
      rows: value ?? [],
      onDataUpdated: invalidateData,
    }),
  ]);
};

export default SingleEventTicketListPanel;
