import h from '../../../lib/react-hyperscript';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  lighten,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro/DataGridPro/DataGridPro';
import {
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
} from '@mui/x-data-grid-pro';
import { format } from '../../../common/components';
import { InventoryItem } from '../../../common/types';
import { useParams } from 'react-router-dom';
import { durationDays } from '../../../common/helper';
import { useState } from 'react';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { stringDateTime } from '../../../common/util';

const sales_column_specs: GridColDef[] = [
  {
    field: 'days_listed_before_event',
    width: 100,
    valueGetter: ({ row }) =>
      row.listed
        ? durationDays(new Date(row.listed), new Date(row.event_date_local))
        : null,
    type: 'number',
  },
  stringDateTime({
    field: 'invoice_date',
    headerName: 'sale_date',
    width: 175,
  }),
  {
    field: 'starting_list_price',
    width: 75,
    headerName: 'start_$',
    valueGetter: ({ row }) => row.starting_list_price ?? row.list_price,
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'client_facing_sale_price',
    width: 75,
    headerName: 'cf_sale_$',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'sales_premium',
    width: 75,
    headerName: 'premium_$',
    valueGetter: ({ row }) => {
      if (!row.comp_sale || typeof row.comp_sale.price !== 'number')
        return null;
      return (row.client_facing_sale_price ?? 0) - (row.comp_sale.price ?? 0);
    },
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'subsidy',
    width: 75,
    headerName: 'subsidy_$',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'quantity',
    width: 75,
    headerName: 'quantity',
    valueGetter: ({ row }) => row.skybox.quantity ?? '0',
    type: 'number',
  },

  {
    field: 'marketplace',
    valueGetter: ({ row }) => row.skybox.customerDisplayName,
  },
  {
    field: 'days_listed',
    width: 100,
    valueGetter: ({ row }) =>
      row.listed
        ? durationDays(new Date(row.listed), new Date(row.invoice_date))
        : null,
    type: 'number',
  },
  {
    field: 'skybox.eventDayOfWeek',
    width: 100,
    headerName: 'event_day_of_week',
    valueGetter: ({ row }) => row.skybox.eventDayOfWeek,
    type: 'number',
  },
  {
    field: 'price_change',
    width: 75,
    headerName: 'Δ$',
    valueGetter: ({ row }) =>
      row.starting_list_price
        ? row.client_facing_sale_price - row.starting_list_price
        : null,
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'pct_change',
    width: 75,
    headerName: 'Δ%',
    valueGetter: ({ row }) =>
      row.starting_list_price
        ? (row.client_facing_sale_price - row.starting_list_price) /
          row.starting_list_price
        : null,
    valueFormatter: format.percent,
    type: 'number',
  },
  stringDateTime({
    field: 'event_date_local',
    width: 175,
  }),
  { field: 'league' },
  { field: 'home_team' },
  { field: 'away_team', headerName: 'opponent' },
  { field: 'section' },
  { field: 'row' },
  { field: 'seats' },
];

const SalesRowDetailsPanel = (row: InventoryItem & { metadata?: object }) => {
  return h(
    Stack,
    {
      sx: { py: 2, height: 1, boxSizing: 'border-box' },
    },
    [
      h(
        Paper,
        {
          elevation: 3,
          sx: { flex: 1, mx: 'auto', width: '90%', py: 1, px: 2 },
        },
        [
          h(Stack, { direction: 'column', spacing: 1, sx: { height: 1 } }, [
            h(
              Typography,
              {
                variant: 'subtitle1',
                sx: { display: 'flex', fontWeight: 'bold' },
              },
              ['Inventory ID:', h(Link, { href: `/sales/${row.id}` }, row.id)],
            ),
            h(Grid, { container: true }, [
              h(Grid, { md: 12 }, [
                h(
                  Typography,
                  {
                    variant: 'body2',
                    color: 'gray',
                    sx: { display: 'flex', wordWrap: 'break-word' },
                  },
                  ['Analysis'],
                ),
                h(
                  Typography,
                  {
                    variant: 'body1',
                    sx: { display: 'flex', wordWrap: 'break-word' },
                  },
                  [
                    // @ts-ignore
                    row.metadata?.analysis || 'No analysis',
                  ],
                ),
              ]),
            ]),
          ]),
        ],
      ),
    ],
  );
};

const SalesClientHistory = ({
  client_sales,
}: {
  client_sales: (InventoryItem & { subsidy: number })[];
}) => {
  const { inventory_id } = useParams<{ inventory_id: string }>();
  const id = parseInt(inventory_id as string, 10);
  const cur_sale = client_sales.find((item) => item.id === id);
  const [is_expanded, setExpanded] = useState(true);

  return h(Accordion, {
    sx: { marginY: '0!important' },
    expanded: is_expanded,
    children: [
      h(AccordionSummary, { sx: { cursor: 'default!important' } }, [
        h(
          Grid,
          {
            container: true,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          [
            h(Stack, { direction: 'row', alignItems: 'center' }, [
              h(
                IconButton,
                { size: 'small', onClick: () => setExpanded(!is_expanded) },
                [h(is_expanded ? ExpandLessIcon : ExpandMoreIcon)],
              ),
              h(
                Typography,
                {
                  variant: 'h6',
                  gutterBottom: true,
                  sx: { fontWeight: 'bold', mb: 0 },
                },
                'Sales history',
              ),
            ]),
          ],
        ),
      ]),

      h(AccordionDetails, { sx: { paddingX: 3 } }, [
        h(Grid, { key: 2, xs: 12, sm: 12, lg: 12, height: '100%' }, [
          h(
            Box,
            {
              width: '100%',
              height: '500px',
              sx: {
                '& .current-sale-row': {
                  bgcolor: (theme) => lighten(theme.palette.info.main, 0.6),

                  '&:hover': {
                    bgcolor: (theme) =>
                      `${lighten(theme.palette.info.main, 0.5)}!important`,
                  },
                },
              },
            },
            [
              h(DataGrid, {
                rows: client_sales,
                columns: sales_column_specs,
                pagination: true,
                autoPageSize: true,
                density: 'compact',
                pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] },
                pinnedRows: {
                  top: cur_sale ? [cur_sale] : [],
                },
                getRowClassName: (params) =>
                  params.id === id ? 'current-sale-row' : '',
                // @ts-ignore
                getDetailPanelContent: ({ row }) => SalesRowDetailsPanel(row),
                getDetailPanelHeight: (_) => 'auto',
              }),
            ],
          ),
        ]),
      ]),
    ],
  });
};

export default SalesClientHistory;
