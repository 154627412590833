import h from '../../../lib/react-hyperscript';

import CachedDataPanel from '../../../common/components/CachedDataPanel';
import BulkPaymentsDataGrid from './BulkPaymentsDataGrid';

export function BulkPaymentsPanel() {
  return h(CachedDataPanel, {
    header: 'Payment Batches',
    cache_key: 'bulk_payments',
    componentFn: ({ rows }) =>
      h(BulkPaymentsDataGrid, {
        rows,
      }),
  });
}
