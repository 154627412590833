import h from '../../../lib/react-hyperscript';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stadium as StadiumIcon } from '@mui/icons-material';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { ClientEvent } from '../../../common/types';
import dayjs, { Dayjs } from 'dayjs';
import { useAsyncFn } from 'react-use';
import * as api from '../../../common/api';
import { format } from '../../../common/components';

const SingleEventTable = ({
  events,
  is_loading = false,
  selected,
  onSelected,
}: {
  events: ClientEvent[];
  is_loading: boolean;
  selected: null | ClientEvent;
  onSelected: (item: any) => void;
}) => {
  return h(Box, [
    h(TableContainer, [
      h(
        Table,
        {
          sx: { size: 'small', ariaLabel: 'Events' },
        },
        [
          h(TableHead, [
            h(TableRow, [
              h(
                TableCell,
                {
                  sx: { pr: '0px', pb: '5px', pt: '5px', fontWeight: 'bold' },
                },
                [],
              ),
              h(
                TableCell,
                { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
                'Date',
              ),
              h(
                TableCell,
                { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
                'Name',
              ),
            ]),
          ]),
          h(
            TableBody,
            events.map(
              ({ id, date, name, venue, inv, is_usual_venue, performer }) => {
                const dateStr = date ? format.datetime({ value: date }) : '';

                return h(
                  TableRow,
                  {
                    sx: {
                      opacity: !!inv ? 0.35 : 1,
                      border: !!inv ? '2px dotted' : '',
                      borderColor: !!inv ? 'rgba(0,0,0,0.35)' : '',
                      cursor: !!inv ? 'pointer' : 'inherit',
                    },
                    key: `${id}-${inv ? inv.id : ''}`,
                    onClick: () => {
                      if (!!inv)
                        window.open(`/ticket-packages/${inv?.tp_id}`, '_blank');
                    },
                  },
                  [
                    h(
                      TableCell,
                      {
                        sx: {
                          pr: '0px',
                          pb: '5px',
                          pt: '5px',
                          fontWeight: 'bold',
                        },
                      },
                      [
                        !inv
                          ? h(Checkbox, {
                              sx: { p: 0 },
                              color: 'primary',
                              checked: id === selected?.id,
                              disabled: !!inv,
                              onChange: (e) => {
                                onSelected(
                                  id !== selected?.id
                                    ? {
                                        id,
                                        date,
                                        name,
                                        venue,
                                        is_usual_venue,
                                        performer,
                                      }
                                    : null,
                                );
                              },
                            })
                          : null,
                      ],
                    ),
                    h(
                      TableCell,
                      {
                        component: 'th',
                        scope: 'row',
                        sx: { pb: '5px', pt: '5px', width: '150px' },
                      },
                      dateStr ?? '',
                    ),

                    h(TableCell, { sx: { pb: '5px', pt: '5px' } }, [
                      h(Box, name),
                      h(
                        Stack,
                        {
                          direction: 'row',
                          sx: {
                            alignItems: 'center',
                            width: 'fit-content',
                          },
                        },
                        [
                          h(StadiumIcon, {
                            sx: {
                              color: 'gray',
                              width: '18px',
                              height: '18px',
                              mr: 1,
                            },
                          }),
                          h(
                            Box,
                            `${venue?.name}, ${venue?.city}, ${venue?.state}`,
                          ),
                        ],
                      ),
                    ]),
                  ],
                );
              },
            ),
          ),
        ],
      ),
    ]),
    is_loading
      ? h(
          Stack,
          {
            direction: 'row',
            py: 2,
            sx: { alignItems: 'center', justifyContent: 'center', gap: 2 },
          },
          [
            h(CircularProgress, { size: 30, thickness: 5 }),
            'Searching events from skybox',
          ],
        )
      : '',
    !events.length && !is_loading
      ? h(
          Stack,
          {
            sx: {
              pb: '5px',
              pt: '5px',
              display: 'flex',
              justify_content: 'center',
            },
          },
          'There are no events available.',
        )
      : '',
  ]);
};

export default function EventFinder({
  init_data,
  onNext,
  display,
  selected_event,
  setSelectedEvent,
}: {
  init_data: {
    default_search_text?: string;
    default_search_date?: string;
  };
  onNext: () => void;
  display?: string;
  selected_event: ClientEvent | null;
  setSelectedEvent: (event: ClientEvent | null) => void;
}) {
  const [events, searchEvents] = useAsyncFn(api.getSkyboxEvents);
  const navigate = useNavigate();
  const [date_range, setDateRange] = React.useState<DateRange<Dayjs>>([
    init_data.default_search_date
      ? dayjs(init_data.default_search_date)
      : dayjs(),
    null,
  ]);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const keywords = data.get('event_keywords');
    // search events
    const [from, to] = date_range;
    const from_date = !!from ? dayjs(from as Dayjs).toDate() : null;
    const to_date = !!to ? dayjs(to as Dayjs).toDate() : null;
    if (keywords) searchEvents(keywords as string, from_date, to_date);
  };

  return h(
    Box,
    {
      key: 'event-finder',
      component: 'form',
      onSubmit: handleSubmit,
      noValidate: true,
      sx: { display },
    } as any,
    [
      h(
        Grid,
        {
          container: true,
          spacing: 2,
          padding: 0,
          sx: { width: '100%' },
        },
        [
          h(Grid, { xs: 10 }, [
            h(TextField, {
              fullWidth: true,
              id: 'search-events',
              label: 'Search Events',
              name: 'event_keywords',
              defaultValue: init_data.default_search_text ?? '',
            }),
          ]),

          h(Grid, { xs: 2 }, [
            h(
              Button,
              {
                key: 'search',
                type: 'submit',
                variant: 'contained',
                sx: { width: '100%' },
                disabled: false,
              },
              'Search',
            ),
          ]),
        ],
      ),
      h(
        Grid,
        {
          container: true,
          spacing: 2,
          padding: 0,
          sx: { width: '100%', my: 1 },
        },
        [
          h(Grid, { xs: 10 }, [
            h(LocalizationProvider, { dateAdapter: AdapterDayjs }, [
              h(DateRangePicker, {
                label: 'Date Range',
                value: date_range,
                onChange: (newValue) => {
                  setDateRange(newValue as DateRange<Dayjs>);
                },
                localeText: { start: 'From', end: 'To' },
                sx: { width: '100%' },
              }),
            ]),
          ]),

          h(Grid, { xs: 2 }, [
            h(
              Button,
              {
                key: 'Reset',
                type: 'reset',
                variant: 'outlined',
                sx: { width: '100%' },
                disabled: false,
                onClick: () => {
                  setDateRange([dayjs(), null]);
                  setSelectedEvent(null);
                  events.value = [];
                },
              },
              'Reset',
            ),
          ]),
        ],
      ),
      h('div', { style: { maxHeight: '60vh', overflowY: 'scroll' } }, [
        SingleEventTable({
          events: events.value || [],
          is_loading: events.loading,
          selected: selected_event,
          onSelected: setSelectedEvent,
        }),
      ]),

      h(
        Stack,
        {
          direction: 'row',
          alignItems: 'baseline',
          justifyContent: 'center',
          spacing: 2,
          sx: { width: '100%', mt: 3 },
        },

        [
          h(
            Button,
            {
              key: 'cancel',
              variant: 'outlined',
              sx: { width: '100%' },
              onClick: () => {
                navigate(`/single-event`);
                return false;
              },
            },
            'Cancel',
          ),
          h(
            Button,
            {
              key: 'next',
              variant: 'contained',
              sx: { width: '100%' },
              disabled: !selected_event,
              onClick: onNext,
            },
            'Next',
          ),
        ],
      ),
    ],
  );
}
