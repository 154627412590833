import h from '../../lib/react-hyperscript';
import SingleEventPanel from './component/SingleEventPanel';
import SingleEventListPanel from './component/SingleEventListPanel';
import AddSingleEventInventoryPanel from './component/AddSingleEventInventoryPanel';

const routing = {
  element: h(SingleEventPanel),
  children: [
    {
      path: '',
      element: h(SingleEventListPanel),
    },
    {
      path: 'add',
      element: h(AddSingleEventInventoryPanel),
    },
  ],
};

export { routing };
