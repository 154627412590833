import h from '../../../lib/react-hyperscript';
import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { useAsyncFn } from 'react-use';
import InventoryHistoryDataGrid from './InventoryHistoryDataGrid';
import { getInventoryPriceHistory } from '../../../common/api';
import { InventoryItem } from '../../../common/types';
import { useBaseData } from '../../../common/base_data';

const fetchFunc = async (id: number) => {
  return await getInventoryPriceHistory(id);
};

const InventoryCard = ({ inv }: { inv: InventoryItem }) => {
  const { baseData } = useBaseData();

  const client = baseData.getClient(inv.client_id);

  return h(Stack, { direction: 'row', spacing: 2, mt: 2, mb: 2 }, [
    // display inventory owner, serction, row and seat
    h(Typography, `${client?.first_name} ${client?.last_name}`),
    h(Typography, inv.event_name),
    h(Typography, inv.event_date_local),
    h(Typography, `sec:${inv.section} row:${inv.row} seats:${inv.seats}`),
  ]);
};

export default function InventoryHistoryPanel() {
  const params = useParams<{ id: string }>();
  const id = parseInt(params.id as string, 10);
  const [state, doFetch] = useAsyncFn(fetchFunc);

  useEffect(() => {
    doFetch(id);
  }, [id, doFetch]);

  const { inv_item, history } = state?.value ?? {};

  return h(
    Stack,
    {
      direction: 'column',
      flexGrow: 1,
    },
    [
      h(
        Typography,
        { variant: 'h5', fontWeight: 'bold' },
        'Inventory Price History',
      ),
      inv_item
        ? h(Stack, { direction: 'row', justifyContent: 'center' }, [
            h(InventoryCard, { inv: inv_item }),
          ])
        : null,

      h(FetchBackdrop, { state }),

      h(InventoryHistoryDataGrid, {
        rows: history ?? [],
      }),
    ],
  );
}
