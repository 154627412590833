import h from '../../../lib/react-hyperscript';
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import ClientsDataGrid from './ClientsDataGrid';
import { FetchPageHeader } from '../../../common/components/FetchPageHeader';
import { useCachedData } from '../../../common/cached_data';

export default function ClientsList() {
  const { loadData, error, loading, value, is_expired, invalidateData } =
    useCachedData('clients');
  useEffect(() => {
    if (is_expired) loadData();
  }, []);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchPageHeader, {
      header: 'Clients',
      state: { error, loading },
      doFetch: loadData,
    }),

    h(FetchBackdrop, { state: { error, loading } }),

    h(ClientsDataGrid, {
      rows: value ?? [],
      onDataUpdated: invalidateData,
    }),
  ]);
}
