import {
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import h from '../../../lib/react-hyperscript';
import { LeagueId } from '../../../common/types';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { Info as InfoIcon, Circle as CircleIcon } from '@mui/icons-material';

const column_specs: GridColDef[] = [
  {
    field: 'league',
    align: 'right',
    minWidth: 75,
    headerName: 'League',
  },
  {
    field: 'start_year',
    align: 'right',
    minWidth: 75,
    headerName: 'Season',
  },
  {
    field: 'start_confirmation_date',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    minWidth: 160,
    headerName: 'Start confirmation date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'start_selling_season_date',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    minWidth: 160,
    headerName: 'Schedule release date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'end_regular_season_date',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    minWidth: 180,
    headerName: 'End regular season date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'season_cutoff',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    minWidth: 180,
    headerName: 'End selling season date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'end_confirmation_date',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    minWidth: 150,
    headerName: 'End confirmation date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'end_skybox_date',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    minWidth: 150,
    headerName: 'End skybox date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'is_current_season',
    type: 'boolean',
    minWidth: 150,
  },
  {
    field: 'in_selling_season',
    type: 'boolean',
    minWidth: 150,
  },
  {
    field: 'in_playoff_season',
    type: 'boolean',
    minWidth: 150,
  },
];
const column_groups = [
  {
    groupId: 'Details',
    children: [
      { field: 'league' },
      { field: 'start_year' },
      { field: 'start_confirmation_date' },
      { field: 'start_selling_season_date' },
      { field: 'end_regular_season_date' },
      { field: 'season_cutoff' },
      { field: 'end_confirmation_date' },
      { field: 'end_skybox_date' },
    ],
    renderHeaderGroup: () =>
      h(Tooltip, {
        sx: {
          width: '400px',
        },
        title: h(Stack, {}, [
          h(Box, {}, [
            h(
              'div',
              {
                sx: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                },
              },
              [
                h('span', { style: { fontWeight: 'bold' } }, [
                  h(CircleIcon, {
                    sx: { fontSize: '6px', marginRight: '1px' },
                  }),
                  'Start confirmation date: ',
                ]),
                h('span', {}, [
                  'ticket packages created after this date will be assigned to this season.',
                ]),
              ],
            ),
          ]),
          h(Box, {}, [
            h(
              'div',
              {
                sx: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                },
              },
              [
                h('span', { style: { fontWeight: 'bold' } }, [
                  h(CircleIcon, {
                    sx: { fontSize: '6px', marginRight: '1px' },
                  }),
                  'Schedule release date: ',
                ]),
                h('span', {}, [
                  'day the schedule is released. Also the start of selling season.',
                ]),
              ],
            ),
          ]),
          h(Box, {}, [
            h(
              'div',
              {
                sx: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                },
              },
              [
                h('span', { style: { fontWeight: 'bold' } }, [
                  h(CircleIcon, {
                    sx: { fontSize: '6px', marginRight: '1px' },
                  }),
                  'End regular season date: ',
                ]),
                h('span', {}, ['the last day of regular season.']),
              ],
            ),
          ]),
          h(Box, {}, [
            h(
              'div',
              {
                sx: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                },
              },
              [
                h('span', { style: { fontWeight: 'bold' } }, [
                  h(CircleIcon, {
                    sx: { fontSize: '6px', marginRight: '1px' },
                  }),
                  'End selling season date: ',
                ]),
                h('span', {}, [
                  'date at which selling season ends, i.e. end of playoffs.',
                ]),
              ],
            ),
          ]),
          h(Box, {}, [
            h(
              'div',
              {
                sx: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 1,
                },
              },
              [
                h('span', { style: { fontWeight: 'bold' } }, [
                  h(CircleIcon, {
                    sx: { fontSize: '6px', marginRight: '1px' },
                  }),
                  'End confirmation date: ',
                ]),
                h('span', {}, [
                  'ticket packages created after this date will be assigned to the next season.',
                ]),
              ],
            ),
          ]),
        ]),
        placement: 'right',
        children: h(
          Box,
          {
            sx: {
              display: 'flex',
              justifyContent: 'between',
              alignItems: 'center',
              gap: 1,
            },
          },
          [
            h(Typography, { sx: { fontSize: 14 } }, 'Details'),
            h(InfoIcon, { color: 'action', fontSize: 'small' }),
          ],
        ),
      }),
  },

  {
    groupId: 'Status',
    children: [
      { field: 'is_current_season' },
      { field: 'in_selling_season' },
      { field: 'in_playoff_season' },
    ],
  },
];
const filter_specs: QuickFilterSpec[] = [
  {
    id: 'league',
    label: 'League',
    optionValues: [
      { label: LeagueId.NFL, value: LeagueId.NFL },
      { label: LeagueId.NBA, value: LeagueId.NBA },
      { label: LeagueId.NHL, value: LeagueId.NHL },
      { label: LeagueId.MLB, value: LeagueId.MLB },
      { label: LeagueId.MLS, value: LeagueId.MLS },
      { label: LeagueId.NCAAB, value: LeagueId.NCAAB },
      { label: LeagueId.NCAAF, value: LeagueId.NCAAF },
    ],
  },
  { id: 'is_current_season', label: 'Current season?', yesNoAny: true },
];

export default function SeasonDataGrid({ rows }: { rows: any[] }) {
  return PageDataGrid({
    id: 'seasons',
    rows,
    column_specs,
    column_groups,
    filter_specs,
    hide_client_filter: true,
  });
}
