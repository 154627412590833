import h from '../../../lib/react-hyperscript';
import { QualifyStatus } from '../../../common/types';
import { Stack, Button, Chip, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import { SimpleSelect } from '../../../common/components';

const optionValues = [
  {
    label: 'Pending',
    value: QualifyStatus.PENDING,
  },
  {
    label: 'Incorrect Info',
    value: QualifyStatus.INCORRECT_INFO,
  },
  {
    label: 'Qualified',
    value: QualifyStatus.QUALIFIED,
  },
  {
    label: 'Unqualified',
    value: QualifyStatus.NOT_QUALIFIED,
  },
];

export default function TicketPackageStatusControls({
  qualify_status,
  client_name,
  onUpdate,
  onIsEditing = () => {},
}: {
  qualify_status: QualifyStatus;
  client_name: string;
  onUpdate: (qs: QualifyStatus) => void;
  onIsEditing?: (isEditing: boolean) => void;
}) {
  const [is_edit_mode, setIsEditMode] = useState(false);
  const [new_qualify_status, setNewQualifyStatus] = useState(qualify_status);

  const handleClickChange = () => {
    setIsEditMode(true);
  };

  const handleClickCancel = () => {
    setNewQualifyStatus(qualify_status);
    setIsEditMode(false);
  };

  const handleClickSave = () => {
    setIsEditMode(qualify_status === null || false);
    onUpdate(new_qualify_status as QualifyStatus);
  };

  useEffect(() => {
    onIsEditing(is_edit_mode);
  }, [is_edit_mode, onIsEditing]);

  const chipCommon = {
    variant: 'outlined' as 'outlined',
    sx: { fontSize: '16px' },
  };

  const qualify_status_chip = {
    [QualifyStatus.PENDING]: h(Chip, {
      ...chipCommon,
      label: 'Pending',
    }),
    [QualifyStatus.INCORRECT_INFO]: h(Chip, {
      ...chipCommon,
      label: 'Incorrect Info',
      color: 'warning',
    }),
    [QualifyStatus.QUALIFIED]: h(Chip, {
      ...chipCommon,
      label: 'Qualified',
      color: 'success',
    }),
    [QualifyStatus.NOT_QUALIFIED]: h(Chip, {
      ...chipCommon,
      label: 'Unqualified',
      variant: 'filled',
      color: 'error',
    }),
  }[new_qualify_status ?? qualify_status];

  return h(Stack, [
    h(
      Stack,
      {
        direction: 'row',
        alignItems: 'baseline',
        justifyContent: 'center',
        sx: { width: '100%', height: '40px' },
      },
      [
        h(
          Stack,
          {
            direction: 'row',
            justifyContent: 'end',
            sx: { width: '100%' },
          },
          is_edit_mode
            ? [
                h(SimpleSelect<QualifyStatus>, {
                  autoFocus: true,
                  disabled: !is_edit_mode,
                  optionValues,
                  fullWidth: true,
                  sx: { mt: -1 },
                  label: '',
                  name: 'league_id',
                  defaultValue: new_qualify_status,
                  onChange: (val) => setNewQualifyStatus(val),
                }),
              ]
            : [qualify_status_chip],
        ),
        h(
          Stack,
          {
            direction: 'row',
            justifyContent: 'start',
            sx: { width: '100%' },
          },
          is_edit_mode
            ? [
                h(
                  Button,
                  {
                    key: 'cancel',
                    variant: 'outlined',
                    sx: { ml: 2, width: '90px' },
                    onClick: () => handleClickCancel(),
                  },
                  'Cancel',
                ),
                h(
                  Button,
                  {
                    key: 'save',
                    variant: 'contained',
                    sx: { ml: 2, width: '90px' },
                    onClick: handleClickSave,
                  },
                  'Save',
                ),
              ]
            : [
                h(
                  Button,
                  { key: 'edit', sx: { ml: 2 }, onClick: handleClickChange },
                  'Change',
                ),
              ],
        ),
      ],
    ),
    is_edit_mode &&
    new_qualify_status === QualifyStatus.NOT_QUALIFIED &&
    new_qualify_status !== qualify_status
      ? h(
          Alert,
          { severity: 'warning', sx: { mt: 2 } },
          `${client_name} will be automatically notified that their package does not qualify.`,
        )
      : is_edit_mode &&
        new_qualify_status === QualifyStatus.QUALIFIED &&
        qualify_status === QualifyStatus.NOT_QUALIFIED
      ? h(
          Alert,
          { severity: 'warning', sx: { mt: 2 } },
          `${client_name} was previously notified that their package does not qualify.`,
        )
      : null,
  ]);
}
