import h from '../../../lib/react-hyperscript';
import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SimpleSelect } from '../../../common/components';
import { EventStatus, LeagueId, PlayoffGameEx } from '../../../common/types';
import { default as React, useState } from 'react';
import { useBaseData } from '../../../common/base_data';

const PlayoffGameEditor = ({
  lpg,
  onCancel,
  onSave,
}: {
  lpg?: PlayoffGameEx;
  onCancel: () => void;
  onSave: (_: {
    id?: number;
    playoff_round_id: number;
    home_team_id: number;
    status: EventStatus;
  }) => void;
}) => {
  const [league_id, setLeague] = useState(lpg?.league ?? LeagueId.NFL);

  const {
    baseData: {
      getLeagueIds,
      getTeamsForLeague,
      getPlayoffRoundsForCurrenLeague,
    },
  } = useBaseData();
  const teams = getTeamsForLeague(league_id);
  const available_playoff_rounds = getPlayoffRoundsForCurrenLeague(league_id);

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const playoff_round_id = data.get('playoff_round_id') as string;
    const home_team_id = data.get('home_team_id') as string;
    const event_status = data.get('event_status') as EventStatus;

    if (playoff_round_id && home_team_id) {
      onSave({
        id: lpg?.id,
        playoff_round_id: parseInt(playoff_round_id),
        home_team_id: parseInt(home_team_id),
        status: event_status,
      });
      onCancel();
    }
  };

  return h(
    Box,
    {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 3,
      },
    },
    [
      h(
        Box,
        {
          sx: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        },

        [
          h(Typography, { variant: 'h5', fontWeight: 'bold', mb: 4, pt: 2 }, [
            `${lpg?.id ? 'Edit' : 'Add'} Playoff Round`,
          ]),
          h(
            Grid,
            {
              container: true,
              spacing: 12,
              columns: { xs: 1, md: 1 },
              sx: { maxWidth: '500px' },
            },

            [
              h(Grid, { key: 1, xs: 1 }, [
                h(
                  Box,
                  { pb: 2, component: 'form', onSubmit: handleSave } as any,
                  [
                    h(SimpleSelect, {
                      options: getLeagueIds(),
                      sx: { mt: 2 },
                      fullWidth: true,
                      label: 'League',
                      name: 'league_id',
                      defaultValue: league_id ?? undefined,
                      onChange: (id) => setLeague(id as LeagueId),
                    }),

                    h(SimpleSelect, {
                      optionValues: teams
                        ? teams.map((t) => ({
                            label: t.name,
                            value: t.id,
                          }))
                        : [],
                      sx: { mt: 3, mb: 1 },
                      fullWidth: true,
                      label: 'Home Team',
                      name: 'home_team_id',
                      defaultValue: lpg?.home_team_id ?? undefined,
                    }),

                    h(SimpleSelect, {
                      optionValues: available_playoff_rounds
                        ? available_playoff_rounds.map((r) => ({
                            label: r.league_name,
                            value: r.id,
                          }))
                        : [],
                      sx: { mt: 3, mb: 1 },
                      fullWidth: true,
                      label: 'Playoff Round',
                      name: 'playoff_round_id',
                      defaultValue: lpg?.playoff_round_id ?? undefined,
                    }),

                    h(SimpleSelect, {
                      optionValues: [
                        { value: 'pending', label: 'Pending' },
                        { value: 'listable', label: 'Listable' },
                        { value: 'not_happening', label: 'Not happening' },
                        { value: 'completed', label: 'Completed' },
                      ],
                      sx: { mt: 3, mb: 1 },
                      fullWidth: true,
                      label: 'Event status',
                      name: 'event_status',
                      defaultValue: lpg?.status ?? undefined,
                    }),

                    h(
                      Stack,
                      {
                        direction: 'row',
                        alignItems: 'baseline',
                        justifyContent: 'center',
                        spacing: 2,
                        sx: { width: '100%', mt: 3 },
                      },

                      [
                        h(
                          Button,
                          {
                            variant: 'outlined',
                            sx: { width: '100%' },
                            onClick: () => {
                              onCancel();
                              return false;
                            },
                          },
                          'Cancel',
                        ),
                        h(
                          Button,
                          {
                            key: 'submit',
                            type: 'submit',
                            variant: 'contained',
                            sx: { width: '100%' },
                          },
                          'Save',
                        ),
                      ],
                    ),
                  ],
                ),
              ]),
            ],
          ),
        ],
      ),
    ],
  );
};

export default PlayoffGameEditor;
