const openDatabase = (store_name: string, version?: number) => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open('tiqassist_occ', version);

    request.onupgradeneeded = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(store_name)) {
        db.createObjectStore(store_name);
      }
    };

    request.onsuccess = function () {
      const db = request.result;
      if (!db.objectStoreNames.contains(store_name)) {
        db.close();
        resolve(openDatabase(store_name, db.version + 1));
      } else {
        resolve(db);
      }
    };

    request.onerror = function () {
      reject(request.error);
    };
  });
};

// db.objectStore is key-value store
// The practice of using the key-value store:
// {
//  data: any;
//  created: Date;
// }

const saveToDatabaseStore = async (
  store_name: string,
  key: string,
  value: any,
) => {
  const db = await openDatabase(store_name);
  const transaction = db.transaction(store_name, 'readwrite');
  const store = transaction.objectStore(store_name);
  return new Promise((resolve, reject) => {
    const request = store.put(value, key);
    request.onsuccess = resolve;
    request.onerror = reject;
    db.close();
  });
};

const getFromDatabaseStore = async (store_name: string, key: string) => {
  const db = await openDatabase(store_name);
  const transaction = db.transaction(store_name, 'readonly');
  const store = transaction.objectStore(store_name);
  return new Promise((resolve, reject) => {
    const request = store.get(key);
    request.onsuccess = () => resolve(request.result);
    request.onerror = reject;
    db.close();
  });
};

const clearDatabaseStore = async (store_name: string) => {
  const db = await openDatabase(store_name);
  const transaction = db.transaction(store_name, 'readwrite');
  const store = transaction.objectStore(store_name);
  return new Promise((resolve, reject) => {
    const request = store.clear();
    request.onsuccess = resolve;
    request.onerror = reject;
    db.close();
  });
};

export { saveToDatabaseStore, getFromDatabaseStore, clearDatabaseStore };
