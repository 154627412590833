import h from '../../lib/react-hyperscript';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Box,
} from '@mui/material';
import { Menu as MenuIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import React from 'react';

export default function AppBar({
  onToggleDrawer,
}: {
  onToggleDrawer: () => void;
  onLogout: () => void;
}) {
  const { user, isLoading, logout, isAuthenticated } = useAuth0();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return h(MuiAppBar, { position: 'sticky' }, [
    h(Toolbar, { variant: 'dense' }, [
      h(
        IconButton,
        {
          edge: 'start',
          color: 'inherit',
          'aria-label': 'open drawer',
          onClick: onToggleDrawer,
          sx: {
            marginRight: '36px',
          },
        },
        [h(MenuIcon)],
      ),
      h(
        Typography,
        {
          variant: 'h6',
          color: 'inherit',
          noWrap: true,
          sx: { flexGrow: 1 },
        },
        'Operations Command Center',
      ),
      h(LoadingButton, { loading: isLoading }, [
        h(Avatar, {
          alt: 'setting',
          src: user?.picture,
          onClick: (e) => {
            if (isAuthenticated) handleOpenUserMenu(e);
          },
        }),
        h(Menu, {
          sx: { mt: '30px', zIndex: 10000 },
          id: 'user-menu-appbar',
          anchorEl: anchorElUser,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          keepMounted: true,
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          open: Boolean(anchorElUser),
          onClose: handleCloseUserMenu,
          children: [
            h(Box, { key: 'user-info', width: 250 }, [
              [
                h(
                  Typography,
                  { key: 'user-email', textAlign: 'center', fontSize: 13 },
                  `${user?.email}`,
                ),
                h(
                  Typography,
                  {
                    key: 'user-fullname',
                    textAlign: 'center',
                    fontSize: 20,
                    lineHeight: '40px',
                  },
                  `Hi, ${user?.given_name}!`,
                ),
              ],
            ]),
            h(Divider, { key: 'divider-1' }),
            h(
              MenuItem,
              {
                key: 'user-logout',
                sx: {
                  display: 'flex',
                  gap: 2,
                  paddingX: '20px',
                },
                onClick: () =>
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  }),
              },
              [
                h(LogoutIcon),
                h(Typography, { textAlign: 'center' }, 'Log out'),
              ],
            ),
          ],
        }),
      ]),
    ]),
  ]);
}
