import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import { Drawer } from './drawer';
import { AppBar } from './app-bar';
import { Alert, AlertColor, Box, Snackbar, Stack } from '@mui/material';
import {
  setAccessToken,
  getTicketPackages,
  getPlayoffTicketPackages,
  getJson,
  getClients,
  getTicketPackagesPricing,
  getEventsPricing,
  getTeamsPricing,
  getInventoryPricing,
  getPayments,
  getBulkPayments,
} from './common/api';
import LoginDialog from './app-bar/components/LoginDialog';
import { useAsyncFn } from 'react-use';
import {
  fetchBaseData as _fetchBaseData,
  BaseDataContext,
} from './common/base_data';
import { AppContext } from './common/store/context';
import h from './lib/react-hyperscript';
import { Outlet, useNavigate } from 'react-router-dom';
import { InitCachedDataModule, CachedDataContext } from './common/cached_data';

const App = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [notification, setNotification] = React.useState({
    open: false,
    severity: 'info' as AlertColor,
    msg: '',
  });
  const [baseData, fetchBaseData] = useAsyncFn(_fetchBaseData);
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();

  // data state management
  const ticket_packages_store = InitCachedDataModule(getTicketPackages);
  const playoff_ticket_packages_store = InitCachedDataModule(
    getPlayoffTicketPackages,
  );
  const sales_store = InitCachedDataModule(() => getJson('/sales'));
  const client_store = InitCachedDataModule(getClients);
  const single_event_ticket_store = InitCachedDataModule(() =>
    getJson('/single-events'),
  );
  const ticket_package_pricing_store = InitCachedDataModule(
    getTicketPackagesPricing,
    3600,
    false,
    {
      module_name: 'ticket_package_pricing',
      is_enabled: true,
    },
  );
  const event_pricing_store = InitCachedDataModule(
    getEventsPricing,
    3600,
    false,
    {
      module_name: 'event_pricing',
      is_enabled: true,
    },
  );
  const team_pricing_store = InitCachedDataModule(
    getTeamsPricing,
    3600,
    false,
    {
      module_name: 'team_pricing',
      is_enabled: true,
    },
  );
  const inventory_pricing_store = InitCachedDataModule(
    getInventoryPricing,
    3600,
    true,
    {
      module_name: 'inventory_pricing',
      is_enabled: true,
    },
  );
  const payments_store = InitCachedDataModule(getPayments);
  const bulk_payments_store = InitCachedDataModule(getBulkPayments);

  const path = window.location.pathname.split('/').filter((p) => p !== '');

  if (!path[0]) {
    window.location.href = '/ticket-packages';
  }

  if (baseData.error) {
    console.error(baseData.error);
  }

  React.useEffect(() => {
    const is_redirect_loggin = window.location.search.includes(
      'redirect_login=true',
    );
    if (is_redirect_loggin && !isAuthenticated) {
      loginWithRedirect();
    }
  }, []);

  React.useEffect(() => {
    getAccessTokenSilently()
      .then((value) => {
        setAccessToken(value);
        fetchBaseData();
      })
      .catch((err) => {
        console.log(err);
      });

    // periodictly get access token, SDK will refresh the token when its lifetime < 30s
    const intervalId = setInterval(() => {
      getAccessTokenSilently().then((value) => {
        setAccessToken(value);
      });
    }, 1000 * 30);
    return () => clearInterval(intervalId);
  }, [fetchBaseData]);

  return h(
    Stack,
    {
      direction: 'column',
      className: 'App',
      sx: { textAlign: 'center', minHeight: '100vh' },
    },
    [
      h(
        AppContext.Provider,
        {
          value: {
            notification: notification,
            setNotification: setNotification,
          },
        },
        [
          h(
            Snackbar,
            {
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              open: notification.open,
              onClose: () =>
                setNotification({
                  open: false,
                  severity: notification.severity,
                  msg: '',
                }),
              autoHideDuration: 3500,
              sx: { zIndex: 9999, marginTop: '35px' },
            },
            [h(Alert, { severity: notification.severity }, [notification.msg])],
          ),

          h(
            Box,
            {
              sx: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 5000,
              },
            },
            [
              h(AppBar, {
                onToggleDrawer: () => setDrawerOpen(!drawerOpen),
                onLogout: () => {
                  window.location.href = '/';
                },
              }),
            ],
          ),

          !isAuthenticated
            ? h(LoginDialog, {
                open: true,
                onLoggedIn: () => {
                  window.location.href = '/';
                },
              })
            : baseData.value
            ? h(
                BaseDataContext.Provider,
                { value: { baseData: baseData.value, fetchBaseData } },
                [
                  h(
                    Stack,
                    {
                      direction: 'column',
                      flexGrow: 1,
                      sx: { margin: 2, marginTop: '70px' },
                    },
                    [
                      h(
                        CachedDataContext.Provider,
                        {
                          value: {
                            ticket_packages: ticket_packages_store,
                            playoff_ticket_packages:
                              playoff_ticket_packages_store,
                            sales: sales_store,
                            clients: client_store,
                            single_event_tickets: single_event_ticket_store,
                            ticket_package_pricing:
                              ticket_package_pricing_store,
                            event_pricing: event_pricing_store,
                            team_pricing: team_pricing_store,
                            inventory_pricing: inventory_pricing_store,
                            payments: payments_store,
                            bulk_payments: bulk_payments_store,
                          },
                        },
                        [h(Outlet)],
                      ),
                    ],
                  ),
                  h(Drawer, {
                    open: drawerOpen,
                    onMenuClicked: (id: string) => {
                      setDrawerOpen(false);
                      navigate('/' + id);
                    },
                    onClose: () => setDrawerOpen(false),
                  }),
                ],
              )
            : null,
        ],
      ),
    ],
  );
};

export default App;
