import h from '../../../lib/react-hyperscript';
import * as _ from 'ramda';
import { BaseDataProvider, useBaseData } from '../../../common/base_data';
import * as api from '../../../common/api';
import {
  ClientDrillDown,
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  ConversionStage,
  LeagueId,
  QualifyStatus,
} from '../../../common/types';
import DataGridEntryButton from '../../../common/components/DataGridEntryButton';
import DataGridNoteColumn from './DataGridNoteColumn';
import DataGridCustomColumn from './DataGridCustomColumn';
import { Box, Tooltip, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { stringDateTime } from '../../../common/util';
import DataGridNoteColumnV2 from '../../../common/components/DataGridNoteColumnV2';
import { toCommissionStr } from '../../../common/helper';

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Package Details',
    children: [
      { field: 'season' },
      { field: 'ac_deal_id' },
      { field: 'created' },
      { field: 'client_name' },
      { field: 'client_id' },
      { field: 'ac_contact_id' },
      { field: 'is_client' },
      { field: 'is_playoff' },
      { field: 'league' },
      { field: 'team_name' },
      { field: 'section' },
      { field: 'row' },
      { field: 'seats' },
      { field: 'submission_note' },
      { field: 'sth_status' },
      { field: 'commission_tier' },
      { field: 'commission' },
      { field: 'is_commission_reviewed' },
      { field: 'is_payment_plan' },
      { field: 'stock_type' },
      { field: 'commission_experiment' },
    ],
  },

  {
    groupId: 'First Listing',
    children: [
      { field: 'is_marketplace_verified' },
      { field: 'first_broadcasted' },
    ],
  },

  {
    groupId: 'Contact Info',
    children: [{ field: 'email' }, { field: 'phone' }],
  },

  {
    groupId: 'Dashboard Setup',
    children: [
      { field: 'qualification' },
      { field: 'is_priced' },
      { field: 'is_awaiting_tickets' },
      { field: 'is_shown' },
      { field: 'is_archived' },
      { field: 'is_price_review_requested' },
      { field: 'first_shown' },
    ],
  },

  {
    groupId: 'Activity',
    children: [
      { field: 'conversion_stage' },
      { field: 'client_registered' },
      { field: 'client_last_seen' },
      { field: 'client_first_listed' },
      { field: 'first_listed' },
      { field: 'last_listed' },
      { field: 'last_sold' },
      { field: 'first_sold' },
      { field: 'customer_third_sale' },
      { field: 'no_tp_next_season' },
    ],
  },

  {
    groupId: 'Credentials',
    freeReordering: true,
    children: [
      { field: 'creds_status' },
      { field: 'creds_username' },
      { field: 'creds_status_changed' },
      { field: 'revalidate_creds' },
    ],
  },

  {
    groupId: 'Stats',
    children: [
      { field: 'client_league_sold_amount' },
      { field: 'deal_value' },
      { field: 'median_game_value' },
      { field: 'min_game_value' },
      { field: 'pct_seats_sold' },
      { field: 'seats_sold' },
      { field: 'pct_seats_sold_listed' },
      { field: 'seats_sold_or_listed' },
      { field: 'pct_seats_not_listed' },
      { field: 'seats_not_listed' },
      { field: 'sold_amount' },
      { field: 'sold_or_listed_amount' },
      { field: 'unlisted_amount' },
      { field: 'avg_days_listed' },
      { field: 'avg_starting_list_price' },
      { field: 'avg_current_list_price' },
      { field: 'avg_listing_days_to_event' },
    ],
  },

  {
    groupId: 'Note',
    children: [
      { field: 'note' },
      { field: 'client_note' },
      { field: 'barcode_note' },
    ],
  },
];

const column_specs: GridColDef[] = [
  {
    field: 'id',
    align: 'center',
    width: 80,
    resizable: false,
    hideable: false,
    pinnable: false,
    renderCell: (params) =>
      DataGridEntryButton(
        params,
        `/ticket-packages/${params.id}`,
        params.row.is_playoff ? { color: 'warning.main' } : {},
      ),
  },
  { field: 'is_marketplace_verified', width: 150, type: 'boolean' },
  {
    field: 'first_broadcasted',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },

  { field: 'email', minWidth: 230, align: 'left' },
  { field: 'phone', minWidth: 140, align: 'left' },

  {
    field: 'qualification',
    minWidth: 120,
  },
  {
    field: 'is_priced',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_awaiting_tickets',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_shown',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_archived',
    width: 75,
    type: 'boolean',
  },
  stringDateTime({
    field: 'first_shown',
    minWidth: 175,
  }),
];

const tp_activity_columns: GridColDef[] = [
  {
    headerName: 'stage',
    field: 'conversion_stage',
    minWidth: 120,
  },
  stringDateTime({
    field: 'client_registered',
    minWidth: 175,
  }),
  stringDateTime({
    field: 'client_last_seen',
    minWidth: 175,
  }),
  {
    field: 'client_first_listed',
    align: 'right',
    minWidth: 120,
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'first_listed',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'last_listed',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'last_sold',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'first_sold',
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'customer_third_sale',
    width: 150,
    align: 'right',
    valueFormatter: format.date,
    type: 'date',
    valueGetter: ({ value }) => (value ? new Date(value) : null),
  },
  {
    field: 'no_tp_next_season',
    width: 75,
    renderHeader: () =>
      h(Tooltip, {
        title:
          'User clicked on link in app "I no longer have %league% season tickets" for the league of this ticket package.',
        placement: 'top',
        children: h(
          Box,
          {
            sx: {
              display: 'flex',
              justifyContent: 'between',
              alignItems: 'center',
              gap: 1,
            },
          },
          [
            h(InfoIcon, { color: 'action', fontSize: 'small' }),
            h(Typography, { sx: { fontSize: 14 } }, 'no_tp_next_season'),
          ],
        ),
      }),
    renderCell: (item) => {
      return h('div', !!item.value ? 'true' : '');
    },
  },
];

const tp_details_columns: GridColDef[] = [
  { field: 'season' },
  {
    field: 'ac_deal_id',
    align: 'right',
    maxWidth: 70,
  },
  stringDateTime({
    field: 'created',
    minWidth: 175,
  }),
  {
    field: 'client_name',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
    renderCell: (item) => {
      return h(ClientDrillDown, {
        label: item.value,
        client_id: item.row.client_id,
      });
    },
  },
  {
    field: 'client_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  {
    field: 'ac_contact_id',
    align: 'right',
    minWidth: 75,
    maxWidth: 75,
  },
  {
    field: 'is_client',
    width: 75,
    type: 'boolean',
  },
  {
    field: 'is_playoff',
    width: 75,
    type: 'boolean',
  },
  { field: 'league', width: 75 },
  { field: 'team_name', width: 100 },
  { field: 'section', width: 75 },
  { field: 'row', width: 50 },
  {
    field: 'seats',
    valueGetter: ({ row }) => row.first_seat + '-' + row.last_seat,
    minWidth: 50,
    maxWidth: 100,
  },
  {
    field: 'submission_note',
    minWidth: 150,
  },
  {
    field: 'sth_status',
    minWidth: 50,
    maxWidth: 100,
    valueGetter: ({ row }) =>
      row.sth_status
        ? row.sth_status +
          (row.sth_status === 'PSTH'
            ? (' - ' + row.num_games).padStart(2, '0')
            : '')
        : null,
  },
  { field: 'commission_tier', align: 'right', minWidth: 70 },
  {
    field: 'commission',
    align: 'right',
    minWidth: 70,
    headerName: 'legacy commission',
    valueGetter: ({ row }) =>
      row.commission_tier
        ? toCommissionStr(
            +(row.commission ?? '0') + +(row.third_party_cost ?? '0'),
          ) + ' %'
        : '--',
  },
  {
    field: 'commission_experiment',
    width: 140,
    type: 'boolean',
    headerName: 'experiment',
    valueGetter: ({ value }) => value === 'experiment',
  },
  { field: 'stock_type', align: 'right', minWidth: 80 },
];

const team_creds_columns: GridColDef[] = [
  { field: 'creds_status', minWidth: 120 },
  {
    field: 'creds_username',
    minWidth: 150,
    headerName: 'creds_email',
  },
  stringDateTime({
    field: 'creds_status_changed',
    minWidth: 175,
  }),
];

const stats_columns: GridColDef[] = [
  {
    field: 'client_league_sold_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    renderHeader: () =>
      h(Tooltip, {
        title:
          'All-time sold amount for all ticket packages in respective league of ticket package',
        placement: 'top',
        children: h(
          Box,
          {
            sx: {
              display: 'flex',
              justifyContent: 'between',
              alignItems: 'center',
              gap: 1,
            },
          },
          [
            h(InfoIcon, { color: 'action', fontSize: 'small' }),
            h(Typography, { sx: { fontSize: 14 } }, 'client_sold_league'),
          ],
        ),
      }),
  },
  {
    field: 'deal_value',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'median_game_value',
    valueFormatter: format.dollars,
    align: 'right',
    type: 'number',
  },
  {
    field: 'min_game_value',
    valueFormatter: format.dollars,
    align: 'right',
    type: 'number',
  },
  {
    field: 'pct_seats_sold',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  { field: 'seats_sold', align: 'right', type: 'number' },
  {
    field: 'pct_seats_sold_listed',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  { field: 'seats_sold_or_listed', align: 'right', type: 'number' },
  {
    field: 'pct_seats_not_listed',
    align: 'right',
    valueFormatter: format.percent,
    type: 'number',
  },
  { field: 'seats_not_listed', align: 'right', type: 'number' },
  {
    field: 'sold_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'sold_or_listed_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'unlisted_amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  { field: 'avg_days_listed', align: 'right', type: 'number' },
  {
    field: 'avg_starting_list_price',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  {
    field: 'avg_current_list_price',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
  },
  { field: 'avg_listing_days_to_event', align: 'right', type: 'number' },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<
    number | LeagueId | QualifyStatus | ConversionStage
  >[] = [
    {
      id: 'season',
      label: 'Season',
      optionValues: _.map(
        (start_year) => ({
          value: start_year,
          label: start_year.toString(),
        }),
        baseData.getSeasonStartYears(),
      ),
    },
    {
      id: 'league',
      label: 'League',
      optionValues: _.map(
        (league_id) => ({ label: league_id, value: league_id }),
        baseData.getLeagueIds(),
      ),
    },
    {
      id: 'qualification',
      label: 'Qualification',
      optionValues: [
        { label: 'Pending', value: QualifyStatus.PENDING },
        { label: 'Incorrect info', value: QualifyStatus.INCORRECT_INFO },
        { label: 'Qualified', value: QualifyStatus.QUALIFIED },
        { label: 'Unqualified', value: QualifyStatus.NOT_QUALIFIED },
      ],
    },
    { id: 'is_shown', label: 'Showing?', yesNoAny: true },
    { id: 'is_priced', label: 'Priced?', yesNoAny: true },
    {
      id: 'conversion_stage',
      label: 'Stage',
      optionValues: [
        { label: 'Sold', value: ConversionStage.SOLD },
        { label: 'Delisted all', value: ConversionStage.DELISTED_ALL },
        { label: 'Listed', value: ConversionStage.LISTED },
        { label: 'Logged on', value: ConversionStage.LOGGED_ON },
        { label: 'Activated', value: ConversionStage.ACTIVATED },
        { label: 'Supported', value: ConversionStage.SUPPORTED },
        { label: 'Unsupported', value: ConversionStage.NOT_SUPPORTED },
        { label: 'Pending', value: ConversionStage.PENDING },
        { label: 'Incorrect info', value: ConversionStage.INCORRECT_INFO },
      ],
    },
  ];

  return filter_specs;
};

export default function PackagesDataGrid({
  rows,
  onDataUpdated,
}: {
  rows: any[];
  onDataUpdated: () => void;
}) {
  const { baseData } = useBaseData();
  const [data, setData] = useState(rows);
  const onSaveSucceed = (row: any, field: string, value: any) => {
    const new_data = [...data];
    const target_idx = data.findIndex((item) => item.id === row.id);
    new_data[target_idx][field] = value;
    setData(new_data);
    onDataUpdated();
  };

  useEffect(() => {
    setData(rows);
  }, [rows]);

  return PageDataGrid({
    id: 'ticket-packages',
    rows: _.filter(
      (r) =>
        (!r.is_archived || baseData.include_archived) &&
        (r.is_client || baseData.include_test_data),
      data,
    ),
    column_specs: [
      ...tp_details_columns,
      DataGridCustomColumn({
        field: 'is_commission_reviewed',
        headerName: 'is commission reviewed',
        onSaveSucceed,
        doSave: api.setCommissionReview,
        type: 'boolean',
        isHidden: ({ row }) =>
          row.commission === undefined || row.commission === null,
      }),
      DataGridCustomColumn({
        field: 'is_payment_plan',
        headerName: 'payment plan',
        onSaveSucceed,
        doSave: api.setPaymentPlan,
        type: 'boolean',
      }),
      ...column_specs,
      DataGridCustomColumn({
        field: 'is_price_review_requested',
        headerName: 'price review requested',
        onSaveSucceed,
        doSave: api.setPriceReview,
        type: 'boolean',
      }),
      ...tp_activity_columns,
      ...team_creds_columns,
      DataGridCustomColumn({
        field: 'revalidate_creds',
        headerName: 'Revalidate',
        onSaveSucceed: (id: number, field: string, value: any) =>
          onSaveSucceed(id, 'creds_status_changed', value),
        doSave: api.revalidateCredentials,
        type: 'action',
        isHidden: ({ row }) => row.creds_status !== 'validated',
        label: 'Revalidate',
        color: 'success',
      }),
      ...stats_columns,
      DataGridNoteColumnV2({
        field: 'note',
        valueGetter: ({ row }) => row.note,
        headerName: 'ticket_package',
        saveNoteHeaderFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteHeader({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'ticket_package',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'note', value);
        },
        saveNoteBodyFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteDetail({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'ticket_package',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'has_notes', !!value);
        },
        getDataFn: (row: any, api_key_field: string) =>
          api.getNoteDetail({
            row,
            api_key_field,
            ref_source: 'ticket_package',
            ref_usage: 'general',
          }),
        getEntryDescription: (row) =>
          `${row.client_name}'s ${row.team_name} package (${row.season} ${
            row.is_playoff ? 'playoff' : 'regular'
          } season, sec ${row.section}, row ${row.row}, s ${row.first_seat}-${
            row.last_seat
          })`,
        hasNoteBody: (row) => !!row.has_notes,
        api_key_field: 'id',
      }),
      DataGridNoteColumnV2({
        field: 'client_note',
        valueGetter: ({ row }) => row.client_note,
        headerName: 'client',
        saveNoteHeaderFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteHeader({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'client_info',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'client_note', value);
        },
        saveNoteBodyFn: async (
          row: any,
          value: string,
          api_key_field: string,
        ) => {
          await api.updateNoteDetail({
            row,
            new_value: value,
            api_key_field,
            ref_source: 'client_info',
            ref_usage: 'general',
          });
          onSaveSucceed(row, 'client_has_notes', !!value);
        },
        getDataFn: (row: any, api_key_field: string) =>
          api.getNoteDetail({
            row,
            api_key_field,
            ref_source: 'client_info',
            ref_usage: 'general',
          }),
        getEntryDescription: (row) =>
          `${row.client_name} (phone: ${row.phone}, email: ${row.email})`,
        hasNoteBody: (row) => !!row.client_has_notes,
        api_key_field: 'client_id',
      }),
      DataGridNoteColumn({
        field: 'barcode_note',
        headerName: 'no_barcode',
        onSaveSucceed,
        doSave: (item: any, note: string, api_key_field: string) =>
          api.upsertNoBarcodeReportNote(
            item,
            note,
            api_key_field,
            'ticket_package',
          ),
        api_key_field: 'id',
      }),
    ],
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
    add_button: true,
  });
}
