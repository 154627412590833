import h from '../../../lib/react-hyperscript';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useContext, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import {
  AlertColor,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../common/store/context';
import { GridColumnHeaderParams } from '@mui/x-data-grid/models/params/gridColumnHeaderParams';
import { Edit as EditIcon } from '@mui/icons-material';
import { SimpleSelect } from '../../../common/components';

const CustomCell = ({
  props,
  name,
  onSaveSucceed,
  doSave,
  api_key_field,
  type,
  options,
  label,
  color,
  disabled,
}: {
  props: GridRenderCellParams;
  name?: string;
  onSaveSucceed: (id: number, field: string, value: any) => void;
  doSave: (row: any, value: any, api_key_field?: string) => Promise<void>;
  api_key_field?: string;
  type: string;
  options?: { label: string; value: any }[];
  label?: string;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled: boolean;
}) => {
  const { id, value, field, row } = props; // row data
  const apiRef = useGridApiContext();
  const { setNotification } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const upsertData = async (e: any) => {
    setLoading(true);
    doSave(row, e, api_key_field)
      .then(() => {
        setNotification({
          open: true,
          severity: 'success' as AlertColor,
          msg: `${name ?? field} has been updated successfully`,
        });
        setLoading(false);
        onSaveSucceed(row, field, e);
        apiRef.current.setEditCellValue({
          id,
          field,
          value: e,
        });
      })
      .catch(() => {
        setNotification({
          open: true,
          severity: 'error' as AlertColor,
          msg: `Failed to update ${name ?? field} for tp: ${row.id}`,
        });
        setLoading(false);
      });
  };

  const getCellByType = (type: string) => {
    switch (type) {
      case 'boolean':
        return h(Checkbox, {
          checked: value,
          disabled,
          onClick: () => upsertData(!value),
        });
      case 'string':
        return options
          ? h(SimpleSelect, {
              optionValues: options,
              disabled,
              fullWidth: true,
              label: '',
              name: 'event_status',
              value: value ?? undefined,
              onChange: (value) => upsertData(value),
            })
          : '';
      case 'action':
        return h(
          Button,
          {
            variant: 'contained',
            size: 'small',
            color: color,
            disabled,
            onClick: () => upsertData(new Date()),
            sx: {
              whiteSpace: 'nowrap',
              flexShrink: 0,
              height: 25,
            },
          },
          label,
        );
      default:
        return '';
    }
  };

  return h(
    Box,
    {
      sx: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      },
    },
    [loading ? h(CircularProgress, { size: 18 }) : getCellByType(type)],
  );
};

const CustomHeader = (props: GridColumnHeaderParams) => {
  return h(
    Box,
    {
      sx: {
        display: 'flex',
        justifyContent: 'between',
        alignItems: 'center',
        gap: 1,
      },
    },
    [
      h(
        Typography,
        { sx: { fontSize: 14 } },
        props.colDef.headerName ?? props.field,
      ),
    ],
  );
};

const DataGridCustomColumn = ({
  field,
  headerName,
  minWidth,
  align,
  onSaveSucceed,
  doSave,
  api_key_field,
  type,
  options,
  isHidden,
  label,
  color,
  isDisabled,
}: {
  field: string;
  headerName?: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  onSaveSucceed: (id: number, field: string, value: any) => void;
  doSave: (row: any, value: any, api_key_field?: string) => Promise<void>;
  api_key_field?: string;
  type: string;
  options?: { label: string; value: any }[];
  isHidden?: ({ row }: { row: any }) => boolean;
  isDisabled?: ({ row }: { row: any }) => boolean;
  label?: string;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}): GridColDef => {
  const renderHeader = (params: GridColumnHeaderParams) => {
    return h(CustomHeader, { ...params });
  };

  const renderCell = (params: GridRenderCellParams) => {
    return (isHidden ? !isHidden(params) : true)
      ? h(CustomCell, {
          props: params,
          name: headerName ?? field,
          onSaveSucceed,
          doSave: doSave,
          api_key_field,
          type,
          options,
          label,
          color,
          disabled: isDisabled ? isDisabled(params) : false,
        })
      : '';
  };

  return {
    field,
    headerName: headerName ?? field,
    minWidth: minWidth ?? 100,
    align: align ?? 'center',
    renderHeader: renderHeader,
    renderCell: renderCell,
    type,
  };
};

export default DataGridCustomColumn;
