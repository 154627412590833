import h from '../../../lib/react-hyperscript';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { InventoryItem, SaleStatistic } from '../../../common/types';
import { ClientDrillDown, format } from '../../../common/components';
import { useBaseData } from '../../../common/base_data';
import MarketplaceEventLinkGroup from '../../../common/components/MarketplaceEventLinkGroup';

const HeaderField = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode | string;
}) => {
  const theme = useTheme();
  return h(Stack, { direction: 'row', gap: '5px', alignItems: 'center' }, [
    h(
      Typography,
      {
        variant: 'caption',
        sx: { color: theme.palette.grey[900], fontSize: 13 },
      },
      label + ':',
    ),
    typeof value === 'string'
      ? h(
          Typography,
          {
            variant: 'body1',
            sx: { color: theme.palette.grey[900] },
          },
          value,
        )
      : value,
  ]);
};

export default function SalesOverview({
  inv_item,
  sale_statistics,
  tm_event_link,
}: {
  inv_item: InventoryItem;
  sale_statistics?: SaleStatistic;
  tm_event_link?: string;
}) {
  const {
    baseData: { getClient },
  } = useBaseData();
  const client_name = inv_item
    ? getClient(inv_item.client_id)?.first_name +
      ' ' +
      getClient(inv_item.client_id)?.last_name
    : '';

  const [is_expanded, setExpanded] = useState(true);

  return h(Accordion, {
    expanded: is_expanded,
    children: [
      h(AccordionSummary, { sx: { cursor: 'default!important' } }, [
        h(
          Grid,
          {
            container: true,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          [
            h(Stack, { direction: 'row', alignItems: 'center' }, [
              h(
                IconButton,
                { size: 'small', onClick: () => setExpanded(!is_expanded) },
                [h(is_expanded ? ExpandLessIcon : ExpandMoreIcon)],
              ),
              h(
                Typography,
                {
                  variant: 'h6',
                  gutterBottom: true,
                  sx: { fontWeight: 'bold', mb: 0 },
                },
                'Overview',
              ),
            ]),

            is_expanded
              ? MarketplaceEventLinkGroup({
                  // @ts-ignore
                  event: inv_item.skybox.event ?? {},
                })
              : '',
          ],
        ),
      ]),

      h(AccordionDetails, { sx: { paddingX: 3 } }, [
        h(Stack, { gap: 1, sx: { height: '265px', overflow: 'scroll' } }, [
          h(HeaderField, {
            label: 'Client',
            value: h(ClientDrillDown, {
              label: client_name,
              client_id: inv_item.client_id,
            }),
          }),
          h(HeaderField, {
            label: 'Details',
            value: `section ${inv_item.section}, row ${inv_item.row}, seats ${inv_item.seats}`,
          }),
          h(HeaderField, {
            label: 'Event',
            value: inv_item.event_name,
          }),
          h(HeaderField, {
            label: 'Date',
            value: format.datetime({ value: inv_item.event_date_local ?? '' }),
          }),
          h(HeaderField, {
            label: 'Marketplace',
            // @ts-ignore
            value: inv_item.skybox.customerDisplayName,
          }),
          h(HeaderField, {
            label: `Client's number of sales (All time .vs ${
              inv_item.season ?? '??'
            })`,
            value: `${sale_statistics?.sales_amount_all_time} .vs ${sale_statistics?.sales_amount_cur_season}`,
          }),
          h(HeaderField, {
            label: `Client's sales volume (All time .vs ${
              inv_item.season ?? '??'
            })`,
            value: sale_statistics
              ? `${format.dollars({
                  value: sale_statistics.sales_volume_all_time,
                })} .vs ${
                  format.dollars({
                    value: sale_statistics.sales_volume_cur_season,
                  }) || '$0'
                }`
              : '',
          }),
          h(HeaderField, {
            label: `Ticket Package Median sale price (${
              inv_item.season ?? '??'
            })`,
            value: sale_statistics
              ? `${
                  format.dollars({
                    value: sale_statistics.median_sale_price,
                  }) || 'None'
                }`
              : '',
          }),
        ]),
      ]),
    ],
  });
}
