import h from '../../lib/react-hyperscript';
import SeasonPanel from './components/SeasonPanel';
import SeasonList from './components/SeasonList';

const routing = {
  element: h(SeasonPanel),
  children: [
    {
      path: '',
      element: h(SeasonList),
    },
  ],
};

export { routing };
