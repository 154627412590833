import { BaseDataProvider, useBaseData } from '../../../common/base_data';
import {
  format,
  PageDataGrid,
  QuickFilterSpec,
} from '../../../common/components';
import { GridColDef } from '@mui/x-data-grid-pro';
import DataGridEntryLink from '../../../common/components/DataGridEntryLink';

const column_specs: GridColDef[] = [
  {
    field: 'id',
    width: 80,
    renderCell: (params) =>
      DataGridEntryLink(
        { id: params.row.id },
        `/payments?filter.01.bulk_payment_id=equals%2C${params.row.id}&v=2`,
      ),
  },
  {
    field: 'initiated',
    width: 160,
    valueFormatter: format.datetime,
  },
  {
    field: 'completed',
    width: 160,
    valueFormatter: format.datetime,
  },
  { field: 'status', minWidth: 120 },
  {
    field: 'payment_amount',
    headerName: '$amount',
    align: 'right',
    valueFormatter: format.dollars,
    type: 'number',
    width: 120,
  },
  { field: 'item_amount', headerName: 'number_of_items', width: 150 },
  { field: 'key', width: 150 },
  {
    field: 'initiated_by',
    flex: 1,
    minWidth: 150,
    maxWidth: 300,
  },
];

const column_groups = [
  {
    groupId: 'Entry',
    children: [{ field: 'id' }],
  },
  {
    groupId: 'Transaction details',
    children: [
      { field: 'initiated' },
      { field: 'completed' },
      { field: 'status' },
      { field: 'payment_amount' },
      { field: 'item_amount' },
      { field: 'key' },
    ],
  },
  {
    groupId: 'Operator',
    children: [{ field: 'initiated_by' }],
  },
];

const buildFilterSpecs = (baseData: BaseDataProvider) => {
  const filter_specs: QuickFilterSpec<number | string>[] = [
    {
      id: 'status',
      label: 'Status',
      optionValues: [
        { label: 'created', value: 'created' },
        { label: 'completed', value: 'completed' },
        { label: 'failed', value: 'failed' },
      ],
    },
  ];

  return filter_specs;
};
export default function BulkPaymentsDataGrid({ rows }: { rows: any[] }) {
  const { baseData } = useBaseData();
  return PageDataGrid({
    id: 'payments',
    getRowId: (row) => row.id,
    rows: rows,
    column_specs,
    column_groups,
    filter_specs: buildFilterSpecs(baseData),
    hide_client_filter: true,
  });
}
