import h from '../../../lib/react-hyperscript';
import debounce from '../../../common/util/debounce';
import {
  AlertColor,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  Client,
  ClientTicketPackageEx,
  LeagueId,
  Team,
  TicketPackage,
} from '../../../common/types';
import * as api from '../../../common/api';
import { default as React, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientAutoComplete, SimpleSelect } from '../../../common/components';
import { useTheme } from '@mui/material/styles';
import { AppContext } from '../../../common/store/context';
import { useCommissions } from '../../../common/hooks/useCommissions';
import TeamAutoComplete from '../../../common/components/TeamAutoComplete';
import { toCommissionStr } from '../../../common/helper';

export default function TicketPackageEditor({
  pkg,
  league_ids,
  getTeamsForLeague,
  onUpdate = () => {},
  onAdd = () => {},
  variant = 'update',
  clients = [],
  onIsEditing = () => {},
  onCancel,
}: {
  pkg?: ClientTicketPackageEx;
  league_ids: LeagueId[];
  getTeamsForLeague: (leagueId: LeagueId) => Team[];
  onUpdate?: (update: TicketPackage) => void;
  onAdd?: (client_id: number, pkg: TicketPackage) => void;
  onCancel?: () => void;
  onIsEditing?: (isEditing: boolean) => void;
  variant?: 'add' | 'update';
  clients?: Client[];
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setNotification } = useContext(AppContext);

  const [team_id, setTeamId] = useState(pkg?.team_id);
  const [sth_status, setSthStatus] = useState(pkg?.sth_status);
  const [num_games, setNumGames] = useState(pkg?.num_games);
  const [league_id, setLeague] = useState(pkg?.league ?? LeagueId.NFL);
  const [is_playoff, setPlayoff] = useState(pkg?.is_playoff ?? false);
  const [client_id, setClientId] = useState(
    pkg?.client_id ?? (null as null | number),
  );
  const [commission_tier, setCommissionTier] = useState(
    pkg?.commission_tier ?? null,
  );
  const [commission, setCommission] = useState('');
  const [most_recent_tier, setMostRecentTier] = useState<{
    league: LeagueId;
    id: number;
    team_abbrev: string;
    section: string;
    row: string;
    first_seat: string;
    last_seat: string;
    commission: string;
  } | null>(null);

  const teams = getTeamsForLeague(league_id);

  const [is_edit_mode, setIsEditMode] = useState(variant === 'add');
  const [selected_stock_type, setSelectedStockType] = useState(
    pkg?.stock_type ?? teams[0].default_stock_type,
  );

  const { commissions, doFetchCommissions } = useCommissions();

  const debouncedGetCommissionByTier = debounce(async () => {
    if (client_id && commission_tier) {
      const resp = await api.getCommissionDetailsByTier(
        client_id,
        commission_tier,
      );
      setCommission(toCommissionStr(resp.commission));
      if (resp.tp_source)
        setMostRecentTier({ ...resp.tp_source, commission: resp.commission });
      else setMostRecentTier(null);
    }
  }, 500);

  useEffect(() => {
    onIsEditing(is_edit_mode);
  }, [is_edit_mode, onIsEditing]);

  useEffect(() => {
    if (variant === 'update') return;
    if (client_id && commission_tier) {
      debouncedGetCommissionByTier();
    } else {
      setMostRecentTier(null);
    }
  }, [client_id, commission_tier]);

  const formValidation = (form: TicketPackage) => {
    if (!form.sth_status) return 'sth status is required';
    if (!form.row) return 'row is required';
    if (!form.first_seat || !form.last_seat) return 'seats is incomplete.';

    return '';
  };

  const isStandardCommission = (
    commission: string | null,
    commission_tier: string | null,
  ) => {
    if (!commission || !commission_tier) return false;
    return !!commissions.find(
      (item) =>
        item.commission_tier === commission_tier &&
        +`${item.tiq_commission}` === +commission &&
        item.is_active,
    );
  };

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsEditMode(variant === 'add');
    const data = new FormData(event.currentTarget);
    const form = {
      league: data.get('league_id') ?? pkg?.league,
      team_id,
      section: data.get('section') as string,
      row: data.get('row') as string,
      first_seat: data.get('first_seat') as string,
      last_seat: data.get('last_seat') as string,
      sth_status: sth_status,
      num_games: parseInt(data.get('num_games') as string, 10) ?? null,
      is_playoff: is_playoff,
      stock_type: selected_stock_type,
    } as TicketPackage & {
      commission_tier?: string;
      tiq_commission?: string;
      third_party_cost?: string;
    };

    const form_validation = formValidation(form);
    if (form_validation) {
      setNotification({
        open: true,
        severity: 'error' as AlertColor,
        msg: `${form_validation}`,
      });
      return;
    }

    if (variant === 'add') {
      // Commission be setup only if is_create_mode
      if (commission_tier) form.commission_tier = commission_tier;
      if (commission) {
        form.tiq_commission = String(parseFloat(commission) / 100);
        form.third_party_cost = '0.0990';
      }
      onAdd(client_id as number, form);
    } else {
      onUpdate(form);
    }
  };

  useEffect(() => {
    if (sth_status === 'STH') setNumGames(undefined);
  }, [sth_status]);

  useEffect(() => {
    doFetchCommissions();
  }, [doFetchCommissions]);

  return h(
    Box,
    {
      component: 'form',
      onSubmit: handleSave,
      noValidate: true,
    } as any, // How do you type a Box element as a form?
    [
      variant === 'add'
        ? h(ClientAutoComplete, {
            clients,
            client_id,
            id: 'client_id',
            name: 'client_id',
            label: 'Client',
            disabled: !!pkg?.client_id,
            onChange: (client) => setClientId(client?.id ?? null),
            sx: { mb: 3 },
          })
        : null,

      ...(variant === 'add'
        ? [
            h(Box, { pb: 1 }, [
              h(
                Stack,
                {
                  direction: 'row',
                  alignItems: 'center',
                  gap: 3,
                },
                [
                  h(FormControl, { fullWidth: true }, [
                    h(
                      InputLabel,
                      { sx: { background: '#fff' } },
                      'Commission tier',
                    ),
                    h(
                      Select,
                      {
                        value: commission_tier,
                        sx: { textAlign: 'left' },
                        onChange: (e) => {
                          setCommissionTier(e.target.value as string);
                        },
                      },
                      commissions
                        .filter((item) => item.is_active)
                        .map((c) =>
                          h(
                            MenuItem,
                            {
                              key: c.commission_tier,
                              value: c.commission_tier,
                            },
                            c.commission_tier,
                          ),
                        ),
                    ),
                  ]),
                  h(TextField, {
                    type: 'number',
                    size: 'medium',
                    id: 'tiq_commission',
                    label: 'Commission',
                    name: 'tiq_commission',
                    value: commission,
                    onChange: (e) => {
                      setCommission(e.target.value);
                    },
                    sx: {
                      width: '250px',
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                        fontWeight: isStandardCommission(
                          (parseFloat(commission || '0') / 100).toFixed(4),
                          commission_tier,
                        )
                          ? 400
                          : 'bold',
                      },
                    },
                    InputProps: {
                      endAdornment: h(InputAdornment, { position: 'end' }, '%'),
                    },
                  }),
                ],
              ),

              h(
                Stack,
                {
                  sx: { width: '100%' },
                },
                most_recent_tier
                  ? [
                      h(
                        Stack,
                        {
                          direction: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        },
                        [
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `Most recent tier ${commission_tier}: `,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `${toCommissionStr(most_recent_tier.commission)}% ${
                              isStandardCommission(
                                most_recent_tier.commission,
                                commission_tier,
                              )
                                ? ''
                                : '(non-standard)'
                            }`,
                          ),
                        ],
                      ),
                      h(
                        Stack,
                        {
                          direction: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        },
                        [
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `(${most_recent_tier.league}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `${most_recent_tier.team_abbrev}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `S${most_recent_tier.section}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px', fontWeight: 'bold' },
                            '|',
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `R${most_recent_tier.row}`,
                          ),
                          h(
                            Typography,
                            { fontSize: '13px', fontWeight: 'bold' },
                            '|',
                          ),
                          h(
                            Typography,
                            { fontSize: '13px' },
                            `S${most_recent_tier.first_seat}-${most_recent_tier.last_seat})`,
                          ),
                        ],
                      ),
                    ]
                  : [
                      h(
                        Typography,
                        { fontSize: '13px' },
                        commission_tier
                          ? `Most recent tier ${commission_tier}: None`
                          : '',
                      ),
                    ],
              ),
              h(
                Stack,
                {
                  direction: 'row',
                  alignItems: 'baseline',
                  justifyContent: 'center',
                  gap: 1,
                  sx: { width: '100%' },
                },
                [
                  h(
                    Typography,
                    {
                      fontSize: '13px',
                      sx: {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      },
                      onClick: () => {
                        window.open(
                          'https://docs.google.com/spreadsheets/d/1uf2IlkOZwkk0c0C_SF3q4d8wqHqI2ffKhEUYd2gn7zw/edit?gid=961367321#gid=961367321',
                          '_blank',
                        );
                      },
                    },
                    'Rate table.',
                  ),
                ],
              ),
            ]),
          ]
        : []),

      h(FormControlLabel, {
        disabled: !is_edit_mode || !!pkg?.client_id,
        label: h(Stack, { direction: 'row', gap: 1 }, [
          'Playoff ticket package',
          h(
            Typography,
            { color: theme.palette?.success?.main || '' },
            '(pre-qualified)',
          ),
        ]),
        sx: { whiteSpace: 'nowrap', display: 'flex' },
        control: h(Checkbox, {
          id: 'is_playoff',
          name: 'is_playoff',
          checked: is_playoff,
          onChange: (_e, value) => {
            setPlayoff(value);
            if (!!value && sth_status !== 'STH') setSthStatus('STH');
          },
        }),
      }),

      h(
        ToggleButtonGroup,
        {
          disabled: !is_edit_mode,
          color: 'primary',
          value: sth_status,
          fullWidth: true,
          exclusive: true,
          onChange: (_e, value) => setSthStatus(value),
          sx: { mb: 1, mt: 2 },
        },
        [
          h(ToggleButton, { value: 'STH' }, 'Full'),
          h(ToggleButton, { value: 'PSTH' }, 'Partial'),
        ],
      ),

      h(TextField, {
        disabled: !is_edit_mode || sth_status !== 'PSTH',
        margin: 'normal',
        fullWidth: true,
        id: 'num_games',
        label: sth_status === 'STH' ? 'All Games' : 'Number of Games',
        variant: !is_edit_mode || sth_status !== 'PSTH' ? 'filled' : 'outlined',
        name: 'num_games',
        value: num_games ?? '',
        onChange: (e) => {
          setNumGames(parseInt(e.target.value, 10) || undefined);
        },
        inputProps: {
          type: 'number',
          pattern: '[0-9]*',
        },
      }),

      h(SimpleSelect, {
        disabled: !is_edit_mode || !!pkg,
        options: league_ids,
        sx: { mt: 2 },
        fullWidth: true,
        label: 'League',
        name: 'league_id',
        defaultValue: league_id ?? undefined,
        onChange: (id) => setLeague(id as LeagueId),
      }),

      h(TeamAutoComplete, {
        disabled: !is_edit_mode || !!pkg?.inventory_count,
        team_id,
        teams: teams ?? [],
        id: 'team-filter',
        label: 'Team',
        name: 'team_id',
        sx: { mt: 3, mb: 1, ml: 2, width: 'auto' },
        onChange: (team) => {
          setTeamId(team.id);
          setSelectedStockType(
            teams.find((t) => t.id === team.id)?.default_stock_type ?? '',
          );
        },
      }),

      h(SimpleSelect, {
        disabled: !is_edit_mode || !!pkg?.inventory_count,
        optionValues: [
          { label: 'Mobile Transfer', value: 'MOBILE_TRANSFER' },
          { label: 'Flash', value: 'FLASH' },
        ],
        value: selected_stock_type,
        sx: { mt: 3, mb: 1 },
        fullWidth: true,
        label: 'Ticket stock type',
        name: 'stock_type',
        onChange: (value) => {
          setSelectedStockType(`${value}`);
        },
      }),

      h(TextField, {
        disabled: !is_edit_mode,
        margin: 'normal',
        fullWidth: true,
        id: 'section',
        label: 'Section',
        name: 'section',
        defaultValue: pkg?.section,
        onChange: (event) => {
          if (
            event.target.value?.toUpperCase().includes('PR') &&
            !(!is_edit_mode || !!pkg?.inventory_count)
          )
            setSelectedStockType(`FLASH`);
        },
      }),

      h(TextField, {
        disabled: !is_edit_mode,
        margin: 'normal',
        required: true,
        fullWidth: true,
        id: 'row',
        label: 'Row',
        name: 'row',
        defaultValue: pkg?.row,
      }),

      h(
        Stack,
        {
          direction: 'row',
          spacing: 2,
          alignItems: 'center',
          mt: 2,
        },
        [
          h(TextField, {
            disabled: !is_edit_mode,
            required: true,
            id: 'first_seat',
            label: 'First seat',
            name: 'first_seat',
            defaultValue: pkg?.first_seat,
            inputProps: {
              type: 'number',
              pattern: '[0-9]*',
            },
          }),
          h(TextField, {
            disabled: !is_edit_mode,
            required: true,
            id: 'last_seat',
            label: 'Last Seat',
            name: 'last_seat',
            defaultValue: pkg?.last_seat,
            inputProps: {
              type: 'number',
              pattern: '[0-9]*',
            },
          }),
          h(FormControlLabel, {
            disabled: true,
            label: 'Aisle Seats',
            sx: { whiteSpace: 'nowrap' },
            control: h(Checkbox, {
              id: 'is_aisle_seats',
              name: 'is_aisle_seats',
            }),
          }),
        ],
      ),
      h(
        Stack,
        {
          // spacing: 2,
          alignItems: 'start',
          mt: 1,
        },
        [
          h(
            Typography,
            { variant: 'overline', fontSize: '10px' },
            'Submission note',
          ),
          h(
            'div',
            {
              style: {
                width: '95%',
                minHeight: '20px',
                textAlign: 'left',
                background: '#eff0f3',
                padding: '8px 12px',
                textTransform: 'none',
              },
            },
            pkg?.submission_note || '',
          ),
        ],
      ),

      h(
        Stack,
        {
          direction: 'row',
          alignItems: 'baseline',
          justifyContent: 'center',
          spacing: 2,
          sx: { width: '100%', mt: 3 },
        },
        is_edit_mode || variant === 'add'
          ? [
              h(
                Button,
                {
                  key: 'cancel',
                  variant: 'outlined',
                  sx: { width: '100%' },
                  onClick: () => {
                    if (onCancel) onCancel();
                    else if (variant === 'update') setIsEditMode(false);
                    else navigate(`/ticket-packages`);

                    return false;
                  },
                },
                'Cancel',
              ),
              h(
                Button,
                {
                  key: 'submit',
                  type: 'submit',
                  variant: 'contained',
                  sx: { width: '100%' },
                },
                'Save',
              ),
            ]
          : [
              h(
                Button,
                {
                  key: 'edit',
                  sx: { ml: 2 },
                  onClick: () => setIsEditMode(true),
                },
                'Change',
              ),
            ],
      ),
    ],
  );
}
