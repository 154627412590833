import h from '../../lib/react-hyperscript';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import tiqassist from '../../tiqassist.jpeg';
import { useEffect, useState } from 'react';

const LoginDialog = ({
  open,
  onClose = () => {},
  onLoggedIn,
}: {
  open: boolean;
  onClose?: () => void;
  onLoggedIn: () => void;
}) => {
  const { isLoading, logout } = useAuth0();
  const [is_init_loading, setInitLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setInitLoading(false);
    }, 2000);
  }, []);

  return h(
    Dialog,
    {
      open,
      onClose,
      fullWidth: true,
      maxWidth: 'sm',
      sx: { alignItems: 'center' },
    },
    [
      h(
        DialogTitle,
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        },
        [
          h(Avatar, {
            alt: 'logo',
            src: tiqassist,
            sx: { width: 80, height: 80 },
          }),
          'Welcome back',
        ],
      ),
      h(DialogContent, { sx: { alignItems: 'center' } }, [
        h(Box, { sx: { display: 'flex', flexDirection: 'column', gap: 1 } }, [
          h(
            LoadingButton,
            {
              fullWidth: true,
              variant: 'contained',
              sx: { mt: 1 },
              loading: isLoading || is_init_loading,
              onClick: async () => {
                await logout({
                  logoutParams: {
                    returnTo: `${window.location.origin}/login?redirect_login=true`,
                  },
                });
              },
            },
            'Login',
          ),
          h(Box, { sx: { display: 'flex', alignItems: 'start' } }, [
            h(TipsAndUpdatesIcon, { color: 'warning', fontSize: 'small' }),
            h(
              Typography,
              { variant: 'button', fontWeight: 'bold', mr: 0.8 },
              'Hint:',
            ),
            h(
              Typography,
              { variant: 'body2', noWrap: true, lineHeight: 1.65 },
              'please ensure your tiqassist.com email is logged in to your browser.',
            ),
          ]),
        ]),
      ]),
    ],
  );
};

export default LoginDialog;
