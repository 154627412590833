import h from '../../../lib/react-hyperscript';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/ticket-packages');
  }, []);
  return h('div');
};
