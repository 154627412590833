import MarkdownEditor from '../../../common/components/MarkdownEditor';
import * as api from '../../../common/api';

export default function Documentation() {
  return MarkdownEditor({
    title: 'Documentation',
    getDataFn: api.getDocumentationPage,
    saveDataFn: api.saveDocumentationPage,
  });
}
