import h from '../../../lib/react-hyperscript';
import { Outlet, useLocation } from 'react-router-dom';
import { Breadcrumbs, Link, Stack } from '@mui/material';

export default function ProblematicListingPanel() {
  const location = useLocation();
  const path = location.pathname.split('/').filter((p) => p !== '');

  return h(
    Stack,
    {
      flexGrow: 1,
      direction: 'column',
    },
    [
      h(
        Breadcrumbs,
        { 'aria-label': 'breadcrumb' },
        path.map((p) => {
          const href = '/problematic-listing-report';
          return h(
            Link,
            {
              underline: 'hover',
              color: 'inherit',
              href: href,
              key: href,
            },
            p,
          );
        }),
      ),
      h(Outlet),
    ],
  );
}
