import h from '../../lib/react-hyperscript';
import ReportsPanel from './components/ReportsPanel';
import Reports from './components/Reports';

const routing = {
  element: h(ReportsPanel),
  children: [
    {
      path: '',
      element: h(Reports),
    },
  ],
};

export { routing };
