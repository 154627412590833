import h from '../../../lib/react-hyperscript';
import {
  Stack,
  Box,
  Paper,
  Typography,
  Dialog,
  Button,
  Checkbox,
  FormControlLabel,
  AlertColor,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import {
  CredsValidationStatus,
  ClientTicketPackageEx,
  QualifyStatus,
  TicketPackage,
  ClientEvent,
} from '../../../common/types';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { useAsyncFn, useCounter } from 'react-use';
import { useEffect, useCallback, useState, useContext } from 'react';
import PackageEditor from './PackageEditor';
import PlayoffControl from './PlayoffControl';
import QualifyControl from './QualifyControl';
import DangerZone from './DangerZone';
import CredentialsControls from './CredentialsControls';
import PackageEvents from './PackageEvents';
import CommissionControl from './CommissionControl';
import AddTicketPackagePanel from './AddPackagePanel';

import { ClientDrillDown } from '../../../common/components';
import * as api from '../../../common/api';
import { useBaseData } from '../../../common/base_data';
import * as _ from 'ramda';

import { useParams } from 'react-router-dom';
import { useCommissions } from '../../../common/hooks/useCommissions';
import { PackageHistoryDialog } from './PackageHistoryDialog';
import { useCachedData } from '../../../common/cached_data';
import NoteDetailsDialog from '../../../common/components/NoteDetailsDialog';
import { Subject as SubjectIcon } from '@mui/icons-material';
import { AppContext } from '../../../common/store/context';

const fetchFunc = async (opts: {
  id: number;
  updateFn?: () => Promise<void>;
}) => {
  if (opts.updateFn) {
    await opts.updateFn();
  }

  const ticket_package = await api.getPackage(opts.id);

  return { ticket_package };
};

const ArchivedMessage = () => {
  const theme = useTheme();
  return h(
    Stack,
    {
      direction: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      sx: {
        backgroundColor: theme.palette.warning.light,
        width: '100vw',
        mt: 3,
        mb: 3,
      },
    },
    [
      h(
        Typography,
        {
          variant: 'h3',
          sx: { color: theme.palette.warning.main },
        },
        'This Package is Archived',
      ),
    ],
  );
};

export default function TicketPackagePanel() {
  const { setNotification } = useContext(AppContext);
  // TODO: consider to use upsert rather than reload whole data if api supports it
  const { invalidateData: invalidateTpData } = useCachedData('ticket_packages');
  const { invalidateData: invalidatePlayoffTpData } = useCachedData(
    'playoff_ticket_packages',
  );
  const { package_id } = useParams<{ package_id: string }>();
  const id = parseInt(package_id as string, 10);

  const [state, doFetch] = useAsyncFn(fetchFunc);
  const [editing_count, editingCounter] = useCounter(0, undefined, 0);
  const { commissions, doFetchCommissions } = useCommissions();
  const [is_note_dialog_open, setNoteDialogOpen] = useState(false);

  const setIsEditing = useCallback(
    (is_editing: boolean) => {
      editingCounter.inc(is_editing ? 1 : -1);
    },
    [editingCounter],
  );

  const {
    baseData: { getTeamsForLeague, getLeagueIds, getTeamTmSystemId },
  } = useBaseData();

  useEffect(() => {
    doFetch({ id });
  }, [id, doFetch]);

  useEffect(() => {
    doFetchCommissions();
  }, [doFetchCommissions]);

  const save = (update: TicketPackage) => {
    doFetch({ updateFn: () => api.updatePackage(id, update), id });
  };

  const setStatus = (status: QualifyStatus) => {
    doFetch({ updateFn: () => api.setStatus(id, status), id });
  };

  const setCredsStatus = (status: CredsValidationStatus) => {
    doFetch({ updateFn: () => api.setCredentialsStatus(id, status), id });
  };

  const setValidatingCreds = async (creds_id: number) => {
    await doFetch({
      updateFn: () => api.validateTeamCredentials(creds_id),
      id,
    });
  };

  const setNoteHeader = async (note_header: string) => {
    await doFetch({
      updateFn: () =>
        api.updateNoteHeader({
          row: { id },
          new_value: note_header,
          api_key_field: 'id',
          ref_source: 'ticket_package',
          ref_usage: 'general',
        }),
      id,
    });
  };
  const createInventory = (
    event_ids: number[],
    selected_events: ClientEvent[],
  ) => {
    doFetch({
      updateFn: () => api.createInventory(id, event_ids, selected_events),
      id,
    });
  };

  const deleteInventory = (
    inv_ids: number[],
    selected_events: ClientEvent[],
  ) => {
    const invs = selected_events.map((eve) => ({
      event: eve,
      id: eve.inv?.id as number,
    }));
    doFetch({ updateFn: () => api.deleteInventory(id, invs), id });
  };

  const setIsInventoryShown = (is_shown: boolean) => {
    doFetch({
      updateFn: () => api.setIsInventoryShown(id, is_shown),
      id,
    });
  };

  const setCommission = (
    commission_tier: string,
    tiq_commission: string | null,
  ) => {
    doFetch({
      updateFn: () => api.setCommission(id, commission_tier, tiq_commission),
      id,
    });
  };

  const setMarketplaceVerification = (is_verified: boolean) => {
    doFetch({
      updateFn: () =>
        api.updateTicketPackageMarketplaceVerification(id, is_verified),
      id,
    });
  };

  const setAwaitingTickets = (is_awaiting_tickets: boolean) => {
    doFetch({
      updateFn: () => api.setAwaitingTickets({ id }, is_awaiting_tickets),
      id,
    });
  };

  const setAmpPricing = async () => {
    try {
      await api.setAmpPricing({ id });
      setNotification({
        open: true,
        severity: 'success' as AlertColor,
        msg: `Pricing request has been sent successfully. It may take a few minutes to complete.`,
      });
    } catch (e) {
      setNotification({
        open: true,
        severity: 'error' as AlertColor,
        msg: `Failed to send pricing request. Please try again.`,
      });
    }
  };

  const archive = (reason?: string) => {
    doFetch({
      updateFn: () => api.archive(id, reason),
      id,
    });
  };

  const unarchive = () => {
    doFetch({
      updateFn: () => api.unarchive(id),
      id,
    });
  };

  const pkg = state.value?.ticket_package;

  const [is_dialog_open, setDialog] = useState(false);

  return h(
    Stack,
    {
      alignItems: 'center',
    },
    [
      h(FetchBackdrop, { state }),
      !pkg
        ? []
        : h(
            Box,
            {
              sx: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              },
            },

            [
              // z-Index necessary because the padding on the grid
              // overlaps this element. Essentially, there is a mub with the
              // grid.
              pkg.is_archived ? h(ArchivedMessage) : null,
              h(
                'div',
                {
                  style: {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '32px',
                  },
                },
                [
                  h('div'),
                  h(
                    Typography,
                    {
                      sx: { zIndex: 10 },
                      variant: 'h5',
                      fontWeight: 'bold',
                    },
                    [
                      h(ClientDrillDown, {
                        label: pkg.client_name,
                        client_id: pkg.client_id,
                      }),
                      `'s ${pkg.tp_season} Package`,
                    ],
                  ),

                  h(PackageHistoryDialog, { id }),
                ],
              ),

              h(
                Grid,
                {
                  container: true,
                  spacing: 12,
                  columns: { xs: 1, sm: 1, md: 3 },
                },
                [
                  h(Grid, { key: 0, xs: 1 }, [
                    h(
                      Typography,
                      { variant: 'h4', mb: 2 },
                      pkg.is_playoff ? 'Playoffs Package' : 'Package',
                    ),

                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'Notes',
                    ),
                    h(Paper, { elevation: 3, sx: { mb: 2 } }, [
                      h(Box, { pr: 2, pb: 2, pl: 2, pt: 2 }, [
                        h(
                          Button,
                          {
                            sx: {
                              width: '100%',
                              minHeight: '30px',
                              justifyContent: 'start',
                              alignItems: 'start',
                              background: '#eff0f3',
                              padding: '8px 12px',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#d0d4db',
                                boxShadow: 'none',
                              },
                            },
                            size: 'small',
                            onClick: () => setNoteDialogOpen(true),
                          },
                          [
                            pkg?.has_notes
                              ? h(SubjectIcon, {
                                  color: 'info',
                                  fontSize: 'small',
                                })
                              : null,
                            h(
                              Typography,
                              {
                                fontWeight: 550,
                                fontSize: 14,
                                color: 'rgba(0, 0, 0, 0.87)',
                                textAlign: 'left',
                              },
                              pkg?.note || '',
                            ),
                          ],
                        ),
                      ]),
                    ]),

                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'Qualification',
                    ),
                    h(Box, { pb: 2 }, [
                      h(QualifyControl, {
                        qualify_status: pkg.qualify_status,
                        client_name: pkg.client_name,
                        onUpdate: setStatus,
                        onIsEditing: setIsEditing,
                      }),
                    ]),

                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'TiqAssist Commission',
                    ),
                    h(Box, { pb: 2 }, [
                      h(CommissionControl, {
                        tp_id: pkg.id,
                        commissions,
                        commission: pkg.commission,
                        commission_tier: pkg.commission_tier ?? null,
                        onUpdate: setCommission,
                        onIsEditing: setIsEditing,
                        getCommissionByTier: (commission_tier: string) =>
                          api.getCommissionDetailsByTier(
                            pkg.client_id,
                            commission_tier,
                            pkg.id,
                          ),
                      }),
                    ]),
                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'Credentials',
                    ),
                    h(Box, { pb: 2 }, [
                      h(CredentialsControls, {
                        ..._.pick(
                          [
                            'creds_id',
                            'creds_status',
                            'creds_status_changed',
                            'creds_username',
                            'creds_password_token',
                          ],
                          pkg,
                        ),
                        onUpdate: setCredsStatus,
                        onIsEditing: setIsEditing,
                        onValidating: setValidatingCreds,
                        is_validation_supported: !!getTeamTmSystemId(
                          pkg.team_id,
                        ),
                      }),
                    ]),
                    h(
                      Box,
                      {
                        pb: 2,
                        display: 'flex',
                        justifyContent: 'center',
                      },
                      [
                        h(FormControlLabel, {
                          disabled: false,
                          label: h(Stack, { direction: 'row', gap: 1 }, [
                            'Verified in Marketplace',
                          ]),
                          sx: { whiteSpace: 'nowrap', display: 'flex' },
                          control: h(Checkbox, {
                            id: 'is_marketplace_verified',
                            name: 'is_marketplace_verified',
                            checked: pkg.is_marketplace_verified,
                            onChange: (_e, value) => {
                              setMarketplaceVerification(value);
                            },
                          }),
                        }),
                      ],
                    ),
                    h(
                      Typography,
                      { variant: 'overline', mb: 2, fontSize: '14px' },
                      'Details',
                    ),
                    h(Paper, { elevation: 3, sx: { mb: 2 } }, [
                      h(Box, { pr: 2, pb: 2, pl: 2, pt: 2 }, [
                        h(PackageEditor, {
                          pkg,
                          league_ids: getLeagueIds(),
                          getTeamsForLeague,
                          onUpdate: (update: TicketPackage) => {
                            save(update);
                            invalidateTpData();
                            if (update.is_playoff) invalidatePlayoffTpData();
                          },
                          onIsEditing: setIsEditing,
                        }),
                      ]),
                    ]),

                    !pkg.is_playoff
                      ? h(PlayoffControl, {
                          onClick: setDialog,
                        })
                      : null,

                    h(
                      Typography,
                      { variant: 'h4', mb: 2, mt: 4 },
                      'Danger Zone',
                    ),
                    h(DangerZone, {
                      ..._.pick(['is_archived', 'archive_reason'], pkg),
                      onArchive: archive,
                      onUnarchive: unarchive,
                    }),
                  ]),

                  h(
                    Grid,
                    { key: 1, xs: 1, md: 2, borderLeft: '1px', paddingLeft: 2 },
                    [
                      h(
                        Typography,
                        { variant: 'h4', mb: 2 },
                        `Events and Inventory`,
                      ),
                      h(Box, [
                        h(PackageEvents, {
                          ticket_package: pkg,
                          onCreateInventory: createInventory,
                          onDeleteInventory: deleteInventory,
                          onShowToggle: setIsInventoryShown,
                          onShowBanner: setAwaitingTickets,
                          onAmpPricingToggled: setAmpPricing,
                          has_unsaved_changes: editing_count > 0,
                        }),
                      ]),
                    ],
                  ),
                ],
              ),
            ],
          ),
      !!pkg?.is_playoff
        ? null
        : h(Dialog, { open: is_dialog_open, onClose: setDialog }, [
            h(AddTicketPackagePanel, {
              pkg: { ...pkg, is_playoff: true } as ClientTicketPackageEx,
              onCancel: () => setDialog(false),
            }),
          ]),

      is_note_dialog_open
        ? h(NoteDetailsDialog, {
            cur_header: pkg?.note ?? '',
            entry_desc: `${pkg?.client_name}'s ${pkg?.team_name} package (${
              pkg?.season
            } ${pkg?.is_playoff ? 'playoff' : 'regular'} season, sec ${
              pkg?.section
            }, row ${pkg?.row}, s ${pkg?.first_seat}-${pkg?.last_seat})`,
            saveNoteHeaderFn: (new_value) => setNoteHeader(new_value),
            saveNoteBodyFn: (new_value) =>
              api.updateNoteDetail({
                row: { id },
                new_value: new_value,
                api_key_field: 'id',
                ref_source: 'ticket_package',
                ref_usage: 'general',
              }),
            getDataFn: () =>
              api.getNoteDetail({
                row: { id },
                api_key_field: 'id',
                ref_source: 'ticket_package',
                ref_usage: 'general',
              }),
            onClose: () => setNoteDialogOpen(false),
          })
        : null,
    ],
  );
}
