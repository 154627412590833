import h from '../../../lib/react-hyperscript';
import * as api from '../../../common/api';
import { useAsyncFn } from 'react-use';
import { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Close as CloseIcon,
  History as HistoryIcon,
} from '@mui/icons-material';
import * as _ from 'ramda';
import { calculateTimeDifference } from '../../../common/helper';
import { ActionLog } from '../../../common/types';
import MDEditor from '@uiw/react-md-editor';
import ErrorIcon from '@mui/icons-material/Error';

const getActionStatus = (status: string) => {
  if (status === 'SUCCESS') return null;
  return h(Tooltip, {
    title:
      status === 'ERROR'
        ? 'Failed to complete the action, value may not updated.'
        : 'Part of requests are failed in the action, please review details below.',
    placement: 'top',
    children: h(Stack, { direction: 'row', spacing: 1 }, [
      h(ErrorIcon, {
        color: status === 'ERROR' ? 'error' : 'warning',
      }),
      h(
        Typography,
        { color: status === 'ERROR' ? 'error' : '#ff6d00' },
        status === 'ERROR' ? 'Failed Action' : 'Partial Success',
      ),
    ]),
  });
};

const ActivityRow = ({
  item,
  expand,
  setExpand,
}: {
  item: ActionLog;
  expand: boolean;
  setExpand: (value: boolean) => void;
}) => {
  return [
    h(TableRow, { key: item.id }, [
      h(TableCell, {}, [
        item.detail
          ? h(
              IconButton,
              {
                size: 'small',
                onClick: () => setExpand(!expand),
              },
              [expand ? h(KeyboardArrowUpIcon) : h(KeyboardArrowDownIcon)],
            )
          : null,
      ]),
      h(
        TableCell,
        {
          component: 'th',
          scope: 'row',
          sx: { pb: '5px', pt: '5px', width: '130px' },
        },
        item.user_fullname,
      ),
      h(
        TableCell,
        {
          component: 'th',
          scope: 'row',
          sx: { pb: '5px', pt: '5px', width: '130px' },
        },
        [
          h('div', { style: { textAlign: 'left' } }, [
            h(MDEditor.Markdown, {
              source: item.description,
              style: { backgroundColor: 'transparent', color: 'black' },
            }),
            getActionStatus(item.status),
          ]),
        ],
      ),
      h(
        TableCell,
        {
          component: 'th',
          scope: 'row',
          sx: { pb: '5px', pt: '5px', width: '130px' },
        },
        calculateTimeDifference(item.created) + ' ago',
      ),
    ]),
    h(TableRow, { key: `log-details-${item.id}` }, [
      h(TableCell, { sx: { py: 0 }, colSpan: 6 }, [
        h(Collapse, { in: expand, timeout: 'auto', unmountOnExit: true }, [
          h(Box, { sx: {} }, [
            h(MDEditor.Markdown, {
              source: item.detail,
              style: { backgroundColor: 'transparent' },
            }),
          ]),
        ]),
      ]),
    ]),
  ];
};

export const PackageHistoryDialog = ({ id }: { id: number }) => {
  const [state, doFetch] = useAsyncFn(api.getTicketPackageActionLogs);
  const [open, setOpen] = useState(false);
  const [expanded_row, setExpandedRow] = useState(
    {} as Record<number, boolean>,
  );

  const loadData = () => {
    doFetch(id).then(() => {
      setExpandedRow(
        _.reduce(
          (result, item: any) => {
            result[item.id] = Boolean(false);
            return result;
          },
          {} as Record<number, boolean>,
          state?.value ?? [],
        ),
      );
    });
  };

  return h(Stack, {}, [
    h(
      Button,
      {
        variant: 'outlined',
        startIcon: h(HistoryIcon),
        onClick: () => {
          loadData();
          setOpen(true);
        },
      },
      'History',
    ),

    h(
      Dialog,
      {
        open,
        maxWidth: 'md',
        fullWidth: true,
        onClose: () => setOpen(false),
        scroll: 'paper',
      },
      [
        h(
          DialogTitle,
          {
            id: 'package-activity-dialog',
            sx: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          },
          [
            'Ticket Package History',
            h(
              IconButton,
              {
                sx: { position: 'absolute', right: 8, top: 8 },
                onClick: () => setOpen(false),
              },
              [h(CloseIcon)],
            ),
          ],
        ),
        h(DialogContent, { dividers: true }, [
          h(TableContainer, { sx: { maxHeight: 600 } }, [
            h(
              Table,
              { sx: { size: 'small', ariaLabel: 'Logs' }, stickyHeader: true },
              [
                h(TableHead, [
                  h(TableRow, [
                    h(TableCell, { padding: 'checkbox' }),
                    h(
                      TableCell,
                      { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
                      'User',
                    ),
                    h(
                      TableCell,
                      { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
                      'Action',
                    ),
                    h(
                      TableCell,
                      { sx: { pb: '5px', pt: '5px', fontWeight: 'bold' } },
                      'Date',
                    ),
                  ]),
                ]),
                h(
                  TableBody,
                  state.value?.map((item) => {
                    return ActivityRow({
                      item,
                      expand: expanded_row[item.id],
                      setExpand: (value: boolean) => {
                        const newSelected = _.set(
                          _.lensProp(item.id),
                          value,
                          expanded_row,
                        );
                        setExpandedRow(newSelected);
                      },
                    });
                  }),
                ),
              ],
            ),
          ]),
          !(state.value ?? []).length
            ? h(
                Stack,
                {
                  direction: 'row',
                  justifyContent: 'center',
                  sx: {
                    pb: '5px',
                    pt: '15px',
                  },
                },
                'No action found in ticket package history.',
              )
            : null,
        ]),
      ],
    ),
  ]);
};
