import h from '../../lib/react-hyperscript';
import { InputLabel, FormControl, NativeSelect } from '@mui/material';
import { useState } from 'react';

export default function SimpleSelect<
  T extends string | number | readonly string[] | undefined,
>({
  sx = {},
  label,
  defaultValue,
  value: controlledValue,
  options,
  optionValues,
  name,
  fullWidth = false,
  onChange = () => {},
  disabled = false,
  variant = 'standard',
  autoFocus,
  fontSize,
  isCellMode = false,
  size,
}: {
  sx?: object;
  label: string;
  defaultValue?: T;
  value?: T;
  options?: T[];
  optionValues?: { label: string; value: T }[];
  name?: string;
  fullWidth?: boolean;
  onChange?: (val: T) => void;
  disabled?: boolean;
  variant?: 'standard' | 'outlined';
  autoFocus?: boolean;
  fontSize?: string;
  isCellMode?: boolean;
  size?: 'small' | 'medium';
}) {
  const [uncontrolledValue, setValue] = useState(defaultValue);
  const value =
    controlledValue === undefined ? uncontrolledValue : controlledValue;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!controlledValue) setValue(event.target.value as T);
    onChange(event.target.value as T);
  };

  optionValues = optionValues
    ? optionValues
    : (options || []).map((o) => ({ label: '' + o, value: o }));

  return h(FormControl, { disabled, sx, fullWidth, size }, [
    !isCellMode
      ? h(
          InputLabel,
          { variant, htmlFor: `${name}-select`, sx: { ml: 2 } },
          label,
        )
      : null,
    h(
      NativeSelect,
      {
        autoFocus,
        inputProps: { name, id: `${name}-select`, value },
        onChange: handleChange,
        name,
        sx: { ml: !isCellMode ? 2 : 1, fontSize },
      },
      optionValues.map((ov) =>
        h('option', { key: '' + ov.value, value: ov.value }, ov.label),
      ),
    ),
  ]);
}
