import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import h from '../../lib/react-hyperscript';
import { Link } from '@mui/material';

const DataGridEntryButton = (
  row: { id: string | number },
  path: string,
  { color = 'primary.main' } = {},
) => {
  return h(
    Link,
    {
      sx: {
        variant: 'soft',
        color: `${color}`,
        border: '1px solid',
        borderRadius: '10px',
        px: 2,
        py: 0.2,
        ':hover': {
          backgroundColor: `${color}`,
          color: 'white',
        },
      },
      underline: 'none',
      href: path,
    },
    row.id,
  );
};

export default DataGridEntryButton;
