import { createContext } from 'react';
import { AlertColor } from '@mui/material';

interface AppNotification {
  open: boolean;
  severity: AlertColor;
  msg: string;
}

interface AppContextType {
  // Notification
  notification: AppNotification;
  setNotification: (value: AppNotification) => void;
}

// Global notification, alert MUI components
export const AppContext = createContext<AppContextType>({
  notification: {
    open: false,
    severity: 'info',
    msg: '',
  },
  setNotification: (value) => {},
});
