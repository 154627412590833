import h from '../../lib/react-hyperscript';
import PaymentsPanel from './components/PaymentsPanel';
import { PaymentListPanel } from './components/PaymentListPanel';
import { BulkPaymentsPanel } from './components/BulkPaymentsPanel';
import OverduePaymentsPanel from './components/OverduePaymentsPanel';

const routing = {
  element: h(PaymentsPanel),
  children: [
    {
      path: '',
      element: h(PaymentListPanel),
    },
    {
      path: 'batches',
      element: h(BulkPaymentsPanel),
    },
    {
      path: 'overdue',
      element: h(OverduePaymentsPanel),
    },
  ],
};

export { routing };
