import h from '../../lib/react-hyperscript';
import {
  Popover,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useAsyncFn } from 'react-use';
import * as api from '../api';
import { useState } from 'react';
import { format } from './QueryDataGrid';

const PopoverCard = ({
  data,
}: {
  data: {
    created: Date;
    adjustment_total: string;
    adjustments: { [key: string]: any }[];
    erp: string;
    rpf: string;
    price: string;
    desired_price: string;
    rpf_comps_limit_config?: { [key: string]: any };
  };
}) => {
  return h(
    Card,
    { sx: { height: '270px', overflowY: 'scroll', minWidth: '300px' } },
    [
      h(
        CardContent,
        data
          ? data.created
            ? [
                h(
                  Stack,
                  {
                    sx: {
                      width: '100%',
                      gap: 0.5,
                      marginBottom: 1,
                    },
                  },
                  [
                    h(
                      Typography,
                      {
                        variant: 'h6',
                        fontWeight: 'bold',
                      },
                      'Price',
                    ),

                    h(
                      Typography,
                      { variant: 'body1' },
                      'erp * rpf * total_adjustment = price',
                    ),

                    h(
                      Typography,
                      { variant: 'body1' },
                      `$${data.erp} * ${format.percentWithTwoDecimals({
                        value: +data.rpf,
                      })} * ${format.percentWithTwoDecimals({
                        value: 1 + +data.adjustment_total,
                      })} = $${data.price}`,
                    ),
                  ],
                ),
                h(
                  Stack,
                  {
                    sx: { width: '100%' },
                  },
                  [
                    h(
                      Typography,
                      {
                        variant: 'h6',
                        fontWeight: 'bold',
                      },
                      'Adjustments',
                    ),
                    ...(data.adjustments
                      ? data.adjustments.map((adj) =>
                          h(
                            Typography,
                            { variant: 'body1' },
                            `${
                              adj.type === 'basic'
                                ? adj.scope.reduce(
                                    (res: string, cur: string) =>
                                      res +
                                      (res ? ', ' : '') +
                                      cur.split(':')[0],
                                    '',
                                  )
                                : adj.type
                            }: ${format.percentWithTwoDecimals({
                              value: +adj.amount,
                            })}`,
                          ),
                        )
                      : []),
                    h(Stack, { direction: 'row' }, [
                      h(
                        Typography,
                        { variant: 'body1' },
                        'Total Adjustment (via multiplication): ',
                      ),
                      h(
                        Typography,
                        {
                          variant: 'body1',
                          sx: { fontWeight: 'bold' },
                        },
                        `${format.percentWithTwoDecimals({
                          value: +data.adjustment_total,
                        })}`,
                      ),
                    ]),
                  ],
                ),

                h(Stack, { sx: { width: '100%' } }, [
                  h(
                    Typography,
                    {
                      variant: 'h6',
                      fontWeight: 'bold',
                    },
                    'RPF-comp limits',
                  ),
                  h(
                    Typography,
                    {
                      variant: 'body1',
                    },
                    `Min: ${
                      data.rpf_comps_limit_config?.min_pct
                        ? format.percentWithTwoDecimals({
                            value: +data.rpf_comps_limit_config.min_pct,
                          })
                        : 'N/A'
                    }`,
                  ),
                  h(
                    Typography,
                    {
                      variant: 'body1',
                    },
                    `Max: ${
                      data.rpf_comps_limit_config?.max_pct
                        ? format.percentWithTwoDecimals({
                            value: +data.rpf_comps_limit_config.max_pct,
                          })
                        : 'N/A'
                    }`,
                  ),
                  h(
                    Typography,
                    {
                      variant: 'body1',
                    },
                    `Max comp: ${
                      data.rpf_comps_limit_config?.max_comp || 'N/A'
                    }`,
                  ),
                ]),

                h(
                  Stack,
                  {
                    sx: { width: '100%' },
                  },
                  [
                    h(
                      Typography,
                      {
                        variant: 'h6',
                        fontWeight: 'bold',
                      },
                      'Lag',
                    ),

                    h(
                      Typography,
                      {
                        variant: 'body1',
                      },
                      'List price may lag target price due to AMP’s daily price move limits.',
                    ),

                    h(
                      Typography,
                      {
                        variant: 'body1',
                      },
                      `Lag: ${format.percentWithTwoDecimals({
                        value:
                          (+data.desired_price - +data.price) / +data.price,
                      })}`,
                    ),
                  ],
                ),
              ]
            : [
                h(
                  Typography,
                  {
                    variant: 'h6',
                    fontWeight: 'bold',
                  },
                  'No pricing adjustment history found.',
                ),
              ]
          : [
              h(
                Box,
                {
                  width: 180,
                  height: 270,
                  sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                },
                [h(CircularProgress)],
              ),
            ],
      ),
    ],
  );
};

export default function InventoryPricingAdjustment({
  inv_id,
  label,
}: {
  inv_id: number;
  label?: any | HTMLElement;
}) {
  const [state, doFetch] = useAsyncFn(api.getInventoryAdjustmentHistory);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!state.loading && !state.value)
      doFetch(inv_id).catch(() => {
        console.error(state.error);
      });

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return label
    ? h('span', [
        h(
          Box,
          {
            component: 'span',
            sx: { cursor: 'pointer' },
            onClick: handleClick,
          },
          label,
        ),

        h(
          Popover,
          {
            open: Boolean(anchorEl),
            anchorEl,
            onClose: handleClose,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          },
          [h(PopoverCard, { data: state.value })],
        ),
      ])
    : null;
}
