import h from '../../lib/react-hyperscript';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import FetchBackdrop from './FetchBackdrop';
import { FetchPageHeader } from './FetchPageHeader';
import { useCachedData } from '../cached_data';

type DataPanelProps = {
  header: string;
  cache_key: string;
  componentFn: ({
    rows,
    onDataUpdated,
  }: {
    rows: unknown[];
    onDataUpdated: () => void;
  }) => JSX.Element;
  paginationLoading?: boolean;
};

export default function CachedDataPanel({
  header,
  cache_key,
  componentFn,
  paginationLoading = false,
}: DataPanelProps) {
  const {
    loadData,
    error,
    loading,
    value,
    is_expired,
    invalidateData,
    loadDataByPagination,
  } = useCachedData(cache_key);

  useEffect(() => {
    if (is_expired) paginationLoading ? loadDataByPagination() : loadData();
  }, []);

  return h(
    Stack,
    {
      direction: 'column',
      flexGrow: 1,
    },
    [
      h(FetchPageHeader, {
        header,
        state: { loading, error },
        doFetch: paginationLoading ? loadDataByPagination : loadData,
      }),

      h(FetchBackdrop, {
        state: {
          error,
          loading: paginationLoading ? loading && value.length === 0 : loading,
        },
      }),

      componentFn({ rows: value ?? [], onDataUpdated: invalidateData }),
    ],
  );
}
