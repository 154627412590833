import h from '../../../lib/react-hyperscript';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import * as api from '../../../common/api';
import {
  Paper,
  Stack,
  Typography,
  Tabs,
  tabsClasses,
  Tab,
  Box,
  Button,
  lighten,
  darken,
  TextField,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ClientEvent, InventoryItem } from '../../../common/types';
import { useTheme } from '@mui/material/styles';
import { useBaseData } from '../../../common/base_data';
import { format } from '../../../common/components';

const EventCard = ({
  event,
  bgColor,
  getTeamNameShortByTeam,
}: {
  event: ClientEvent;
  bgColor: string;
  getTeamNameShortByTeam: (team_name: string) => string;
}) => {
  const [away_team, home_team] = event.name.split(' at ');
  const event_date = format.datetime({ value: event.date ?? '' }) ?? '';

  return h(
    Box,
    {
      sx: {
        padding: 0,
      },
    },
    [
      h(
        Stack,
        {
          paddingX: 1,
          sx: {
            backgroundColor: bgColor,
            borderRadius: '4px',
            color: darken(bgColor, 0.7),
          },
        },
        [
          h(Tooltip, {
            title: event.name,
            children: h(
              Typography,
              {
                variant: 'button',
                sx: {
                  mb: 0,
                  textTransform: 'none!important',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '200px',
                  fontWeight: 'bolder',
                },
              },
              away_team && home_team
                ? `${getTeamNameShortByTeam(
                    away_team,
                  )} @ ${getTeamNameShortByTeam(home_team)}`
                : event.name,
            ),
          }),
          h(
            Typography,
            {
              variant: 'button',
              sx: { mb: 0 },
            },
            event_date,
          ),
        ],
      ),
    ],
  );
};

export default function SalesHomeSchedule({
  inv_item,
  setSeatChartResource,
}: {
  inv_item?: InventoryItem;
  setSeatChartResource: (seat_chart: string) => void;
}) {
  const { inventory_id } = useParams<{ inventory_id: string }>();
  const id = parseInt(inventory_id as string, 10);

  const [forward_days, setForwardDays] = useState('14');
  const [backward_days, setBackwardDays] = useState('14');
  const [state, doFetch] = useAsyncFn(api.getHomeSchedule);

  useEffect(() => {
    doFetch(id, {
      forward_days: parseInt(forward_days),
      backward_days: parseInt(backward_days),
    }).then((resp) => {
      if (!resp) return;
      for (const schedule of resp) {
        // ignore hours diff
        if (
          schedule.date.split('T')[0] ===
          inv_item?.event_date_local.split('T')[0]
        ) {
          setSeatChartResource(schedule.chartUrl);
          break;
        }
      }
    });
  }, []);

  const theme = useTheme();
  const getEventCardBackgroundColor = (event_date: string) => {
    if (!inv_item) return lighten('#000', 0.8);
    // only compare date without time
    const event_date_only = event_date.split('T')[0];
    const inv_event_date_only = inv_item.event_date_local.split('T')[0];
    if (event_date_only === inv_event_date_only)
      return lighten(theme.palette.info.main, 0.6);
    if (event_date_only > inv_event_date_only)
      return lighten(theme.palette.success.light, 0.7);
    return lighten('#000', 0.8);
  };

  const {
    baseData: { getTeamNameShortByTeam },
  } = useBaseData();

  return h(Paper, { sx: { height: '100%' } }, [
    h(
      Stack,
      {
        direction: 'column',
        sx: { px: 3, py: 2, gap: 1 },
      },
      [
        h(
          Grid,
          {
            container: true,
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          [
            h(
              Typography,
              {
                variant: 'h6',
                gutterBottom: true,
                sx: { fontWeight: 'bold', mb: 0 },
              },
              `Home schedule - ${inv_item?.home_team ?? ''} - ${
                inv_item?.season ?? ''
              }`,
            ),

            h(Stack, { direction: 'row', alignItems: 'end', gap: 1 }, [
              h(TextField, {
                size: 'small',
                variant: 'standard',
                type: 'number',
                sx: { width: '100px' },
                label: 'Minus day',
                value: backward_days,
                onChange: (e) => setBackwardDays(e.target.value),
              }),
              h(
                Typography,
                {
                  variant: 'body1',
                  sx: { mb: 0 },
                },
                `/ ${inv_item?.event_date_local.split('T')[0]} /`,
              ),
              h(TextField, {
                size: 'small',
                variant: 'standard',
                type: 'number',
                sx: { width: '100px' },
                label: 'Plus day',
                value: forward_days,
                onChange: (e) => setForwardDays(e.target.value),
              }),
              h(
                Button,
                {
                  variant: 'outlined',
                  size: 'small',
                  onClick: () =>
                    doFetch(id, {
                      forward_days: parseInt(forward_days),
                      backward_days: parseInt(backward_days),
                    }),
                },
                ['Search'],
              ),
            ]),
          ],
        ),

        h(
          Box,
          { width: '100%', sx: { display: 'flex', justifyContent: 'center' } },
          [
            !state.loading
              ? h(
                  Tabs,
                  {
                    value: inv_item?.event_id ?? null,
                    variant: 'scrollable',
                    scrollButtons: true,
                    sx: {
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    },
                  },
                  [
                    state.value
                      ? state.value
                          .sort((a: ClientEvent, b: ClientEvent) =>
                            a.date > b.date ? 1 : -1,
                          )
                          .map((item: ClientEvent) =>
                            h(Tab, {
                              key: item.id,
                              disableRipple: true,
                              value: item.id,
                              sx: { cursor: 'default' },
                              label: h(EventCard, {
                                event: item,
                                bgColor: getEventCardBackgroundColor(item.date),
                                getTeamNameShortByTeam,
                              }),
                            }),
                          )
                      : '',
                  ],
                )
              : h(CircularProgress),
          ],
        ),
      ],
    ),
  ]);
}
