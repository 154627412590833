import h from '../../lib/react-hyperscript';
import AwaitingBroadcastPanel from './components/AwaitingBroadcastPanel';
import AwaitingBroadcastListPanel from './components/AwaitingBroadcastListPanel';

const routing = {
  element: h(AwaitingBroadcastPanel),
  children: [
    {
      path: '',
      element: h(AwaitingBroadcastListPanel),
    },
  ],
};

export { routing };
