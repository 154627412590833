import h from '../../lib/react-hyperscript';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { InventoryItem } from '../types';

export default function MarketplaceEventLinkGroup({
  seat_quantity,
  event,
}: {
  seat_quantity?: number;
  event: { [key: string]: any };
}) {
  const ticketmaster_url = `https://www.ticketmaster.com/search?q=${
    event.performer?.name
  }&sort=date&startDate=${event.date?.split('T')[0]}&endDate=${
    event.date?.split('T')[0]
  }`;
  const stubhub_url = `${event.stubhubEventUrl}?quantity=${seat_quantity ?? 0}`;
  const vividseats_url = event.vividSeatsEventUrl;
  const seatgeek_url = `https://seatgeek.com/${event.performer?.name.replaceAll(
    ' ',
    '-',
  )}-tickets?start_date=${event.date?.split('T')[0]}T00%3A00%3A00&end_date=${
    event.date?.split('T')[0]
  }T23%3A59%3A59`;

  return h(ButtonGroup, { variant: 'outlined', size: 'small' }, [
    h(
      Button,
      {
        onClick: () => window.open(ticketmaster_url),
      },
      [
        h(Tooltip, {
          title: 'Ticketmaster',
          placement: 'top',
          children: h('img', {
            src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAACjUlEQVRIibWWz0tUURTHv/fM8834azQ1mkYQdSKyoqjWgVBklH9AuKiWRm1bBi3atZAW0aIIJMhFOxftClxEBQWZmRaKtElSlBlzmjcz757Twmlmnvjeu6/yLM+993zOr3vuVXTtCyiGXRLWFigGtWsAAv31WRZDRBQRgLWwI7zBB5vJhBEBwCWRrL7YYz+70uE86rs1lEQpnGCZW7871HpjuCNhV3x6Pv3L5LRxBEW5eqa9ah3AxKwDUv8HwALYqqut5vDyehmuif3AIBlAngHAxaUTifqld18LKAoTA4ClKO6L8gVsWZ+9ndYaLLK3zbPz5IHGD3fSipRFmF4qjjxd92P4R6BlsNc+3JPYcbG7q6G7q6ESzYLjaySoBhoXjuxsfZtMzRXgPwr8IxBkUnZ2U7talEJnsraz7Ep2UysFAI1xevy5iJhvDRRdX/CbRVwUlARlGT3d/GA0VdU/eZG9/HANiT9GW8i3nUQHdRHFFeKKHTl3rKle/3bRwR4iy6BJje6BK8f7PMW4/9EJyEk0AAMQ9O+3q5pcXuMnm1wxIwC0DB+K1ytmlhzjAWYEwNkBT37eLzowy74ZwJVTGQ/g5bwT0PXRARoD3ss8ORfU9dEADEChM1lz+NuPElS0VzBwM+NoylPQlZyLCN4DoS9aZ8Jjrz9lI88cUxAAoMZwWiBA4fWa9vCS1sJYz9Sn/L42q6WJBu+thDICR4VCKcerObf+Mcik7UzaBjD5ZsMkXWEFa1I3x1d3XHll1q8hALLU+IwzMvZ9eb28bWlipmDSr0HjuirsCgrS0R4739vQGqf5VXdqsYQWotCGFW0EqGAEYIEApEw/ncHvwTYhhUh3eEsssP6HH3CYsP4NuvrfrRyMPfsAAAAASUVORK5CYII=',
            width: 20,
            height: 20,
          }),
        }),
      ],
    ),
    h(
      Button,
      {
        onClick: () => window.open(stubhub_url),
      },
      [
        h(Tooltip, {
          title: 'StubHub',
          placement: 'top',
          children: h('img', {
            src: 'https://www.stubhub.com/favicon.ico',
            width: 20,
            height: 20,
          }),
        }),
      ],
    ),
    h(
      Button,
      {
        onClick: () => window.open(vividseats_url),
      },
      [
        h(Tooltip, {
          title: 'Vivid Seats',
          placement: 'top',
          children: h('img', {
            src: 'https://a.vsstatic.com/common/favicon/rebrand/favicon.ico',
            width: 20,
            height: 20,
          }),
        }),
      ],
    ),
    h(
      Button,
      {
        onClick: () => window.open(seatgeek_url),
      },
      [
        h(Tooltip, {
          title: 'SeatGeek',
          placement: 'top',
          children: h('img', {
            src: 'https://seatgeek.com/favicon-new.png',
            width: 20,
            height: 20,
          }),
        }),
      ],
    ),
  ]);
}
