import h from '../../../lib/react-hyperscript';
import { default as PackagesDataGrid } from './PackagesDataGrid';
import FetchBackdrop from '../../../common/components/FetchBackdrop';
import { useCachedData } from '../../../common/cached_data';
import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { FetchPageHeader } from '../../../common/components/FetchPageHeader';

const PackagesListPanel = () => {
  const { loadData, error, loading, value, is_expired, invalidateData } =
    useCachedData('ticket_packages');
  useEffect(() => {
    if (is_expired) loadData();
  }, []);

  return h(Stack, { direction: 'column', flexGrow: 1 }, [
    h(FetchPageHeader, {
      header: 'Ticket Packages',
      state: { error, loading },
      doFetch: loadData,
    }),

    h(FetchBackdrop, { state: { error, loading } }),

    h(PackagesDataGrid, {
      rows: value ?? [],
      onDataUpdated: invalidateData,
    }),
  ]);
};

export default PackagesListPanel;
